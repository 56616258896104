package com.essntl.features.proposal.domain.model

import com.essntl.core.utils.datetime.formatTimestampToHHMM
import com.essntl.features.supplier.domain.model.SupplierModel

data class ProposalServiceModel(
    val id: String,
    val proposalId: String?,
    val serviceId: String,
    val grouped: Boolean?,
    val additionalInfo: String?,
    val editedDescription: String?,
    val startDate: String,
    val endDate: String?,
    val startTime: String?,
    val endTime: String?,
    val index: Int,
    val status: Status?,
    val createdAt: String,
    val updatedAt: String?,
    val price: Int?,
    val internalNotes: String?,
    val supplier: SupplierModel?,
    val guestCount: Int,
    val address: String?,
    val updatedBy: String?,
    val contactName: String?,
    val contactPhoneNumber: String?,
    val clients: List<String>?,
    val itineraryId: String?,
    val dynamicFields: Map<String, String>?,
    val specialNotes: String?,
    val paid: Boolean?,
    val embeddedMap: String?,
    val embeddedContent: String?,
    val files: Set<String>?,
    val commissionable: Boolean?,
) {
    val dynamicFieldsString
        get() =
            dynamicFields
                .orEmpty()
                .entries
                .joinToString("\n") { (key, value) ->
                    val capitalizeKey =
                        key
                            .replace("_", " ")
                            .replaceFirstChar { it.titlecaseChar() }

                    "$capitalizeKey: $value"
                }

    fun getChangedFields(proposalServiceModel: ProposalServiceModel): ProposalServiceModel =
        ProposalServiceModel(
            id = id,
            createdAt = createdAt,
            updatedAt = updatedAt,
            proposalId = proposalId,
            serviceId = serviceId,
            grouped = if (grouped != proposalServiceModel.grouped) proposalServiceModel.grouped else null,
            startDate = if (startDate != proposalServiceModel.startDate) proposalServiceModel.startDate else "",
            endDate = if (endDate.orEmpty() != proposalServiceModel.endDate.orEmpty()) proposalServiceModel.endDate else null,
            startTime = if (formatTimestampToHHMM(startTime.orEmpty()) !=
                formatTimestampToHHMM(proposalServiceModel.startTime.orEmpty())
            ) proposalServiceModel.startTime else null,
            endTime = if (formatTimestampToHHMM(endTime.orEmpty())
                != formatTimestampToHHMM(proposalServiceModel.endTime.orEmpty())
            ) proposalServiceModel.endTime else null,
            index = if (index != proposalServiceModel.index) proposalServiceModel.index else -1,
            additionalInfo =
            if (additionalInfo.orEmpty() != proposalServiceModel.additionalInfo.orEmpty()) {
                proposalServiceModel.additionalInfo
            } else {
                null
            },
            editedDescription =
            if (editedDescription.orEmpty() != proposalServiceModel.editedDescription.orEmpty()) {
                proposalServiceModel.editedDescription
            } else {
                null
            },
            price = if (price != proposalServiceModel.price) proposalServiceModel.price else null,
            status = if (status != proposalServiceModel.status) proposalServiceModel.status else null,
            internalNotes =
            if (internalNotes.orEmpty() != proposalServiceModel.internalNotes.orEmpty()) {
                proposalServiceModel.internalNotes
            } else {
                null
            },
            supplier = if (supplier != proposalServiceModel.supplier) proposalServiceModel.supplier else null,
            guestCount = if (guestCount != proposalServiceModel.guestCount) proposalServiceModel.guestCount else -1,
            address = if (address.orEmpty() != proposalServiceModel.address.orEmpty()) proposalServiceModel.address else null,
            updatedBy = if (updatedBy != proposalServiceModel.updatedBy) proposalServiceModel.updatedBy else null,
            contactName = if (contactName.orEmpty() != proposalServiceModel.contactName.orEmpty()) proposalServiceModel.contactName else null,
            clients = if (clients != proposalServiceModel.clients) proposalServiceModel.clients else null,
            dynamicFields =
            if (
                dynamicFields == null &&
                proposalServiceModel.dynamicFields.orEmpty().all { it.value.isEmpty() }
            ) {
                null
            } else if (dynamicFields != proposalServiceModel.dynamicFields) {
                proposalServiceModel.dynamicFields
            } else {
                null
            },
            itineraryId = itineraryId,
            specialNotes = if (specialNotes.orEmpty() != proposalServiceModel.specialNotes.orEmpty()) proposalServiceModel.specialNotes else null,
            paid = if (paid != proposalServiceModel.paid) proposalServiceModel.paid else null,
            contactPhoneNumber =
            if (contactPhoneNumber.takeIf { it != "0" }
                    .orEmpty() != proposalServiceModel.contactPhoneNumber.takeIf { it != "0" }.orEmpty()) {
                proposalServiceModel.contactPhoneNumber
            } else {
                null
            },
            embeddedMap = if (embeddedMap.orEmpty() != proposalServiceModel.embeddedMap.orEmpty()) proposalServiceModel.embeddedMap else null,
            embeddedContent = if (embeddedContent.orEmpty() != proposalServiceModel.embeddedContent.orEmpty()) proposalServiceModel.embeddedContent else null,
            files = if (files != proposalServiceModel.files) proposalServiceModel.files else null,
            commissionable = if (commissionable != proposalServiceModel.commissionable) proposalServiceModel.commissionable else null,
        )

    val isEmpty
        get() =
            this.copy(
                id = "",
                serviceId = "",
                createdAt = "",
                updatedAt = null,
                proposalId = null,
                itineraryId = null,
            ) == empty

    enum class Status {
        Ready,
        POSent,
        Confirmed,
        TOChange,
        Cancelled,
        Completed,
    }

    companion object {
        val empty =
            ProposalServiceModel(
                id = "",
                proposalId = null,
                serviceId = "",
                grouped = null,
                createdAt = "",
                updatedAt = null,
                additionalInfo = null,
                editedDescription = null,
                startDate = "",
                endDate = null,
                startTime = null,
                endTime = null,
                index = -1,
                price = null,
                status = null,
                internalNotes = null,
                supplier = null,
                guestCount = -1,
                address = null,
                contactName = null,
                contactPhoneNumber = null,
                updatedBy = null,
                clients = null,
                itineraryId = null,
                dynamicFields = null,
                specialNotes = null,
                paid = null,
                embeddedMap = null,
                embeddedContent = null,
                files = null,
                commissionable = null,
            )
    }
}
