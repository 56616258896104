package com.essntl.core.utils.viewmodel

import com.essntl.core.utils.repository.RealtimeAction
import com.essntl.core.utils.utils.loadingmanager.LoadingManager
import com.essntl.core.utils.utils.update
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.coroutines.yield

interface ViewModel {
    val scope: CoroutineScope
    val loadingManager: LoadingManager
}

fun <T> ViewModel.getData(
    getData: suspend () -> Result<T>,
    getState: () -> State<T> = { State.initial() },
    setState: (State<T>) -> Unit,
    ignoreLoading: Boolean = false,
    onSuccess: suspend (T) -> Unit = {},
    onFailure: suspend (Throwable) -> Unit = {},
    onCompletion: suspend (Result<T>) -> Unit = {},
): Job =
    scope.launch {
        getDataSuspend(
            getData = getData,
            getState = getState,
            setState = setState,
            ignoreLoading = ignoreLoading,
            onSuccess = onSuccess,
            onFailure = onFailure,
            onCompletion = onCompletion,
        )
    }

suspend fun <T> ViewModel.getDataSuspend(
    getData: suspend () -> Result<T>,
    getState: () -> State<T> = { State.initial() },
    setState: (State<T>) -> Unit,
    ignoreLoading: Boolean = false,
    onSuccess: suspend (T) -> Unit = {},
    onFailure: suspend (Throwable) -> Unit = {},
    onCompletion: suspend (Result<T>) -> Unit = {},
) {
    // yield() is used to add cancellation points
    try {
        yield()
        if (!ignoreLoading && getState().isLoading) return

        yield()

        setState(State.loading(getState().data))
        loadingManager.emitLoading(true)

        yield()

        val result =
            getData()
                .onSuccess {
                    yield()

                    setState(State.success(it))
                    onSuccess(it)
                }
                .onFailure {
                    yield()

                    setState(State.failure(getState().data))
                    onFailure(it)
                }

        loadingManager.emitLoading(false)
        onCompletion(result)
    } catch (e: CancellationException) {
        // Set loading back to false if the job is canceled
        setState(getState().copy(isLoading = false))
        loadingManager.emitLoading(false)
        onFailure(e)
        onCompletion(Result.failure(e))
    }
}

fun <T> ViewModel.getPaginationData(
    getData: suspend () -> Result<List<T>>,
    getState: () -> PaginationState<T> = { PaginationState() },
    setState: (PaginationState<T>) -> Unit,
    ignoreLoading: Boolean = false,
    onSuccess: suspend (page: Int, data: List<T>) -> Unit = { _, _ -> },
    onFailure: suspend (Throwable) -> Unit = {},
): Job =
    scope.launch {
        getPaginationDataSuspend(
            getData = getData,
            getState = getState,
            setState = setState,
            ignoreLoading = ignoreLoading,
            onSuccess = onSuccess,
            onFailure = onFailure,
        )
    }

suspend fun <T> ViewModel.getPaginationDataSuspend(
    getData: suspend () -> Result<List<T>>,
    getState: () -> PaginationState<T> = { PaginationState() },
    setState: (PaginationState<T>) -> Unit,
    ignoreLoading: Boolean = false,
    onSuccess: suspend (page: Int, data: List<T>) -> Unit = { _, _ -> },
    onFailure: suspend (Throwable) -> Unit = {},
) {
    // yield() is used to add cancellation points
    try {
        yield()
        if (!ignoreLoading && getState().isLoading) return

        yield()

        setState(getState().loading())
        loadingManager.emitLoading(true)

        yield()

        getData()
            .onSuccess {
                yield()

                val state = getState().success(it)
                setState(state)
                onSuccess(state.loadedPages, it)
            }
            .onFailure {
                yield()

                setState(getState().failure())
                onFailure(it)
            }

        loadingManager.emitLoading(false)
    } catch (e: CancellationException) {
        // Set loading back to false if the job is canceled
        setState(getState().copy(isLoading = false))
        loadingManager.emitLoading(false)
        onFailure(e)
    }
}

fun ViewModel.collectLoading(
    flow: Flow<Set<String>>,
    setState: (Set<String>) -> Unit,
) = scope.launch {
    flow.collect { loadingSet ->
        loadingManager.emitLoading(loadingSet.isNotEmpty())
        setState(loadingSet)
    }
}

data class StateListGetSet<T>(
    val getState: () -> State<List<T>>,
    val setState: (State<List<T>>) -> Unit,
    val predicate: (T) -> Boolean = { true },
)

fun <T> ViewModel.collectRealTimeActions(
    flow: Flow<RealtimeAction<T>>,
    predicate: (first: T, second: T) -> Boolean,
    predicateIds: (first: String, second: String) -> Boolean,
    itemId: (T) -> String,
    update: (old: T, new: T) -> T = { _, new -> new },
    getState: () -> State<List<T>>,
    setState: (State<List<T>>) -> Unit,
    getSetPredicate: (item: T) -> Boolean = { true },
) = collectRealTimeActions(
    flow = flow,
    predicate = predicate,
    predicateIds = predicateIds,
    itemId = itemId,
    update = update,
    StateListGetSet(getState, setState, getSetPredicate),
)


fun <T> ViewModel.collectRealTimeActions(
    flow: Flow<RealtimeAction<T>>,
    predicate: (first: T, second: T) -> Boolean,
    predicateIds: (first: String, second: String) -> Boolean,
    itemId: (T) -> String,
    update: (old: T, new: T) -> T = { _, new -> new },
    vararg stateListGetSet: StateListGetSet<T>,
) = scope.launch {
    flow.collect { action ->
        stateListGetSet.forEach { (getState, setState, _) ->

            val state = getState()
            val list = state.data.orEmpty()

            val newList = when (action) {
                is RealtimeAction.Insert -> list.filter {
                    !predicate(it, action.item)
                } + action.item

                is RealtimeAction.Update -> list.update(
                    predicate = { predicate(it, action.item) },
                    update = { update(it, action.item) },
                )

                is RealtimeAction.Delete -> list.filter {
                    !predicateIds(itemId(it), action.itemId ?: "")
                }

                is RealtimeAction.Select -> list
            }

            setState(
                state.copy(
                    data = newList,
                ),
            )
        }
    }
}

fun <T> ViewModel.collectSuccessEvent(
    flow: Flow<Operation<T>>,
    predicate: (first: T, second: T) -> Boolean,
    update: (old: T, new: T) -> T = { _, new -> new },
    getState: () -> State<List<T>>,
    setState: (State<List<T>>) -> Unit,
    getSetPredicate: (item: T) -> Boolean = { true },
) = collectSuccessEvent(
    flow = flow,
    predicate = predicate,
    update = update,
    StateListGetSet(getState, setState, getSetPredicate),
)

fun <T> ViewModel.collectSuccessEvent(
    flow: Flow<Operation<T>>,
    predicate: (first: T, second: T) -> Boolean,
    update: (old: T, new: T) -> T = { _, new -> new },
    vararg stateListGetSet: StateListGetSet<T>,
) = scope.launch {
    flow.collect { operation ->
        stateListGetSet.forEach { (getState, setState, subPredicate) ->
            if (!subPredicate(operation.item)) return@forEach

            val state = getState()
            val list = state.data.orEmpty()

            val newList =
                when (operation) {
                    is Operation.Add ->
                        list.filter { !predicate(it, operation.item) } + operation.item

                    is Operation.Delete ->
                        list.filter { !predicate(it, operation.item) }

                    is Operation.Update ->
                        list.update(
                            predicate = { predicate(it, operation.item) },
                            update = { update(it, operation.item) },
                        )
                }

            setState(
                state.copy(
                    data = newList,
                ),
            )
        }
    }
}

data class StateGetSet<T>(
    val getState: () -> State<T>,
    val setState: (State<T>) -> Unit,
    val predicate: (T) -> Boolean = { true },
)

fun <T> ViewModel.collectSuccessEvent(
    flow: Flow<T>,
    update: (old: T?, new: T) -> T = { _, new -> new },
    onSuccess: (T) -> Unit = {},
    getState: () -> State<T>,
    setState: (State<T>) -> Unit,
) = collectSuccessEvent(
    flow = flow,
    update = update,
    onSuccess = onSuccess,
    StateGetSet(getState, setState),
)

fun <T> ViewModel.collectSuccessOperationEvent(
    flow: Flow<Operation<T>>,
    update: (old: T?, new: T) -> T = { _, new -> new },
    onSuccess: (T) -> Unit = {},
    getState: () -> State<T>,
    setState: (State<T>) -> Unit,
) = collectSuccessOperationEvent(
    flow = flow,
    update = update,
    onSuccess = onSuccess,
    StateGetSet(getState, setState),
)

fun <T> ViewModel.collectSuccessOperationEvent(
    flow: Flow<Operation<T>>,
    update: (old: T?, new: T) -> T = { _, new -> new },
    onSuccess: (T) -> Unit = {},
    vararg stateGetSet: StateGetSet<T>,
) = collectSuccessEvent(
    flow = flow.map { it.item },
    update = update,
    onSuccess = onSuccess,
    stateGetSet = stateGetSet,
)

fun <T> ViewModel.collectSuccessEvent(
    flow: Flow<T>,
    update: (old: T?, new: T) -> T = { _, new -> new },
    onSuccess: (T) -> Unit = {},
    vararg stateGetSet: StateGetSet<T>,
) = scope.launch {
    flow.collect { newElement ->
        stateGetSet.forEach { (getState, setState, predicate) ->
            if (!predicate(newElement)) return@forEach

            val newState =
                State.success(
                    update(getState().data, newElement),
                )

            setState(newState)
        }

        onSuccess(newElement)
    }
}

data class PaginationStateGetSet<T>(
    val getState: () -> PaginationState<T>,
    val setState: (PaginationState<T>) -> Unit,
    val predicate: (T) -> Boolean = { true },
)

fun <T> ViewModel.collectPaginationSuccessEvent(
    flow: Flow<Operation<T>>,
    predicate: (first: T, second: T) -> Boolean,
    update: (old: T, new: T) -> T = { _, new -> new },
    getState: () -> PaginationState<T>,
    setState: (PaginationState<T>) -> Unit,
    getSetPredicate: (item: T) -> Boolean = { true },
) = collectPaginationSuccessEvent(
    flow = flow,
    predicate = predicate,
    update = update,
    PaginationStateGetSet(getState, setState, getSetPredicate),
)

fun <T> ViewModel.collectPaginationSuccessEvent(
    flow: Flow<Operation<T>>,
    predicate: (first: T, second: T) -> Boolean,
    update: (old: T, new: T) -> T = { _, new -> new },
    vararg stateListGetSet: PaginationStateGetSet<T>,
) = scope.launch {
    flow.collect { operation ->
        stateListGetSet.forEach { (getState, setState, subPredicate) ->
            if (!subPredicate(operation.item)) return@forEach

            val state = getState()
            val list = state.data

            val newList =
                when (operation) {
                    is Operation.Add ->
                        list.filter { !predicate(it, operation.item) } + operation.item

                    is Operation.Delete ->
                        list.filter { !predicate(it, operation.item) }

                    is Operation.Update ->
                        list.update(
                            predicate = { predicate(it, operation.item) },
                            update = { update(it, operation.item) },
                        )
                }

            setState(
                state.copy(
                    data = newList,
                ),
            )
        }
    }
}
