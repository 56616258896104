package com.essntl.core.utils.utils

fun String.capitalizeFirstLetter(): String {
    return this.split(Regex("\\b")).joinToString("") {
        it.replaceFirstChar { char -> char.titlecase() }
    }
}

fun String.capitalise(): String {
    return this.lowercase()
        .replaceFirstChar {
            if (it.isLowerCase()) it.titlecase() else
                it.toString()
        }
}

inline fun String?.letIfNotEmpty(block: (String) -> Unit) {
    if (!this.isNullOrEmpty()) {
        block(this)
    }
}