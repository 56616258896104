package com.essntl.config

import kotlin.OptIn
import kotlin.String
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
@OptIn(ExperimentalJsExport::class)
public object EssntlConfig {
  public val buildFlavor: String = "RELEASE"

  public val essntlBaseUrl: String = "https://be.essntl.app"

  public val supabaseKey: String =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxtcGZsa2xkZG9mbWNicXNmZG90Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTYzMzk4MzMsImV4cCI6MjAxMTkxNTgzM30.r_QmcE2G653d94rb7DpU3wPhiUStJVwxtqOePenLrvk"

  public val supabaseUrl: String = "https://lmpflklddofmcbqsfdot.supabase.co"

  public val supabaseStorageUrl: String = "storage/v1/object/public"

  public val unsplashAccessKey: String = "8oZkGWiHlS8WdS-bz4v9nZcN4zPnMeTrGjptSQ6cOQg"

  public val waypointUsername: String = ""

  public val waypointPassword: String = ""

  public val twilioAccountId: String = ""

  public val twilioPassword: String = ""

  public val axusUsername: String = ""

  public val axusPassword: String = ""

  public val axusClientId: String = ""

  public val openAIKey: String = ""

  public val stripeKey: String = ""

  public val sentryDns: String = ""

  public val version: String = "1.0.0"

  public val whatsappPhoneNumberId: String = ""

  public val whatsappAuthToken: String = ""

  public val quickbooksBaseUrl: String = ""

  public val quickbooksRefreshTokenUrl: String = ""

  public val quickbooksIncomeAccountId: String = ""

  public val whapiAuthToken: String = ""
}
