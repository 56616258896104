package client.pages

import com.essntl.features.file.domain.model.FileModel

sealed interface ProposalItineraryEvent {
    data class GetProposalPreview(val id: String) : ProposalItineraryEvent

    data class GetPreviewItinerary(val id: String) : ProposalItineraryEvent

    data class GetItinerary(val shareCode: String) : ProposalItineraryEvent

    data class GetProposal(val shareCode: String) : ProposalItineraryEvent

    data class GetProposalHistory(
        val shareCode: String,
        val version: Int,
    ) : ProposalItineraryEvent

    data class DownloadFile(val file: FileModel) : ProposalItineraryEvent
    data object ClearState : ProposalItineraryEvent
}