package com.essntl.features.client.data.repository

import com.essntl.core.supabase.*
import com.essntl.core.utils.repository.Repository
import com.essntl.features.client.data.supabase.ClientDto
import com.essntl.features.client.data.supabase.ClientSimpleDto
import com.essntl.features.client.data.supabase.ClientSupabaseDataSource
import com.essntl.features.client.data.supabase.toRequestMap
import com.essntl.features.client.domain.model.ClientModel
import com.essntl.features.client.domain.repository.ClientRepository
import io.github.jan.supabase.postgrest.query.Order
import org.koin.core.annotation.Single

@Single
internal class ClientRepositoryImpl internal constructor(
    private val supabase: ClientSupabaseDataSource,
) : Repository(), ClientRepository {
    override suspend fun getAll(): Result<List<ClientModel>> =
        runCatching {
            supabase
                .getAll(
                    columns = ClientDto.getColumns(),
                )
                .map {
                    it.toModel()
                }
        }

    override suspend fun getAll(
        page: Long,
        limit: Int,
        query: String,
    ): Result<List<ClientModel>> =
        runCatching {
            supabase
                .getAll(
                    page = page, limit = limit, columns = ClientDto.getColumns(),
                ) {
                    if (query.isNotEmpty()) {
                        filter {
                            or {
                                ilike("first_name", "%$query%")
                                ilike("last_name", "%$query%")
                            }
                        }
                    }

                    order("created_at", Order.DESCENDING)
                }
                .map { it.toModel() }
        }

    override suspend fun getById(id: String): Result<ClientModel> =
        runCatching {
            supabase
                .getById(
                    id = id, columns = ClientDto.getColumns(),
                )
                .toModel()
        }

    override suspend fun getByIds(ids: List<String>): Result<List<ClientModel>> =
        runCatching {
            supabase
                .getByIds(
                    ids = ids, columns = ClientDto.getColumns(),
                )
                .map {
                    it.toModel()
                }
        }

    override suspend fun insert(client: ClientModel): Result<ClientModel> =
        runCatching {
            val requestMap = client.toRequestMap()
            val dto: ClientSimpleDto =
                supabase
                    .insertCustom(requestMap)
            val newModel = dto.toModel()

            newModel.copy(
                travelAgent = client.travelAgent,
            )
        }

    override suspend fun update(client: ClientModel): Result<ClientModel> =
        runCatching {
            val requestMap = client.toRequestMap()
            val dto: ClientSimpleDto =
                supabase
                    .updateCustom(id = client.id, requestMap)
            val newModel = dto.toModel()

            newModel.copy(
                travelAgent = client.travelAgent,
            )
        }

    override suspend fun delete(id: String): Result<Unit> =
        runCatching {
            supabase.delete(id = id)
        }

    override suspend fun getByPhoneNumbers(phoneNumbers: Set<String>): Result<List<ClientModel>> =
        runCatching {
            supabase
                .getAll {
                    filter {
                        if (phoneNumbers.isNotEmpty()) {
                            or {
                                phoneNumbers.forEach { phoneNumber ->
                                    eq("telephone", phoneNumber)
                                }
                            }
                        }
                    }
                }
                .map { it.toModel() }
        }
}
