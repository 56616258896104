package client.pages.components.service

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.varabyte.kobweb.compose.css.BackgroundPosition
import com.varabyte.kobweb.compose.css.BackgroundSize
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.css.overflowX
import com.varabyte.kobweb.compose.css.whiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.backgroundImage
import com.varabyte.kobweb.compose.ui.modifiers.backgroundPosition
import com.varabyte.kobweb.compose.ui.modifiers.backgroundSize
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flex
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.width
import kotlin.math.min
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun ColumnScope.DefaultServicePager(
    allImage: List<FileModel?>,
    onOpenImageFullScreen: (imageList: List<FileModel>, imageIndex: Int) -> Unit,
    category: CategoryModel?,
) {
    var currentPage by remember {
        mutableStateOf(0)
    }

    Box(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .fillMaxWidth()
            .flex(1)
            .margin(bottom = 7.px)
    ) {
        allImage.forEachIndexed { index, image ->
            DefaultServiceImage(
                serviceImage = image,
                category = category,
                onImageClicked = {
                    val list = allImage.filterNotNull()

                    onOpenImageFullScreen(
                        list,
                        min(index, list.lastIndex)
                    )
                },
                modifier = Modifier
                    .fillMaxSize()
                    .display(
                        if (index == currentPage)
                            DisplayStyle.Block
                        else
                            DisplayStyle.None
                    )

            )

        }
    }

    Div(
        attrs = {
            style {
                width(100.percent)
                height(68.px)
                display(DisplayStyle.Flex)
                overflowX(Overflow.Auto)
                whiteSpace(WhiteSpace.NoWrap)
            }
        },
    ) {
        Row(
            modifier = Modifier
                .alignItems(AlignItems.Stretch)
                .fillMaxHeight()
        ) {
            allImage.forEachIndexed { index, image ->
                val imageUrl = image?.url

                Box(
                    modifier = Modifier
                        .display(DisplayStyle.InlineBlock)
                        .width(112.px)
                        .fillMaxHeight()
                        .then(
                            if (imageUrl == null)
                                Modifier
                                    .backgroundColor(Colors.LightGrey)
                            else
                                Modifier
                                    .backgroundImage(url(imageUrl))
                                    .backgroundSize(BackgroundSize.Cover)
                                    .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
                                    .opacity(
                                        if (currentPage == index)
                                            1f
                                        else
                                            .4f
                                    )
                        )
                        .onClick {
                            currentPage = index
                        }
                )
            }
        }
    }
}
