package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_category_data_di_CategoryModule : Module = module {
	single() { com.essntl.features.category.data.repository.CategoryRepositoryImpl(supabase=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.category.domain.repository.CategoryRepository::class))
	single() { com.essntl.features.category.data.supabase.CategorySupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.category.data.di.CategoryModule.module : org.koin.core.module.Module get() = com_essntl_features_category_data_di_CategoryModule