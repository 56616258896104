package com.essntl.features.notification.data.repository

import NotificationDto
import com.essntl.core.supabase.getAll
import com.essntl.core.supabase.getChannel
import com.essntl.core.supabase.removeChannel
import com.essntl.core.utils.repository.RealtimeAction
import com.essntl.core.utils.repository.Repository
import com.essntl.features.notification.data.supabase.NotificationSupabaseDataSource
import com.essntl.features.notification.domain.model.NotificationModel
import com.essntl.features.notification.domain.repository.NotificationRepository
import io.github.jan.supabase.realtime.RealtimeChannel
import kotlinx.coroutines.NonCancellable
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.withContext
import org.koin.core.annotation.Single

@Single
internal class NotificationRepositoryImpl internal constructor(
    private val supabase: NotificationSupabaseDataSource,
) : Repository(), NotificationRepository {
    private var channel: RealtimeChannel? = null

    override suspend fun collectRealTimeNotifications(): Result<Flow<RealtimeAction<NotificationModel>>> =
        runCatching {
            if (channel != null) unsubscribeChannel()

            channel = supabase.getChannel()

            realTimeActions<NotificationModel, NotificationDto>(
                channel = channel!!,
                tableName = supabase.tableName,
                mapper = {
                    println("it.toModel ${it.toModel()}")
                    it.toModel()
                },
            )
        }

    override suspend fun unsubscribeChannel(): Result<Unit> =
        runCatching {
            if (channel != null) {
                withContext(NonCancellable) {
                    channel?.unsubscribe()
                    supabase.removeChannel(channel!!)
                    channel = null
                }
            }
        }

    override suspend fun getWhapiCallsNotificationsByChatId(chatId: String): Result<List<NotificationModel>> =
        runCatching {
            supabase.getAll{
                filter {
                    and {
                        eq("type", "WHATSAPP_CALL")
                        eq("chat_id", chatId)
                    }
                }
            }
                .map { it.toModel() }
        }

}