package client.pages.components.service

import androidx.compose.runtime.Composable
import client.toSitePalette
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.px

@Composable
fun ItineraryDayInfosPriceCard(
    price: String,
    modifier: Modifier = Modifier,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    Column(
        horizontalAlignment = Alignment.Start,
        modifier = modifier
            .borderRadius(16.px)
            .backgroundColor(Color.rgb(0xd9b5bb))
            .padding(
                topBottom = 14.px,
                leftRight = 20.px,
            )
    ) {
        SpanText(
            text = price,
            modifier = Modifier
                .align(Alignment.CenterHorizontally)
                .color(sitePalette.text.primary)
                .fontSize(24.px)
                .lineHeight(28.px)
                .fontWeight(FontWeight.SemiBold)
        )
    }
}