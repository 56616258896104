package com.essntl.features.category.data.repository

import com.essntl.core.supabase.*
import com.essntl.core.utils.repository.Repository
import com.essntl.features.category.data.supabase.CategorySupabaseDataSource
import com.essntl.features.category.data.supabase.toRequestMap
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.category.domain.repository.CategoryRepository
import org.koin.core.annotation.Single

@Single
class CategoryRepositoryImpl internal constructor(
    private val supabase: CategorySupabaseDataSource,
) : Repository(), CategoryRepository {
    override suspend fun getAll(): Result<List<CategoryModel>> =
        runCatching {
            supabase
                .getAll()
                .map {
                    it.toModel()
                }
        }

    override suspend fun getByIds(ids: List<String>): Result<List<CategoryModel>> =
        runCatching {
            val categoryList =
                supabase.getByIds(ids = ids)

            categoryList
                .map { category ->
                    category.toModel()
                }
        }

    override suspend fun insert(category: CategoryModel): Result<CategoryModel> =
        runCatching {
            supabase
                .insert(category.toRequestMap())
                .toModel()
        }

    override suspend fun update(category: CategoryModel): Result<CategoryModel> =
        runCatching {
            supabase
                .update(id = category.id, category.toRequestMap())
                .toModel()
        }

    override suspend fun delete(category: CategoryModel): Result<Unit> =
        runCatching {
            supabase.delete(id = category.id)
        }
}
