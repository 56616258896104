package client.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import client.toSitePalette
import client.ui.S2SemiBold16
import client.ui.spacing
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.service.domain.model.ServiceModel
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px

@Composable
fun ProposalCollapsedService(
    number: Int,
    service: ServiceModel,
    category: CategoryModel?,
    allImage: List<FileModel?>,
    proposalServiceCount: Int,
    modifier: Modifier = Modifier,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    Column(
        horizontalAlignment = Alignment.Start,
        modifier = modifier
            .display(DisplayStyle.InlineBlock)
    ) {
//        Row(
//            verticalAlignment = Alignment.CenterVertically,
//            modifier = Modifier
//                .margin(bottom = 16.px)
//        ) {
//            Box(
//                contentAlignment = Alignment.Center,
//                modifier = Modifier
//                    .size(28.px)
//                    .borderRadius(8.px)
//                    .backgroundColor(Colors.White)
//            ) {
//                SpanText(
//                    text = "$number",
//                    modifier = S2SemiBold16.toModifier()
//                        .color(sitePalette.text.primary)
//                )
//            }
//
//            HorizontalDivider(
//                modifier = Modifier
//                    .flex(1)
//                    .height(1.px)
//                    .border {
//                        color(Colors.White)
//                    }
//            )
//        }

        Column(
            modifier = Modifier
                .width(269.px)
                .borderRadius(16.px)
                .overflow(Overflow.Hidden)
                .backgroundColor(Colors.White)
                .then(
                    if (number < proposalServiceCount)
                        Modifier
                            .margin(right = 32.px)
                    else
                        Modifier
                )
        ) {
            val imageUrl = remember(allImage, category) {
                allImage.firstOrNull()?.url ?: category?.iconUrl
            }

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(148.px)
                    .then(
                        if (imageUrl != null) {
                            Modifier
                                .backgroundImage(url(imageUrl))
                                .backgroundSize(BackgroundSize.Cover)
                                .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
                        } else {
                            Modifier
                                .backgroundColor(Colors.White)
                        }
                    )
            )

            SpanText(
                text = service.title,
                modifier = Modifier
                    .color(sitePalette.text.primary)
                    .fillMaxWidth()
                    .height(68.px)
                    .overflow(Overflow.Hidden)
                    .textOverflow(TextOverflow.Ellipsis).padding(
                        top = 1.5f.spacing,
                        leftRight = 1.5f.spacing,
                        bottom = 2.spacing,
                    )
            )
        }
    }
}