package com.essntl.features.area.data.supabase

import com.essntl.features.area.domain.model.AreaModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AreaDto(
    val id: String,
    val name: String,
    val country: String,
    @SerialName("created_at")
    val createdAt: String,
) {
    fun toModel() =
        AreaModel(
            id = id,
            name = name,
            country = country,
            createdAt = createdAt,
        )
}
