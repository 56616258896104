package client.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.essntl.core.utils.utils.loadingmanager.LoadingManager
import com.essntl.core.utils.viewmodel.ViewModel
import com.essntl.core.utils.viewmodel.getData
import com.essntl.features.area.domain.repository.AreaRepository
import com.essntl.features.category.domain.repository.CategoryRepository
import com.essntl.features.client.domain.repository.ClientRepository
import com.essntl.features.file.domain.repository.FileRepository
import com.essntl.features.itinerary.domain.repository.ItineraryRepository
import com.essntl.features.paymentlink.domain.repository.PaymentLinkRepository
import com.essntl.features.proposal.domain.model.ProposalModel
import com.essntl.features.proposal.domain.repository.ProposalRepository
import com.essntl.features.service.domain.repository.ServiceRepository
import com.essntl.features.tag.domain.repository.TagRepository
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.compose.koinInject
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

@Composable
internal fun rememberProposalViewModel(): ProposalItineraryViewModel {
    val scope = rememberCoroutineScope()
    val proposalRepository = koinInject<ProposalRepository>()
    val fileRepository = koinInject<FileRepository>()
    val categoryRepository = koinInject<CategoryRepository>()
    val tagRepository = koinInject<TagRepository>()
    val areaRepository = koinInject<AreaRepository>()
    val serviceRepository = koinInject<ServiceRepository>()
    val clientRepository = koinInject<ClientRepository>()
    val itineraryRepository = koinInject<ItineraryRepository>()
    val paymentLinkRepository = koinInject<PaymentLinkRepository>()
    val loadingManager = koinInject<LoadingManager>()

    return remember {
        ProposalItineraryViewModel(
            scope = scope,
            proposalRepository = proposalRepository,
            itineraryRepository = itineraryRepository,
            fileRepository = fileRepository,
            categoryRepository = categoryRepository,
            tagRepository = tagRepository,
            areaRepository = areaRepository,
            serviceRepository = serviceRepository,
            clientRepository = clientRepository,
            paymentLinkRepository = paymentLinkRepository,
            loadingManager = loadingManager
        )
    }
}

class ProposalItineraryViewModel(
    override val scope: CoroutineScope,
    override val loadingManager: LoadingManager,
    private val proposalRepository: ProposalRepository,
    private val itineraryRepository: ItineraryRepository,
    private val fileRepository: FileRepository,
    private val categoryRepository: CategoryRepository,
    private val tagRepository: TagRepository,
    private val areaRepository: AreaRepository,
    private val serviceRepository: ServiceRepository,
    private val clientRepository: ClientRepository,
    private val paymentLinkRepository: PaymentLinkRepository,
) : ViewModel {

    private val _state = MutableStateFlow(ProposalState())
    val state = _state.asStateFlow()

    fun onEvent(event: ProposalItineraryEvent) {
        when (event) {
            is ProposalItineraryEvent.GetProposalPreview -> {
                getProposalPreview(event)
                getAllProposalService(event.id)
            }

            is ProposalItineraryEvent.GetProposal -> {
                getProposal(event)
            }

            is ProposalItineraryEvent.GetProposalHistory -> {
                getProposalHistory(event)
            }

            is ProposalItineraryEvent.GetItinerary -> {
                getItinerary(event)
            }

            is ProposalItineraryEvent.GetPreviewItinerary -> {
                getPreviewItinerary(event)
                getAllItineraryService(event.id)
            }

            is ProposalItineraryEvent.DownloadFile -> downloadFiles(event)

            is ProposalItineraryEvent.ClearState -> _state.value = ProposalState()
        }

        getAllCategory()
        getAllTag()
    }

    private fun getItinerary(event: ProposalItineraryEvent.GetItinerary) {
        getData(
            getData = { itineraryRepository.getItineraryByShareCode(event.shareCode) },
            getState = { _state.value.getItinerary },
            setState = { _state.value = _state.value.copy(getItinerary = it) },
            onSuccess = { itinerary ->
                itinerary.proposal?.let { proposal ->
                    getProposalLinkedObjects(proposal)
                }
                getAllItineraryService(itineraryId = itinerary.id)
            },
        )
    }

    private fun getPreviewItinerary(event: ProposalItineraryEvent.GetPreviewItinerary) {
        getData(
            getData = { itineraryRepository.get(event.id) },
            getState = { _state.value.getItinerary },
            setState = { _state.value = _state.value.copy(getItinerary = it) },
            onSuccess = { itinerary ->
                itinerary.proposal?.let { proposal ->
                    getProposalLinkedObjects(proposal)
                }
            },
        )
    }

    private fun getProposalPreview(event: ProposalItineraryEvent.GetProposalPreview) =
        getData(
            getData = { proposalRepository.getProposal(event.id) },
            getState = { _state.value.getProposal },
            setState = { _state.value = _state.value.copy(getProposal = it) },
            onSuccess = { proposal ->
                getProposalLinkedObjects(proposal)
                getAllProposalService(proposal.id)
            },
        )

    private fun getProposalLinkedObjects(proposal: ProposalModel) {
        getPaymentLinks(proposal.id)
        proposal.coverImage?.id?.let { getProposalCoverImage(it) }
        proposal.primaryClient?.let { getPrimaryClient(it) }
        proposal.areas?.firstOrNull()?.let { getArea(it) }

        proposal.travelAgent?.let { ta ->
            ta.logoFile?.let { file ->
                if (file.id.isNotEmpty())
                    getTravelAgentLogo(file.id)
            }
        }
    }

    private fun getProposalHistory(event: ProposalItineraryEvent.GetProposalHistory) =
        getData(
            getData = {
                proposalRepository.getProposalHistory(
                    shareCode = event.shareCode,
                    version = event.version,
                )
            },
            getState = { _state.value.getProposal },
            setState = { _state.value = _state.value.copy(getProposal = it) },
            onSuccess = { proposal ->
                getProposalLinkedObjects(proposal)
                getAllProposalService(proposal.id)
            },
        )

    private fun getProposal(event: ProposalItineraryEvent.GetProposal) =
        getData(
            getData = { proposalRepository.getLatestProposalHistory(event.shareCode) },
            getState = { _state.value.getProposal },
            setState = { _state.value = _state.value.copy(getProposal = it) },
            onSuccess = { proposal ->
                getProposalLinkedObjects(proposal)
                getAllProposalService(proposal.id)
            },
        )

    private var getProposalCoverImageJob: Job? = null
    private fun getProposalCoverImage(imageId: String) {
        getProposalCoverImageJob?.cancel()
        getProposalCoverImageJob = getData(
            getData = { fileRepository.getById(imageId) },
            getState = { _state.value.getProposalCoverImage },
            setState = { _state.value = _state.value.copy(getProposalCoverImage = it) },
        )
    }

    private var getPrimaryClientJob: Job? = null
    private fun getPrimaryClient(clientId: String) {
        getPrimaryClientJob?.cancel()
        getPrimaryClientJob = getData(
            getData = { clientRepository.getById(clientId) },
            getState = { _state.value.getPrimaryClient },
            setState = { _state.value = _state.value.copy(getPrimaryClient = it) },
        )
    }

    private var getAreaJob: Job? = null
    private fun getArea(areaId: String) {
        getAreaJob?.cancel()
        getAreaJob = getData(
            getData = { areaRepository.get(areaId) },
            getState = { _state.value.getArea },
            setState = { _state.value = _state.value.copy(getArea = it) },
        )
    }

    private var getPaymentLinkJob: Job? = null
    private fun getPaymentLinks(proposalId: String) {
        getPaymentLinkJob?.cancel()
        getPaymentLinkJob = getData(
            getData = { paymentLinkRepository.getProposalActivePaymentLink(proposalId) },
            getState = { _state.value.getPaymentLinks },
            setState = { _state.value = _state.value.copy(getPaymentLinks = it) },
        )
    }

    private fun getTravelAgentLogo(id: String) =
        getData(
            getData = { fileRepository.getById(id = id) },
            getState = { _state.value.getTravelAgentLogo },
            setState = { _state.value = _state.value.copy(getTravelAgentLogo = it) },
        )

    private var getAllProposalServiceJob: Job? = null
    private fun getAllProposalService(proposalId: String) {
        getAllProposalServiceJob?.cancel()
        getAllProposalServiceJob = getData(
            getData = { proposalRepository.getAllProposalService(proposalId) },
            getState = { _state.value.getAllProposalService },
            setState = { _state.value = _state.value.copy(getAllProposalService = it) },
            onSuccess = {
                getAllService(it.map { service -> service.serviceId }.distinct())
            },
        )
    }

    private var getAllItineraryServiceJob: Job? = null
    private fun getAllItineraryService(itineraryId: String) {
        getAllItineraryServiceJob?.cancel()
        getAllItineraryServiceJob = getData(
            getData = { itineraryRepository.getAllItineraryService(itineraryId) },
            getState = { _state.value.getAllItineraryService },
            setState = { _state.value = _state.value.copy(getAllItineraryService = it) },
            onSuccess = {
                getAllService(it.map { service -> service.serviceId }.distinct())
                getAllFile(it.flatMap { service -> service.files.orEmpty() }.distinct())
            },
        )
    }

    private fun getAllCategory() =
        getData(
            getData = { categoryRepository.getAll() },
            getState = { _state.value.getAllCategory },
            setState = { _state.value = _state.value.copy(getAllCategory = it) },
        )

    private fun getAllTag() =
        getData(
            getData = { tagRepository.getAll() },
            getState = { _state.value.getAllTag },
            setState = { _state.value = _state.value.copy(getAllTag = it) },
        )

    private var getAllServiceJob: Job? = null
    private fun getAllService(ids: List<String>) {
        getAllServiceJob?.cancel()
        getAllServiceJob = getData(
            getData = { serviceRepository.getByIds(ids = ids) },
            getState = { _state.value.getAllService },
            setState = { _state.value = _state.value.copy(getAllService = it) },
            onSuccess = {
                getAllImage(it.flatMap { service -> service.images.orEmpty() }.distinct())
            },
        )
    }

    private var getAllImageJob: Job? = null
    private fun getAllImage(ids: List<String>) {
        getAllImageJob?.cancel()
        getAllImageJob = getData(
            getData = { fileRepository.getByIds(ids = ids) },
            getState = { _state.value.getAllImage },
            setState = { _state.value = _state.value.copy(getAllImage = it) },
        )
    }

    private var getAllFileJob: Job? = null
    private fun getAllFile(ids: List<String>) {
        getAllFileJob?.cancel()
        getAllFileJob = getData(
            getData = { fileRepository.getByIds(ids = ids) },
            getState = { _state.value.getAllFile },
            setState = { _state.value = _state.value.copy(getAllFile = it) },
        )
    }

    private fun downloadFiles(event: ProposalItineraryEvent.DownloadFile) =
        scope.launch {
            fileRepository.downloadFiles(
                bucketName = "files",
                file = event.file,
            ).onSuccess { byteArray ->
                val blob = Blob(arrayOf(byteArray), BlobPropertyBag("*/*"))
                val link = document.createElement("a") as HTMLAnchorElement
                link.href = URL.createObjectURL(blob)
                link.download = event.file.name ?: "file.txt"
                link.click()
            }.onFailure { throwable ->
                println("Download Fail: $throwable")
            }
        }
}