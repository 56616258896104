import androidx.compose.runtime.Composable
import client.components.widgets.EsCard
import client.pages.ServiceUiState
import client.pages.components.service.ServiceContactDetailsCard
import com.essntl.core.utils.datetime.formatTimestampToHHMM
import com.essntl.core.utils.utils.letIfNotEmpty
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.jetbrains.compose.web.css.px

@Composable
fun ItineraryTimeAddressCard(
    proposalService: ServiceUiState,
    modifier: Modifier = Modifier,
) {
    Column(modifier = modifier) {
        Row(
            modifier = Modifier
                .margin(bottom = 10.px),
        ) {
            StartTimeEndTime(proposalService = proposalService)
            ServiceContactDetailsCard(proposalService = proposalService)
        }

        Row {
            MeetingPoint(proposalService = proposalService)
            proposalService.dynamicFields?.let {
                it.map { d ->
                    if (d.showOnItinerary)
                        EsCard(
                            title = d.fieldName,
                            description = d.value,
                            modifier = Modifier.margin(right = 10.px),
                        )
                }
            }
        }
    }
}

@Composable
fun StartTimeEndTime(proposalService: ServiceUiState) {
    proposalService.startTime?.letIfNotEmpty {
        EsCard(
            title = "Start time",
            description = formatTimestampToHHMM(it),
            modifier = Modifier
                .margin(right = 10.px),
        )
    }

    proposalService.endTime?.letIfNotEmpty {
        EsCard(
            title = "End time",
            description = formatTimestampToHHMM(it),
            modifier = Modifier
                .margin(right = 10.px),
        )
    }
}

@Composable
fun MeetingPoint(proposalService: ServiceUiState) {
    proposalService.address?.letIfNotEmpty {
        EsCard(
            title = "Meeting point",
            description = it,
            modifier = Modifier
                .margin(right = 10.px),
        )
    }
}