package com.essntl.features.category.data.supabase

import com.essntl.features.category.domain.model.DynamicField
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DynamicFieldDto(
    @SerialName("field_name")
    val fieldName: String,
    @SerialName("show_on_itinerary")
    val showOnItinerary: Boolean,
) {
    fun toModel(): DynamicField {
        return DynamicField(
            fieldName = fieldName,
            showOnItinerary = showOnItinerary,
        )
    }
}
