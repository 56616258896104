package com.essntl.features.itinerary.domain.model

import com.essntl.features.proposal.domain.model.ProposalModel

data class ItineraryModel(
    val id: String,
    val proposal: ProposalModel?,
    val createdAt: String,
    val shareCode: String?,
) {
    val isEmpty get() = this.copy(id = "", createdAt = "") == empty

    companion object {
        val empty =
            ItineraryModel(
                id = "",
                createdAt = "",
                proposal = null,
                shareCode = null,
            )
    }
}
