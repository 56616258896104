package client.pages.components

import androidx.compose.runtime.*
import client.components.widgets.EsSmallOutlineIconButton
import client.pages.ServiceUiState
import client.toSitePalette
import client.ui.EsIcons
import client.ui.iconSrc
import com.essntl.core.utils.viewmodel.State
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.proposal.domain.model.ProposalServiceModel
import com.essntl.features.service.domain.model.ServiceModel
import com.essntl.features.tag.domain.model.TagModel
import com.varabyte.kobweb.browser.dom.observers.ResizeObserver
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

@Composable
fun ItineraryDayInfosCollapsed(
    isExpanded: Boolean,
    allDayProposalService: List<ServiceUiState>,
    getAllService: State<List<ServiceModel>>,
    getAllCategory: State<List<CategoryModel>>,
    getAllTag: State<List<TagModel>>,
    getAllImage: State<List<FileModel>>,
    onProposalServiceSelected: (ServiceUiState) -> Unit,
    modifier: Modifier = Modifier,
) {
    val sitePalette = ColorMode.current.toSitePalette()
    val breakpoint = rememberBreakpoint()

    var canScrollLeft by remember { mutableStateOf(false) }
    var canScrollRight by remember { mutableStateOf(false) }

    LaunchedEffect(isExpanded) {
        val element = document.getElementById("collapsed-service-list") ?: return@LaunchedEffect

        ResizeObserver { _ ->
            canScrollLeft = element.scrollLeft > 10
            canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
        }.observe(element)

        element.addEventListener("scroll", { _ ->
            canScrollLeft = element.scrollLeft > 10
            canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
        })

        delay(1000)

        canScrollLeft = element.scrollLeft > 10
        canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
    }

    Box(
        modifier = modifier
            .width(100.percent)
            .padding(
                topBottom = if (breakpoint < Breakpoint.MD) 14.px else 32.px,
            )
    ) {
        Div(
            attrs = {
                id("collapsed-service-list")

                style {
                    width(100.percent)
                    display(DisplayStyle.Flex)
                    flex(1)
                    overflowX(Overflow.Auto)
                    whiteSpace(WhiteSpace.NoWrap)
                }
            },
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                val leftRightMargin = if (breakpoint < Breakpoint.MD) 14.px else 32.px

                allDayProposalService.forEachIndexed { index, proposalService ->
                    val service = remember(getAllService.data, proposalService.serviceId) {
                        getAllService.data?.find { it.id == proposalService.serviceId }
                    }

                    val category = remember(getAllCategory.data, service?.category) {
                        getAllCategory.data?.find { it.id == service?.category }
                    }

                    val allServiceImage = remember(getAllImage.data, service?.images) {
                        service?.images?.map { imageId ->
                            getAllImage.data?.find { it.id == imageId }
                        } ?: emptyList()
                    }

                    if (service != null) {
                        ProposalCollapsedService(
                            number = index + 1,
                            service = service,
                            category = category,
                            allImage = allServiceImage,
                            proposalServiceCount = allDayProposalService.size,
                            modifier = Modifier
                                .padding(
                                    left = if (index == 0) leftRightMargin else 0.px,
                                    right = if (index == allDayProposalService.lastIndex) leftRightMargin else 0.px
                                )
                                .onClick {
                                    onProposalServiceSelected(proposalService)
                                }
                        )
                    }
                }

                Box(
                    modifier = Modifier
                        .width(if (breakpoint < Breakpoint.MD) 14.px else 32.px)
                )
            }
        }

        if (canScrollLeft)
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier
                    .height(100.percent)
                    .align(Alignment.CenterStart)
                    .padding(10.px)
                    .zIndex(2)
            ) {
                EsSmallOutlineIconButton(
                    onClick = {
                        val element = document.getElementById("collapsed-service-list")
                            ?: return@EsSmallOutlineIconButton

                        element.scrollTo(
                            ScrollToOptions(
                                left = element.scrollLeft - 360.0,
                                behavior = ScrollBehavior.SMOOTH,
                            )
                        )
                    },
                    iconRes = iconSrc(EsIcons.ArrowLeft),
                )
            }

        if (canScrollRight)
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier
                    .height(100.percent)
                    .align(Alignment.CenterEnd)
                    .padding(10.px)
                    .zIndex(2)
            ) {
                EsSmallOutlineIconButton(
                    onClick = {
                        val element = document.getElementById("collapsed-service-list")
                            ?: return@EsSmallOutlineIconButton

                        element.scrollTo(
                            ScrollToOptions(
                                left = element.scrollLeft + 360.0,
                                behavior = ScrollBehavior.SMOOTH,
                            )
                        )
                    },
                    iconRes = iconSrc(EsIcons.ArrowRight),
                )
            }
    }
}