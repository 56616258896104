package com.essntl.features.area.data.supabase

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class AreaSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<AreaDto> {
    override val tableName: String = "area"
}
