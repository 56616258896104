package client.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import client.components.widgets.EsSmallOutlineIconButton
import client.pages.ServiceUiState
import client.pages.components.service.FullScreenServiceImage
import client.termsandconditions.TermsAndConditionsContent
import client.ui.EsIcons
import client.ui.iconSrc
import com.essntl.core.utils.datetime.parseLocalDateOrNull
import com.essntl.core.utils.viewmodel.State
import com.essntl.features.area.domain.model.AreaModel
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.client.domain.model.ClientModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.paymentlink.domain.model.PaymentLinkModel
import com.essntl.features.proposal.domain.model.ProposalModel
import com.essntl.features.service.domain.model.ServiceModel
import com.essntl.features.tag.domain.model.TagModel
import client.utils.rememberNumberOfDays
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import core.config.buildFlavor
import kotlin.math.absoluteValue
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.plus
import org.jetbrains.compose.web.css.*
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

@Composable
fun ProposalScreenContent(
    proposal: ProposalModel,
    isItinerary: Boolean = false,
    getProposalCoverImage: State<FileModel>,
    getProposalPrimaryClient: State<ClientModel>,
    getTravelAgentLogo: State<FileModel>,
    getArea: State<AreaModel>,
    allProposalService: List<ServiceUiState>,
    getAllService: State<List<ServiceModel>>,
    getAllCategory: State<List<CategoryModel>>,
    getAllTag: State<List<TagModel>>,
    getAllImage: State<List<FileModel>>,
    paymentLinks: List<PaymentLinkModel>?,
    getAllFile: State<List<FileModel>>,
    onDownloadFile: (FileModel) -> Unit,
) {
    var selectedDay by remember { mutableStateOf(1) }
    val totalDays = rememberNumberOfDays(proposal.fromDate, proposal.toDate) ?: 0

    val breakpoint = rememberBreakpoint()

    LaunchedEffect(totalDays) {
        delay(2000)

        val elementList =
            List(totalDays) {
                document.getElementById("day-${it + 1}")
            }

        window.addEventListener(
            "scroll",
            { _ ->
                var currentElement: Int = -1
                var minTop = Double.MAX_VALUE
                elementList.forEachIndexed { index, element ->
                    if (element == null)
                        return@forEachIndexed

                    val top = element.getBoundingClientRect().top
                    println("day-${index + 1} top: $top")
                    if (top.absoluteValue < minTop) {
                        minTop = top.absoluteValue
                        currentElement = index
                    }
                }

                if (currentElement > -1) {
                    selectedDay = currentElement + 1
                }
            },
        )
    }

    var selectedImageList by remember { mutableStateOf<List<FileModel>>(emptyList()) }
    var selectedImageIndex by remember { mutableStateOf(-1) }

    Box(
        contentAlignment = Alignment.TopCenter,
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(Color.white),
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
            modifier = Modifier
                .maxWidth(1200.px),
        ) {
            ProposalCover(
                proposal = proposal,
                proposalCoverImage = getProposalCoverImage.data,
                proposalPrimaryClient = getProposalPrimaryClient.data,
                travelAgentLogo = getTravelAgentLogo.data,
                area = getArea.data,
            )

            ItineraryNavRow(
                totalDays = totalDays,
                fromDate = proposal.fromDate,
                selectedDay = selectedDay,
                onDaySelected = {
                    selectedDay = it
                    val element = document.getElementById("day-$it") ?: return@ItineraryNavRow
                    window.scrollBy(
                        ScrollToOptions(
                            top = element.getBoundingClientRect().top - if (breakpoint >= Breakpoint.MD) 108.0 else 0.0,
                            behavior = ScrollBehavior.SMOOTH,
                        ),
                    )
                },
            )

            repeat(totalDays) { dayIndex ->
                val day = remember(proposal.fromDate, dayIndex) {
                    parseLocalDateOrNull(proposal.fromDate)?.plus(DatePeriod(days = dayIndex))
                }

                val allDayProposalService = remember(allProposalService, day) {
                    allProposalService
                        .filter { it.startDate == day?.toString() }
                        .filter { it.status != ServiceUiState.Status.Cancelled }
                        .sortedBy { it.index }
                }

                LaunchedEffect(dayIndex, proposal, selectedDay, allProposalService, allDayProposalService) {
                    if (dayIndex + 1 == selectedDay && allDayProposalService.isEmpty()) {
                        val nonEmptyDays = (0 until totalDays)
                            .mapNotNull { i ->
                                val d = parseLocalDateOrNull(proposal.fromDate)?.plus(DatePeriod(days = i))

                                val list = allProposalService
                                    .filter { it.startDate == d?.toString() }
                                    .filter { it.status != ServiceUiState.Status.Cancelled }
                                    .sortedBy { it.index }

                                if (list.isNotEmpty()) i + 1 else null
                            }

                        selectedDay =
                            nonEmptyDays.find { it > selectedDay }
                            ?: nonEmptyDays.findLast { it < selectedDay }
                            ?: 1
                    }
                }

                if (day != null && allDayProposalService.isNotEmpty())
                    ItineraryDayInfos(
                        day = day,
                        dayNumber = dayIndex + 1,
                        isItinerary = isItinerary,
                        allDayServices = allDayProposalService,
                        getAllService = getAllService,
                        getAllCategory = getAllCategory,
                        getAllTag = getAllTag,
                        getAllImage = getAllImage,
                        getAllFile = getAllFile,
                        onDownloadFile = onDownloadFile,
                        onOpenImageFullScreen = { imageList, imageIndex ->
                            selectedImageList = imageList
                            selectedImageIndex = imageIndex
                        },
                    )
            }

            if (!buildFlavor.isDemo) {
                TermsAndConditionsContent(
                    initialIsExpanded = false,
                )
            }
            ProposalFooter()
        }

//        if (paymentLinks.orEmpty().isNotEmpty())
//            ProposalBookingCard(
//                paymentLinks = paymentLinks,
//                proposal = proposal,
//                proposalCoverImage = getProposalCoverImage.data,
//            )

        if (selectedImageList.isNotEmpty()) {
            val image = remember(selectedImageIndex, selectedImageList) {
                selectedImageList.getOrNull(selectedImageIndex)
            }

            if (image != null) {
                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(100.vh)
                        .backgroundColor(rgba(0, 0, 0, .6f))
                        .position(Position.Fixed)
                        .zIndex(3),
                ) {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier
                            .fillMaxSize()
                            .padding(
                                leftRight = 20.px,
                                topBottom = 40.px,
                            ),
                    ) {
                        FullScreenServiceImage(
                            serviceImage = image,
                            canGoBackward = selectedImageIndex > 0,
                            canGoForward = selectedImageIndex < selectedImageList.lastIndex,
                            onGoBackward = {
                                if (selectedImageIndex > 0)
                                    selectedImageIndex -= 1
                            },
                            onGoForward = {
                                if (selectedImageIndex < selectedImageList.lastIndex)
                                    selectedImageIndex += 1
                            },
                            modifier = Modifier
                                .maxWidth(1200.px),
                        )
                    }

                    EsSmallOutlineIconButton(
                        onClick = {
                            selectedImageList = emptyList()
                            selectedImageIndex = -1
                        },
                        iconRes = iconSrc(EsIcons.Close),
                        modifier = Modifier
                            .align(Alignment.TopEnd)
                            .margin(20.px),
                    )
                }
            }
        }
    }
}