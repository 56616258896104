package com.essntl.features.itinerary.data.supabase.itinerary_service

import com.essntl.features.itinerary.domain.model.DynamicField
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DynamicFieldDto(
    @SerialName("field_name")
    val fieldName: String,
    @SerialName("show_on_itinerary")
    val showOnItinerary: Boolean,
    val value: String,
) {
    fun toModel(): DynamicField {
        return DynamicField(
            fieldName = fieldName,
            showOnItinerary = showOnItinerary,
            value = value,
        )
    }
}

internal fun DynamicField.toDto(): DynamicFieldDto {
    return DynamicFieldDto(
        fieldName = fieldName,
        showOnItinerary = showOnItinerary,
        value = value,
    )
}
