package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_paymentlink_data_di_PaymentLinkModule : Module = module {
	single() { com.essntl.features.paymentlink.data.repository.PaymentLinkRepositoryImpl(supabase=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.paymentlink.domain.repository.PaymentLinkRepository::class))
	single() { com.essntl.features.paymentlink.data.supabase.PaymentLinkSupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.paymentlink.data.di.PaymentLinkModule.module : org.koin.core.module.Module get() = com_essntl_features_paymentlink_data_di_PaymentLinkModule