package client.pages.components.hotel

import androidx.compose.runtime.*
import client.components.widgets.EsSmallOutlineIconButton
import client.pages.ServiceUiState
import client.toSitePalette
import client.ui.EsIcons
import client.ui.iconSrc
import com.essntl.core.utils.viewmodel.State
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.proposal.domain.model.ProposalServiceModel
import com.essntl.features.service.domain.model.ServiceModel
import com.essntl.features.tag.domain.model.TagModel
import com.varabyte.kobweb.browser.dom.observers.ResizeObserver
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.css.overflowX
import com.varabyte.kobweb.compose.css.whiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

@Composable
fun HotelList(
    allProposalService: List<ServiceUiState>,
    getAllService: State<List<ServiceModel>>,
    getAllTag: State<List<TagModel>>,
    getAllImage: State<List<FileModel>>,
    getAllCategory: State<List<CategoryModel>>,
    onOpenImageFullScreen: (imageList: List<FileModel>, imageIndex: Int) -> Unit,
) {
    val sitePalette = ColorMode.current.toSitePalette()
    val breakpoint = rememberBreakpoint()

    var canScrollLeft by remember { mutableStateOf(false) }
    var canScrollRight by remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        val element = document.getElementById("hotel-list") ?: return@LaunchedEffect

        ResizeObserver { _ ->
            canScrollLeft = element.scrollLeft > 10
            canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
        }.observe(element)

        element.addEventListener("scroll", { _ ->
            canScrollLeft = element.scrollLeft > 10
            canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
        })

        delay(1000)

        canScrollLeft = element.scrollLeft > 10
        canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
    }

    Box(
        modifier = Modifier
            .width(100.percent)
    ) {
        Div(
            attrs = {
                id("hotel-list")

                style {
                    width(100.percent)
                    display(DisplayStyle.Flex)
                    flex(1)
                    if (breakpoint >= Breakpoint.MD) {
                        overflowX(Overflow.Auto)
//                        whiteSpace(WhiteSpace.NoWrap)
                    }
                }
            },
        ) {
            Row(
                modifier = Modifier
                    .alignItems(AlignItems.Stretch)
                    .flexDirection(if (breakpoint < Breakpoint.MD) FlexDirection.Column else FlexDirection.Row)
                    .then(
                        if (breakpoint < Breakpoint.MD)
                            Modifier
                                .fillMaxWidth()
                        else
                            Modifier
                    )
            ) {
                Box(
                    modifier = Modifier
                        .width(if (breakpoint < Breakpoint.MD) 14.px else 32.px)
                )

                allProposalService.forEachIndexed { index, proposalService ->
                    val service = remember(proposalService, getAllService) {
                        getAllService.data?.find { it.id == proposalService.serviceId }
                    }

                    val allServiceTag = remember(service, getAllTag) {
                        service?.tags?.mapNotNull { tagId -> getAllTag.data?.find { it.id == tagId } } ?: emptyList()
                    }

                    val allServiceImage = remember(service, getAllImage) {
                        service?.images?.map { imageId -> getAllImage.data?.find { it.id == imageId } } ?: emptyList()
                    }

                    val category = remember(service, getAllCategory) {
                        getAllCategory.data?.find { it.id == service?.category }
                    }

                    if (service != null) {
                        HotelItem(
                            proposalService = proposalService,
                            service = service,
                            category = category,
                            allTag = allServiceTag,
                            allImage = allServiceImage,
                            onOpenImageFullScreen = onOpenImageFullScreen,
                            modifier = Modifier
                                .display(if (breakpoint < Breakpoint.MD) DisplayStyle.Block else DisplayStyle.InlineBlock)
                                .width(if (breakpoint < Breakpoint.MD) 100.percent else 360.px)
                                .margin(
                                    right =
                                    if (index < allProposalService.lastIndex && breakpoint >= Breakpoint.MD)
                                        20.px
                                    else
                                        0.px,
                                    bottom =
                                    if (index < allProposalService.lastIndex && breakpoint < Breakpoint.MD)
                                        20.px
                                    else
                                        0.px,
                                )
                        )
                    }
                }

                Box(
                    modifier = Modifier
                        .width(if (breakpoint < Breakpoint.MD) 14.px else 32.px)
                )
            }
        }

        if (breakpoint >= Breakpoint.MD) {
            if (canScrollLeft)
                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier
                        .height(100.percent)
                        .align(Alignment.CenterStart)
                        .padding(20.px)
                        .zIndex(2)
                ) {
                    EsSmallOutlineIconButton(
                        onClick = {
                            val element = document.getElementById("hotel-list")
                                ?: return@EsSmallOutlineIconButton

                            element.scrollTo(
                                ScrollToOptions(
                                    left = element.scrollLeft - 360.0,
                                    behavior = ScrollBehavior.SMOOTH,
                                )
                            )
                        },
                        iconRes = iconSrc(EsIcons.ArrowLeft),
                    )
                }

            if (canScrollRight)
                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier
                        .height(100.percent)
                        .align(Alignment.CenterEnd)
                        .padding(20.px)
                        .zIndex(2)
                ) {
                    EsSmallOutlineIconButton(
                        onClick = {
                            val element = document.getElementById("hotel-list")
                                ?: return@EsSmallOutlineIconButton

                            element.scrollTo(
                                ScrollToOptions(
                                    left = element.scrollLeft + 360.0,
                                    behavior = ScrollBehavior.SMOOTH,
                                )
                            )
                        },
                        iconRes = iconSrc(EsIcons.ArrowRight),
                    )
                }
        }
    }
}