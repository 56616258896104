package com.essntl.features.service.data.supabase

import com.essntl.features.file.data.supabase.FileDto
import com.essntl.features.service.domain.model.ServiceModel
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class ServiceDto(
    val id: String,
    val title: String,
    val category: String?,
    val description: String?,
    val location: String?,
    @SerialName("area_id")
    val areaId: String?,
    val headline: String?,
    val tags: List<String>?,
    val images: List<String>?,
    @SerialName("primary_image_data")
    val primaryImage: FileDto? = null,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("updated_at")
    val updatedAt: String?,
    @SerialName("reference_code")
    val referenceCode: String?,
    @SerialName("internal_notes")
    val internalNotes: String?,
    val suppliers: List<String>? = null,
    @SerialName("qb_item_id")
    val qbItemId: String? = null,
) {
    fun toModel() =
        ServiceModel(
            id = id,
            title = title,
            category = category,
            description = description,
            location = location,
            areaId = areaId,
            headline = headline,
            tags = tags?.toSet(),
            images = images?.toSet(),
            primaryImage = primaryImage?.toModel(),
            createdAt = createdAt,
            updatedAt = updatedAt,
            referenceCode = referenceCode,
            internalNotes = internalNotes,
            suppliers = suppliers,
            qbItemId = qbItemId,
        )

    companion object {
        fun getColumns() = Columns.raw("*, primary_image_data:primary_image(*)")
    }
}
