package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_notification_data_di_NotificationModule : Module = module {
	single() { com.essntl.features.notification.data.repository.NotificationRepositoryImpl(supabase=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.notification.domain.repository.NotificationRepository::class))
	single() { com.essntl.features.notification.data.supabase.NotificationSupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.notification.data.di.NotificationModule.module : org.koin.core.module.Module get() = com_essntl_features_notification_data_di_NotificationModule