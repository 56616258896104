package client.pages.components

import com.essntl.features.file.domain.model.FileModel
import com.varabyte.kobweb.compose.css.BackgroundImage
import com.varabyte.kobweb.compose.css.BackgroundPosition
import com.varabyte.kobweb.compose.css.BackgroundSize
import com.varabyte.kobweb.compose.css.CSSBackground
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba

fun proposalCoverModifier(
    proposalCoverImage: FileModel?,
): Modifier {
    return Modifier
        .fillMaxSize()
        .borderRadius(40.px)
        .then(
            Modifier
                .background(
                    CSSBackground(
                        image = BackgroundImage.of(
                            url(proposalCoverImage?.url ?: "")
                        ),
                        size = BackgroundSize.Cover,
                        position = BackgroundPosition.of(CSSPosition.Center)
                    ),
                    CSSBackground(
                        image = BackgroundImage.of(
                            linearGradient(
                                angle = 90.deg,
                                from = rgba(0, 0, 0, 0.6f),
                                to = rgba(0, 0, 0, 0.2f)
                            )
                        )
                    ),
                )
        )
}