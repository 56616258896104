package client.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.addVariantBase
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

@Composable
fun EsSmallOutlineIconButton(
    onClick: () -> Unit,
    iconRes: String,
    enabled: Boolean = true,
    loading: Boolean = false,
    modifier: Modifier = Modifier
) {
    Button(
        onClick = { onClick() },
        enabled = enabled,
        variant = EsSmallOutlineIconButtonVariant,
        modifier = modifier,
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxSize()
        ) {
            Image(
                src = iconRes,
                width = 15,
                height = 15,
                modifier = Modifier
            )
        }
    }
}

val EsSmallOutlineIconButtonVariant by ButtonStyle.addVariantBase {
    Modifier
        .minWidth(2.cssRem)
        .height(2.cssRem)
        .padding(0.px)
        .borderRadius(8.px)
        .border {
            width(1.px)
            style(LineStyle.Solid)
            color(Color.Companion.rgb(0xE7E6EB))
        }
        .backgroundColor(Colors.White)
}