package client.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import client.pages.ProposalItineraryEvent
import client.pages.rememberProposalViewModel
import core.config.buildFlavor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.Text

@Composable
fun ItineraryScreenContent(
    shareCode: String? = null,
    itineraryId: String? = null,
) {
    val viewModel = rememberProposalViewModel()
    val state by viewModel.state.collectAsState()

    itineraryId?.let {
        LaunchedEffect(Unit) {
            viewModel.onEvent(
                ProposalItineraryEvent.GetPreviewItinerary(id = it),
            )
        }
    }

    shareCode?.let {
        LaunchedEffect(Unit) {
            viewModel.onEvent(
                ProposalItineraryEvent.GetItinerary(shareCode = it),
            )
        }
    }

    LaunchedEffect(state.getArea.data) {
        document.title = if (buildFlavor.isDemo) "Essntl" else "Be Essential"
        state.getArea.data?.country.let { country ->
            document.title = if (buildFlavor.isDemo) "Essntl" else "Your Be Essential Itinerary to $country"
        }
    }

    val proposal = state.getItinerary.data?.proposal

    Box(
        contentAlignment = Alignment.TopStart,
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(Color.rgb(0xF2EFEA)),
    ) {
        if (proposal == null)
            Text(
                value = "Loading...",
            )
        else
            ProposalScreenContent(
                proposal = proposal,
                isItinerary = true,
                getProposalCoverImage = state.getProposalCoverImage,
                getProposalPrimaryClient = state.getPrimaryClient,
                getTravelAgentLogo = state.getTravelAgentLogo,
                getArea = state.getArea,
                allProposalService = state.itineraryServicesToUiStateList(proposal),
                getAllService = state.getAllService,
                getAllCategory = state.getAllCategory,
                getAllTag = state.getAllTag,
                getAllImage = state.getAllImage,
                paymentLinks = state.getPaymentLinks.data,
                getAllFile = state.getAllFile,
                onDownloadFile = {
                    viewModel.onEvent(
                        ProposalItineraryEvent.DownloadFile(it),
                    )
                },
            )
    }
}