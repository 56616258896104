package client.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import client.pages.components.ProposalScreen
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext

@Page(routeOverride = "trip/{shareCode}/v/{version}")
@Composable
fun ProposalHistoryPage() {
    val ctx = rememberPageContext()
    val viewModel = rememberProposalViewModel()
    val state by viewModel.state.collectAsState()

    LaunchedEffect(Unit) {
        val shareCode = ctx.route.params.getValue("shareCode")
        val version = ctx.route.params.getValue("version")

        viewModel.onEvent(
            ProposalItineraryEvent.GetProposalHistory(
                shareCode = shareCode,
                version = version.toInt()
            ),
        )
    }

    ProposalScreen(
        state = state
    )
}