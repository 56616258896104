package com.essntl.core.utils.error

open class NetworkException(
    message: String? = null,
    cause: Throwable? = null,
) : Exception(message, cause)

class UnauthorizedException(
    cause: Throwable? = null,
) : NetworkException(
    message = "Unauthorized",
    cause = cause,
)

class OfflineException(
    cause: Throwable? = null,
) : NetworkException(
    message = "Please check your internet connection",
    cause = cause,
)

class BadRequestException(
    message: String? = null,
    val code: Int? = null,
    cause: Throwable? = null,
) : NetworkException(
    message = message,
    cause = cause,
)

class DataConversionException(
    message: String? = null,
    cause: Throwable? = null,
) : NetworkException(
    message = message,
    cause = cause,
)

class UnknownNetworkException(
    message: String? = null,
    cause: Throwable? = null,
) : NetworkException(
    message = message,
    cause = cause,
)
