package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_tag_data_di_TagModule : Module = module {
	single() { com.essntl.features.tag.data.repository.TagRepositoryImpl(supabase=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.tag.domain.repository.TagRepository::class))
	single() { com.essntl.features.tag.data.supabase.TagSupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.tag.data.di.TagModule.module : org.koin.core.module.Module get() = com_essntl_features_tag_data_di_TagModule