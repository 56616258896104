package com.essntl.features.tag.data.repository

import com.essntl.core.supabase.delete
import com.essntl.core.supabase.getAll
import com.essntl.core.supabase.insert
import com.essntl.core.supabase.update
import com.essntl.core.utils.repository.Repository
import com.essntl.features.tag.data.supabase.TagSupabaseDataSource
import com.essntl.features.tag.data.supabase.toRequestMap
import com.essntl.features.tag.domain.model.TagModel
import com.essntl.features.tag.domain.repository.TagRepository
import org.koin.core.annotation.Single

@Single
internal class TagRepositoryImpl(
    private val supabase: TagSupabaseDataSource,
) : Repository(), TagRepository {
    override suspend fun getAll(): Result<List<TagModel>> =
        runCatching {
            supabase
                .getAll()
                .map { it.toModel() }
        }

    override suspend fun insert(model: TagModel): Result<TagModel> =
        runCatching {
            supabase
                .insert(model.toRequestMap())
                .toModel()
        }

    override suspend fun update(model: TagModel): Result<TagModel> =
        runCatching {
            supabase
                .update(id = model.id, model.toRequestMap())
                .toModel()
        }

    override suspend fun delete(model: TagModel): Result<Unit> =
        runCatching {
            supabase.delete(model.id)
        }
}
