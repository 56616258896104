package com.essntl.features.travelagent.domain.model

import com.essntl.features.file.domain.model.FileModel

data class TravelAgentModel(
    val id: String,
    val email: String?,
    val website: String?,
    val agency: String?,
    val city: String?,
    val country: String?,
    val address: String?,
    val zipCode: String?,
    val firstName: String?,
    val lastName: String?,
    val jobTitle: String?,
    val notes: String?,
    val source: String?,
    val logoUrl: String?,
    val logoFile: FileModel?,
    val telephone: String?,
    val createdAt: String,
    val commissionType: CommissionType?,
    val commission: Double?,
    val qbCustomerId: String?,
) {

    val prettyName
        get() = "${firstName ?: ""} ${lastName ?: ""}"

    fun getChangedFields(other: TravelAgentModel) =
        TravelAgentModel(
            id = id,
            createdAt = createdAt,
            email = if (email.orEmpty() != other.email.orEmpty()) other.email else null,
            website = if (website.orEmpty() != other.website.orEmpty()) other.website else null,
            agency = if (agency.orEmpty() != other.agency.orEmpty()) other.agency else null,
            city = if (city.orEmpty() != other.city.orEmpty()) other.city else null,
            country = if (country.orEmpty() != other.country.orEmpty()) other.country else null,
            address = if (address.orEmpty() != other.address.orEmpty()) other.address else null,
            zipCode = if (zipCode.orEmpty() != other.zipCode.orEmpty()) other.zipCode else null,
            firstName = if (firstName.orEmpty() != other.firstName.orEmpty()) other.firstName else null,
            lastName = if (lastName.orEmpty() != other.lastName.orEmpty()) other.lastName else null,
            jobTitle = if (jobTitle.orEmpty() != other.jobTitle.orEmpty()) other.jobTitle else null,
            notes = if (notes.orEmpty() != other.notes.orEmpty()) other.notes else null,
            source = if (source.orEmpty() != other.source.orEmpty()) other.source else null,
            logoUrl = if (logoUrl.orEmpty() != other.logoUrl.orEmpty()) other.logoUrl else null,
            telephone = if (telephone.orEmpty() != other.telephone.orEmpty()) other.telephone else null,
            commissionType = if (commissionType != other.commissionType) other.commissionType else null,
            commission = if (commission != other.commission) other.commission else null,
            qbCustomerId = if (qbCustomerId != other.qbCustomerId) other.qbCustomerId else null,
            logoFile =
            if (logoFile != other.logoFile) {
                if (logoFile == null &&
                    other.logoFile == FileModel.empty
                ) {
                    null
                } else {
                    other.logoFile
                }
            } else {
                null
            },
        )

    enum class CommissionType {
        Net,
        Gross,
    }

    val isEmpty get() = this.copy(id = "", createdAt = "") == empty

    companion object {
        val empty =
            TravelAgentModel(
                id = "",
                createdAt = "",
                firstName = null,
                lastName = null,
                notes = null,
                telephone = null,
                email = null,
                website = null,
                country = null,
                jobTitle = null,
                source = null,
                city = null,
                address = null,
                zipCode = null,
                agency = null,
                logoUrl = null,
                commissionType = null,
                commission = null,
                logoFile = null,
                qbCustomerId = null,
            )
    }
}
