package com.essntl.features.file.data.repository

import com.essntl.core.supabase.*
import com.essntl.core.utils.repository.Repository
import com.essntl.features.file.data.supabase.FileDto
import com.essntl.features.file.data.supabase.FileSupabaseDataSource
import com.essntl.features.file.data.supabase.toRequestMap
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.file.domain.repository.FileRepository
import com.mohamedrejeb.calf.core.PlatformContext
import org.koin.core.annotation.Single

@Single
internal class FileRepositoryImpl internal constructor(
    private val supabase: FileSupabaseDataSource,
) : Repository(), FileRepository {
    override suspend fun getById(id: String): Result<FileModel> =
        runCatching {
            supabase
                .getById(id)
                .toModel()
        }

    override suspend fun getByIds(ids: List<String>): Result<List<FileModel>> =
        runCatching {
            supabase
                .getByIds(ids)
                .map {
                    it.toModel()
                }
        }

    override suspend fun getByEntityType(entityType: String): Result<List<FileModel>> =
        runCatching {
            supabase
                .getAll {
                    filter {
                        eq("entity_type", entityType)
                    }
                }.map {
                    it.toModel()
                }
        }

    override suspend fun insertAll(
        images: List<FileModel>,
        folderName: String,
        bucketName: String,
        context: PlatformContext,
    ): Result<List<FileModel>> =
        runCatching {
            val imagesModel: MutableList<FileModel> = mutableListOf()

            val imagesToUpload =
                images.filter {
                    if (it.url.startsWith("https://")) {
                        imagesModel.add(it)
                    }
                    !it.url.startsWith("https://")
                }

            println("imagesToUpload $imagesToUpload")

            val uploadedImages =
                supabase.uploadImage(
                    bucketName = bucketName,
                    folderName = folderName,
                    context = context,
                    images = imagesToUpload,
                )

            println("uploadedImages $uploadedImages")

            uploadedImages.mapIndexed { index, url ->
                imagesModel.add(
                    FileModel.empty.copy(
                        url = url.substring(url.indexOf(bucketName)),
                        name = imagesToUpload[index].name,
                        entityType = imagesToUpload[index].entityType,
                    ),
                )
            }

            val imageMap = imagesModel.map { it.toRequestMap() }
            supabase
                .insertAll(imageMap)
                .map { it.toModel() }
        }

    override suspend fun insert(
        image: FileModel,
        folderName: String,
        context: PlatformContext,
    ): Result<FileModel> =
        runCatching {
            val imageModel: FileModel =
                if (image.url.startsWith("https://")) {
                    image
                } else {
                    val url = supabase.uploadImage(
                        bucketName = "images",
                        folderName = folderName,
                        context = context,
                        images = listOf(image),
                    ).first()

                    FileModel.empty.copy(
                        url = url.substring(url.indexOf("images")),
                    )
                }
            val imageMap = imageModel.toRequestMap()
            supabase
                .insert(imageMap)
                .toModel()
        }

    override suspend fun update(image: FileModel): Result<FileModel> =
        runCatching {
            val imageMap = image.toRequestMap()
            supabase
                .update(id = image.id, imageMap)
                .toModel()
        }

    override suspend fun delete(image: FileModel): Result<Unit> =
        runCatching {
            if (!image.url.startsWith("https://images.unsplash")) {
                supabase.deleteImage(listOf(image.url))
            }
            if (image.id.isNotEmpty()) {
                supabase.delete(id = image.id)
            }
        }

    override suspend fun deleteAll(images: List<FileModel>): Result<Unit> =
        runCatching {
            val imagesToDelete =
                images.filter {
                    !it.url.startsWith("https://images.unsplash")
                }.map {
                    it.url
                }

            supabase.deleteImage(imagesToDelete)
            supabase.deleteAll(images.map { it.id })
        }

    override suspend fun downloadFiles(
        bucketName: String,
        file: FileModel,
    ): Result<ByteArray> =
        runCatching {
            supabase.downloadFile(
                file.url.substringAfter("${bucketName}/"),
                bucketName,
            )
        }
}
