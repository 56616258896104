package client.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.dom.svg.Path
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.toAttrs

@Composable
fun SvgEssntl(
    modifier: Modifier = Modifier,
    color: String = "white",
) {
    Svg(
        attrs = modifier
            .toAttrs {
                attr("viewBox", "0 0 62 62")
                attr("fill", "none")
                attr("role", "img")
            },
    ) {
        Path(
            attrs = {
                attr(
                    "d",
                    "M29.248 55.7637L29.248 46.9506C29.248 39.2523 23.0074 33.0117 15.3091 33.0117L6.49603 33.0117C7.397 45.1619 17.0979 54.8628 29.248 55.7637Z",
                )
                attr(
                    "fill",
                    color
                )
            },
        )
        Path(
            attrs = {
                attr(
                    "d",
                    "M6.49603 29.3126L15.3091 29.3126C23.0074 29.3126 29.248 23.0719 29.248 15.3736L29.248 6.56055C17.0979 7.46144 7.397 17.1624 6.49603 29.3126Z",
                )
                attr(
                    "fill",
                    color
                )
            },
        )
        Path(
            attrs = {
                attr(
                    "d",
                    "M32.9511 55.7637C45.1013 54.8628 54.8022 45.1619 55.7031 33.0117L46.89 33.0117C39.1918 33.0117 32.9511 39.2523 32.9511 46.9506L32.9511 55.7637Z",
                )
                attr(
                    "fill",
                    color
                )
            },
        )
        Path(
            attrs = {
                attr(
                    "d",
                    "M55.7031 29.3126C54.8022 17.1624 45.1013 7.46144 32.9511 6.56055L32.9511 15.3736C32.9511 23.0719 39.1918 29.3126 46.89 29.3126L55.7031 29.3126Z",
                )
                attr(
                    "fill",
                    color
                )
            },
        )
    }
}