package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_service_data_di_ServiceModule : Module = module {
	single() { com.essntl.features.service.data.repository.ServiceRepositoryImpl(supabase=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.service.domain.repository.ServiceRepository::class))
	single() { com.essntl.features.service.data.supabase.ServiceSupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.service.data.di.ServiceModule.module : org.koin.core.module.Module get() = com_essntl_features_service_data_di_ServiceModule