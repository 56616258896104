package com.essntl.features.itinerary.data.repository

import com.essntl.core.supabase.*
import com.essntl.core.utils.repository.Repository
import com.essntl.features.itinerary.data.supabase.ItinerarySupabaseDataSource
import com.essntl.features.itinerary.data.supabase.itinerary.ItineraryDto
import com.essntl.features.itinerary.data.supabase.itinerary.ItinerarySimpleDto
import com.essntl.features.itinerary.data.supabase.itinerary.toRequestMap
import com.essntl.features.itinerary.data.supabase.ItineraryServiceDataSource
import com.essntl.features.itinerary.data.supabase.itinerary_service.ItineraryServiceDto
import com.essntl.features.itinerary.data.supabase.itinerary_service.ItineraryServiceSimpleDto
import com.essntl.features.itinerary.data.supabase.itinerary_service.toRequestMap
import com.essntl.features.itinerary.domain.model.ItineraryFilterOptions
import com.essntl.features.itinerary.domain.model.ItineraryModel
import com.essntl.features.itinerary.domain.model.ItineraryServiceModel
import com.essntl.features.itinerary.domain.repository.ItineraryRepository
import com.essntl.features.proposal.data.supabase.proposalservice.ProposalServiceDto
import io.github.jan.supabase.postgrest.query.Order
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.koin.core.annotation.Single

@Single
internal class ItineraryRepositoryImpl internal constructor(
    private val supabase: ItinerarySupabaseDataSource,
    private val itineraryServiceSupabase: ItineraryServiceDataSource,
) : Repository(), ItineraryRepository {
    override suspend fun get(id: String): Result<ItineraryModel> =
        runCatching {
            val itinerary =
                supabase.getById(
                    id = id,
                    columns = ItineraryDto.getColumns(),
                )

            itinerary.toModel()
        }

    override suspend fun getItineraryByShareCode(shareCode: String): Result<ItineraryModel> =
        runCatching {
            supabase
                .getOne(
                    columns = ItineraryDto.getColumns(),
                ) {
                    filter {
                        eq("share_code", shareCode)
                    }
                }
                .toModel()
        }

    override suspend fun getAll(completed: Boolean): Result<List<ItineraryModel>> =
        runCatching {
            val itineraryList =
                supabase.getAll(
                    columns = ItineraryDto.getColumns(),
                ) {
                    if (!completed) {
                        filter {
                            gte(
                                "proposal_id_data.to_date",
                                Clock.System.now().toLocalDateTime(TimeZone.UTC).date,
                            )
                        }
                    }
                }

            itineraryList
                .map { itinerary ->
                    itinerary.toModel()
                }
        }

    override suspend fun getAll(
        page: Long,
        limit: Int,
        filterOptions: ItineraryFilterOptions,
    ): Result<List<ItineraryModel>> =
        runCatching {
            val itineraryList = supabase.getAll(
                page = page,
                limit = limit,
                columns = ItineraryDto.getColumns(),
            ) {
                order("created_at", Order.DESCENDING)

                if (filterOptions.selectedProfileIds.isNotEmpty()) {
                    filter {
                        filterOptions.selectedProfileIds.forEach { profileId ->
                            eq(
                                "owner_id",
                                profileId,
                            )
                        }
                    }
                }
            }

            itineraryList
                .map { itinerary ->
                    itinerary.toModel()
                }
        }

    override suspend fun getAllByIds(ids: List<String>): Result<List<ItineraryModel>> =
        runCatching {
            supabase
                .getByIds(
                    ids,
                    columns = ItineraryDto.getColumns(),
                )
                .map { it.toModel() }
        }

    override suspend fun getItinerariesByProposalIds(proposalIds: List<String>): Result<List<ItineraryModel>> =
        runCatching {
            supabase
                .getAll(
                    columns = ItineraryDto.getColumns(),
                ) {
                    filter {
                        or {
                            proposalIds.forEach { proposalId ->
                                eq("proposal_id", proposalId)
                            }
                        }
                    }
                }
                .map {
                    it.toModel()
                }
        }

    override suspend fun insert(model: ItineraryModel): Result<ItineraryModel> =
        runCatching {
            val requestMap = model.toRequestMap()
            val dto: ItinerarySimpleDto =
                supabase
                    .insertCustom(requestMap)
            val newModel = dto.toModel()
            newModel
        }

    override suspend fun update(model: ItineraryModel): Result<ItineraryModel> =
        runCatching {
            val requestMap = model.toRequestMap()
            val dto: ItinerarySimpleDto =
                supabase
                    .updateCustom(id = model.id, requestMap)
            val newModel = dto.toModel()
            newModel
        }

    override suspend fun delete(model: ItineraryModel): Result<Unit> =
        runCatching {
            supabase.delete(id = model.id)
        }

    override suspend fun getItineraryService(id: String): Result<ItineraryServiceModel> =
        runCatching {
            itineraryServiceSupabase
                .getById(id = id, columns = ItineraryServiceDto.getColumns())
                .toModel()
        }

    override suspend fun updateItineraryService(itineraryServiceModel: ItineraryServiceModel): Result<ItineraryServiceModel> =
        runCatching {
            val requestMap = itineraryServiceModel.toRequestMap()
            val dto: ItineraryServiceSimpleDto =
                itineraryServiceSupabase
                    .updateCustom(id = itineraryServiceModel.id, requestMap)
            val newModel = dto.toModel()
            newModel.copy(supplier = itineraryServiceModel.supplier)
        }

    override suspend fun addAllItineraryService(itineraryServiceList: List<ItineraryServiceModel>): Result<List<ItineraryServiceModel>> =
        runCatching {
            itineraryServiceSupabase
                .insertAllCustom<ItineraryServiceDto, ItineraryServiceSimpleDto>(
                    itineraryServiceList.map { it.toRequestMap(ignoreNullFields = false) },
                )
                .map { it.toModel() }
        }

    override suspend fun getAllItineraryService(itineraryId: String): Result<List<ItineraryServiceModel>> =
        runCatching {
            itineraryServiceSupabase
                .getAll(
                    columns = ItineraryServiceDto.getColumns(),
                ) {
                    filter {
                        eq("itinerary_id", itineraryId)
                    }
                    order("created_at", Order.DESCENDING)
                }
                .map { it.toModel() }
        }

    override suspend fun getAllItineraryServiceByStartDate(startDate: String): Result<List<ItineraryServiceModel>> =
        runCatching {
            itineraryServiceSupabase.getAll(columns = ItineraryServiceDto.getColumns()) {
                filter {
                    eq("start_date", startDate)
                }
                order("created_at", Order.DESCENDING)
            }.map { it.toModel() }
        }
}
