package com.essntl.features.itinerary.data.supabase

import com.essntl.core.supabase.SupabaseDataSource
import com.essntl.features.itinerary.data.supabase.itinerary.ItineraryDto
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class ItinerarySupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<ItineraryDto> {
    override val tableName: String = "itinerary"
}
