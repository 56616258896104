package client.ui

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.zIndex
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

@Composable
fun EsDialog(
    isOpen: Boolean,
    onClose: () -> Unit,
    headerContent: @Composable () -> Unit?,
    content: @Composable () -> Unit,
) {
    val breakpoint = rememberBreakpoint()

    if (isOpen) {
        Div(
            {
                style {
                    position(Position.Fixed)
                    top(0.px)
                    left(0.px)
                    width(100.vw)
                    height(100.vh)
                    zIndex(2)
                    backgroundColor(rgba(0, 0, 0, 0.5))
                    display(DisplayStyle.Flex)
                    justifyContent(JustifyContent.Center)
                    alignItems(AlignItems.Center)
                }
                onClick { onClose() }
            },
        ) {
            Div({ onClick { it.stopPropagation() } }) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .minWidth(500.px)
                        .maxWidth(1000.px)
                        .borderRadius(28.px)
                        .backgroundColor(Colors.White),
                ) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .borderRadius(
                                topLeft = 28.px,
                                topRight = 28.px,
                            )
                            .backgroundColor(Colors.Black)
                            .padding(
                                topBottom = 28.px,
                                leftRight = if (breakpoint < Breakpoint.MD)
                                    20.px
                                else
                                    40.px,
                            ),
                    ) {
                        headerContent()
                    }
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .borderRadius(
                                bottomLeft = 28.px,
                                bottomRight = 28.px,
                            )
                            .backgroundColor(Colors.White)
                            .padding(topBottom = 28.px),
                    ) {
                        content()
                    }
                }
            }
        }
    }
}