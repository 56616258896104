package client.utils

import org.jetbrains.compose.web.dom.ElementBuilder
import org.w3c.dom.Element
import org.w3c.dom.asList

fun Element.setInnerHtml(
    htmlString: String?
) {
    val html = htmlString?.replace(
        "text-align: left",
        "text-align: justify"
    ) ?: ""
    innerHTML = html
    children.asList().forEach { element ->
        if (element.textContent.isNullOrBlank()) {
            val linebreak = ElementBuilder
                .createBuilder<Element>("br")
                .create()
            element.append(linebreak)
        }
    }
}