package com.essntl.core.network

import org.koin.dsl.module

val networkModule
    get() =
        module {
            single { createHttpClient() }
            single<NetworkManager> { NetworkManagerImpl() }
            single { ImageClient() }
        }
