package com.essntl.features.category.data.supabase

import com.essntl.features.category.domain.model.CategoryModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CategoryDto(
    val id: String,
    val name: String,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("icon_url")
    val iconUrl: String?,
    @SerialName("service_dynamic_fields")
    val serviceDynamicFields: List<String>?,
    @SerialName("dynamic_fields")
    val dynamicFields: List<DynamicFieldDto>?,
    @SerialName("qb_category_id")
    val qbCategoryId: String?,
) {
    fun toModel(): CategoryModel =
        CategoryModel(
            id = id,
            name = name,
            createdAt = createdAt,
            iconUrl = iconUrl,
            serviceDynamicFields = serviceDynamicFields,
            dynamicFields = dynamicFields?.map {
                it.toModel()
            },
            qbCategoryId = qbCategoryId,
        )
}
