package com.essntl.core.network

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

internal class NetworkManagerImpl : NetworkManager {
    private val _state = MutableStateFlow(if (currentPlatform.isWeb) NetworkState.Available else NetworkState.Unknown)
    override val state = _state.asStateFlow()

    override fun setState(state: Boolean?) {
        _state.value =
            when (state) {
                true -> NetworkState.Available
                false -> NetworkState.Unavailable
                else -> NetworkState.Unknown
            }
    }
}