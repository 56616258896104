package client.pages

import com.essntl.features.itinerary.domain.model.DynamicField
import com.essntl.features.supplier.domain.model.SupplierModel

data class ServiceUiState(
    val id: String,
    val serviceId: String,
    val grouped: Boolean?,
    val additionalInfo: String?,
    val editedDescription: String?,
    val startDate: String,
    val endDate: String?,
    val startTime: String?,
    val endTime: String?,
    val index: Int,
    val status: Status?,
    val createdAt: String,
    val updatedAt: String?,
    val price: Int?,
    val internalNotes: String?,
    val supplier: SupplierModel?,
    val guestCount: Int,
    val address: String?,
    val updatedBy: String?,
    val contactName: String?,
    val contactPhoneNumber: String?,
    val itineraryId: String?,
    val dynamicFields: List<DynamicField>?,
    val specialNotes: String?,
    val paid: Boolean?,
    val embeddedMap: String?,
    val embeddedContent: String?,
    val files: Set<String>?,
    val messages: Set<String>?,
    val linkedId: String?,
) {
    enum class Status {
        Ready,
        POSent,
        Confirmed,
        TOChange,
        Cancelled,
        Completed,
    }
}
