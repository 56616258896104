package client

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color
import core.config.buildFlavor

class SitePalette(
    val nearBackground: Color,
    val background: Background,
    val border: Border,
    val text: Text,
    val icon: Icon,
    val controls: Controls,
) {
    class Background(
        val primary: Color,
        val secondary: Color,
        val secondaryHover: Color,
        val contrast: Color,
        val warning: Color,
        val success: Color,
        val table: Color,
        val tableHover: Color,
        val tableActive: Color,
    )

    class Border(
        val primary: Color,
        val secondary: Color,
        val disabled: Color,
        val focus: Color,
        val warning: Color,
        val success: Color,
        val error: Color,
        val accent: Color,
    )

    class Text(
        val primary: Color,
        val secondary: Color,
        val tertiary: Color,
        val disabled: Color,
        val contrast: Color,
        val warning: Color,
        val success: Color,
        val error: Color,
        val accent: Color,
    )

    class Icon(
        val primary: Color,
        val secondary: Color,
        val disabled: Color,
        val contrast: Color,
        val warning: Color,
        val success: Color,
        val error: Color,
        val accent: Color,
    )

    class Controls(
        val primary: Color,
        val primaryHover: Color,
        val primaryPressed: Color,
        val primaryDisabled: Color,

        val tinted: Color,
        val tintedHover: Color,
        val tintedPressed: Color,
        val tintedDisabled: Color,

        val secondary: Color,
        val secondaryHover: Color,
        val secondaryPressed: Color,
        val secondaryDisabled: Color,

        val ghost: Color,
        val ghostHover: Color,
        val ghostPressed: Color,

        val destructive: Color,
        val destructiveHover: Color,
        val destructivePressed: Color,
    )

    class Brand(
        val primary: Color = Color.rgb(0x3C83EF),
        val secondary: Color = Color.rgb(0x3C83EF),
        val tinted: Color = Color.rgb(0xCEC8F9),
        val accent: Color = Color.argb(0xFFFDF9D0),
    )
}

object Branding {
    private val essntlBrand = SitePalette.Brand(
        primary = Color.rgb( 0x0E073D),
        secondary = Color.rgb(0xF5F5FC),
        tinted = Color.rgb(0xCEC8F9),
    )

    private val beBrand = SitePalette.Brand(
        primary = Colors.Black,
        secondary = Color.rgb(0xF5F5FC),
        tinted = Color.rgb(0xCEC8F9),
        accent = Color.argb(0xFFEFEDFD),
    )

    val backgroundPrimary =
        if (buildFlavor.isDemo) essntlBrand.primary else beBrand.primary

    val backgroundSecondary =
        if (buildFlavor.isDemo) essntlBrand.secondary else beBrand.secondary

    val controlTinted =
        if (buildFlavor.isDemo) essntlBrand.tinted else beBrand.tinted

    val controlAccent =
        if (buildFlavor.isDemo) essntlBrand.accent else beBrand.accent
}

object SitePalettes {
    val light = SitePalette(
        nearBackground = Color.rgb(0xF4F6FA),
        background = SitePalette.Background(
            primary = Primary100,
            secondary = Grayscale100,
            secondaryHover = Grayscale200,
            contrast = Primary800,
            warning = Orange500,
            success = Green500,
            table = Color.argb(0xFFF7F7F8),
            tableHover = Color.argb(0x1F2845DF),
            tableActive = Color.argb(0x332845DF),
        ),
        border = SitePalette.Border(
            primary = Grayscale300,
            secondary = Grayscale400,
            disabled = Grayscale200,
            focus = Color.argb(0xFFe5e9fb),
            warning = Orange500,
            success = Green500,
            error = Red500,
            accent = Primary500,
        ),
        text = SitePalette.Text(
            primary = Grayscale800,
            secondary = Grayscale600,
            tertiary = Grayscale500,
            disabled = Grayscale400,
            contrast = Grayscale100,
            warning = Orange500,
            success = Green500,
            error = Red500,
            accent = Primary500,
        ),
        icon = SitePalette.Icon(
            primary = Primary800,
            secondary = Grayscale500,
            disabled = Grayscale400,
            contrast = Grayscale100,
            warning = Orange500,
            success = Green500,
            error = Red500,
            accent = Primary500,
        ),
        controls = SitePalette.Controls(
            primary = Primary800,
            primaryHover = Primary700,
            primaryPressed = Primary900,
            primaryDisabled = Color.rgb(0xE6E6EB),

            tinted = Primary200,
            tintedHover = Primary100,
            tintedPressed = Primary300,
            tintedDisabled = Primary100,

            secondary = Grayscale200,
            secondaryHover = Grayscale300,
            secondaryPressed = Grayscale400,
            secondaryDisabled = Grayscale200,

            ghost = Grayscale100,
            ghostHover = Grayscale400,
            ghostPressed = Grayscale300,

            destructive = Red100,
            destructiveHover = Red200,
            destructivePressed = Red300,
        ),
    )
    // TODO: Implement dark theme
    val dark = light
}

fun ColorMode.toSitePalette(): SitePalette {
    return when (this) {
        ColorMode.LIGHT -> SitePalettes.light
        ColorMode.DARK -> SitePalettes.dark
    }
}

@InitSilk
fun initTheme(ctx: InitSilkContext) {
    ctx.theme.palettes.light.background = Colors.White
    ctx.theme.palettes.light.color = Colors.Black
    ctx.theme.palettes.dark.background = Color.rgb(0x06080B)
    ctx.theme.palettes.dark.color = Colors.White
}
