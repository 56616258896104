package com.essntl.toastmanager

import com.benasher44.uuid.uuid4

sealed interface Toast {
    val id: String

    data class Default(
        override val id: String = uuid4().toString(),
        val title: String,
        val description: String,
        val state: State,
    ) : Toast {
        enum class State {
            Information,
            Success,
            Warning,
            Error,
        }
    }

    data class Message(
        override val id: String = uuid4().toString(),
        val conversationId: String,
        val senderName: String,
        val senderType: String?,
        val message: String,
        val type: String?,
        val unreadMessages: Int,
        val dateTime: String,
    ) : Toast
}