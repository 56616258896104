package client.pages.components

import androidx.compose.runtime.*
import client.pages.ServiceUiState
import client.pages.components.hotel.HotelSection
import client.pages.components.service.DefaultService
import client.utils.setInnerHtml
import com.essntl.core.utils.datetime.formatTimestampToHHMM
import com.essntl.core.utils.viewmodel.State
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.service.domain.model.ServiceModel
import com.essntl.features.tag.domain.model.TagModel
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.datetime.LocalTime
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

@Composable
fun ItineraryDayInfosExpanded(
    isItinerary: Boolean,
    allDayServices: List<ServiceUiState>,
    getAllService: State<List<ServiceModel>>,
    getAllCategory: State<List<CategoryModel>>,
    getAllTag: State<List<TagModel>>,
    getAllImage: State<List<FileModel>>,
    getAllFiles: State<List<FileModel>>,
    onDownloadFile: (FileModel) -> Unit,
    selectedProposalServiceId: String,
    clearSelectedProposalService: () -> Unit,
    onOpenImageFullScreen: (imageList: List<FileModel>, imageIndex: Int) -> Unit,
    modifier: Modifier = Modifier,
) {
    var allGroupedProposalServiceFirstIndex by remember {
        mutableStateOf(allDayServices.size)
    }

    val allGroupedProposalService = remember(allDayServices) {
        allDayServices.filterIndexed { index, proposalService ->
            val isGrouped = proposalService.grouped == true

            if (isGrouped && index < allGroupedProposalServiceFirstIndex)
                allGroupedProposalServiceFirstIndex = index

            isGrouped
        }
    }

    val groupedDayProposalServices = remember(allDayServices) {
        var isFirstGroupedProposalServiceReached = false

        allDayServices
            .sortedBy {
                it.startTime?.let { time ->
                    LocalTime.parse(formatTimestampToHHMM(time))
                }
            }
            .filter { proposalService ->
                val isGrouped = proposalService.grouped == true
                val predicate = !isGrouped || !isFirstGroupedProposalServiceReached

                if (isGrouped)
                    isFirstGroupedProposalServiceReached = true

                predicate
            }
            .groupBy { it.linkedId.orEmpty().ifEmpty { it.id } }
            .toList()
    }

    val breakpoint = rememberBreakpoint()

    LaunchedEffect(selectedProposalServiceId) {
        val elementId =
            if (allGroupedProposalService.any { it.id == selectedProposalServiceId })
                allGroupedProposalService.firstOrNull()?.id ?: return@LaunchedEffect
            else
                selectedProposalServiceId

        val element = document.getElementById(elementId) ?: return@LaunchedEffect

        window.scrollBy(
            ScrollToOptions(
                top = element.getBoundingClientRect().top - if (breakpoint >= Breakpoint.MD) 108.0 else 0.0,
                behavior = ScrollBehavior.SMOOTH,
            ),
        )
    }

    Column(
        modifier = modifier
            .fillMaxWidth(),
    ) {
        groupedDayProposalServices.forEachIndexed { groupIndex, (_, list) ->
            val firstProposalService = remember(list) {
                list.first()
            }

            val index = remember(groupedDayProposalServices, groupIndex) {
                var index = 0
                for (i in 0 until groupIndex) {
                    index += groupedDayProposalServices[i].second.size
                }
                index
            }

            if (firstProposalService.grouped == true) {
                if (firstProposalService.id == allGroupedProposalService.firstOrNull()?.id) {
                    if (allGroupedProposalService.isNotEmpty()) {
                        HotelSection(
                            allProposalService = allGroupedProposalService,
                            getAllService = getAllService,
                            getAllTag = getAllTag,
                            getAllImage = getAllImage,
                            getAllCategory = getAllCategory,
                            onOpenImageFullScreen = onOpenImageFullScreen,
                            modifier = Modifier
                                .id(firstProposalService.id)
                                .margin(bottom = if (breakpoint < Breakpoint.MD) 24.px else 42.px),
                        )
                    }
                }
            } else {
                ItineraryDayInfosServiceGroup(
                    number = index + 1,
                    id = firstProposalService.id,
                ) {
                    list.forEachIndexed { index, proposalService ->
                        key(proposalService.id) {
                            val service = remember(getAllService, proposalService.serviceId) {
                                getAllService.data?.find { it.id == proposalService.serviceId }
                            }

                            val category = remember(getAllCategory, service?.category) {
                                getAllCategory.data?.find { it.id == service?.category }
                            }

                            val allProposalServiceFiles = remember(getAllFiles, proposalService.files) {
                                proposalService.files?.map { fileId ->
                                    getAllFiles.data?.find { it.id == fileId }
                                } ?: emptyList()
                            }

                            val allServiceImage = remember(getAllImage, service?.images) {
                                service?.images?.map { imageId ->
                                    getAllImage.data?.find { it.id == imageId }
                                } ?: emptyList()
                            }

                            if (service != null) {
                                ItineraryDayInfosService(
                                    id = proposalService.id,
                                ) {
                                    DefaultService(
                                        isItinerary = isItinerary,
                                        proposalService = proposalService,
                                        service = service,
                                        category = category,
                                        allImage = allServiceImage,
                                        onOpenImageFullScreen = onOpenImageFullScreen,
                                        files = allProposalServiceFiles,
                                        onDownloadFile = {
                                            onDownloadFile(it)
                                        },
                                    )
                                }

                                if (index != list.lastIndex) {
                                    Box(
                                        modifier = Modifier
                                            .height(1.px)
                                            .fillMaxWidth()
                                            .borderBottom(
                                                width = 1.px,
                                                style = LineStyle.Dashed,
                                                color = com.varabyte.kobweb.compose.ui.graphics.Color.rgb(0xF2EFEA),
                                            )
                                    )
                                }
                            }
                            if (proposalService.embeddedContent.orEmpty().isNotEmpty()) {
                                EmbeddedContent(
                                    proposalService = proposalService,
                                )
                            }

                            MapContent(
                                proposalService = proposalService,
                                isLast = groupIndex == groupedDayProposalServices.lastIndex,
                            )
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun MapContent(proposalService: ServiceUiState, isLast: Boolean) {
    val embeddedMapId = remember(proposalService.id) {
        "proposal-service-embeddedMap-${proposalService.id}"
    }

    SpanText(
        text = "",
        modifier = Modifier
            .fillMaxWidth()
            .id(embeddedMapId),
    )

    LaunchedEffect(embeddedMapId, proposalService.embeddedMap) {
        val widthRegex = """width="\d+"""".toRegex()
        val borderBottom = if (isLast) " border-radius:0 0 28px 28px;" else " border-radius:0;"
        val updatedIframe = proposalService.embeddedMap
            ?.replace(widthRegex, """width="100%"""")
            ?.replace(Regex("border:\\s*\\d+;")) {
                it.value.replaceFirst("\\d+".toRegex(), "0") + borderBottom
            }
        document.getElementById(embeddedMapId)?.setInnerHtml(updatedIframe)
    }
}

@Composable
fun EmbeddedContent(proposalService: ServiceUiState) {
    val breakpoint = rememberBreakpoint()

    val embeddedContentId = remember(proposalService.id) {
        "proposal-service-embeddedContent-${proposalService.id}"
    }

    SpanText(
        text = "",
        modifier = Modifier
            .margin(left = if (breakpoint < Breakpoint.MD) 14.px else 40.px)
            .id(embeddedContentId),
    )

    LaunchedEffect(embeddedContentId, proposalService.embeddedContent) {
        document.getElementById(embeddedContentId)?.setInnerHtml(proposalService.embeddedContent)
    }
}