package com.essntl.features.proposal.data.supabase.proposal

import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.proposal.domain.model.ProposalModel
import com.essntl.features.travelagent.data.supabase.TravelAgentDto
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProposalDto(
    val id: String,
    val title: String?,
    val headline: String?,
    val areas: List<String>?,
    @SerialName("from_date")
    val fromDate: String?,
    @SerialName("to_date")
    val toDate: String?,
    @SerialName("request_id")
    val requestId: String?,
    @SerialName("itinerary_id")
    val itineraryId: String? = null,
    @SerialName("proposal_id")
    val proposalId: String? = null,
    @SerialName("cover_image")
    val coverImage: String? = null,
    val status: String?,
    val version: Int,
    @SerialName("created_at")
    val createdAt: String,
    val clients: List<String>? = null,
    @SerialName("guest_count")
    val guestCount: Int? = null,
    @SerialName("primary_client")
    val primaryClient: String? = null,
    @SerialName("owner_id")
    val ownerId: String? = null,
    @SerialName("share_code")
    val shareCode: String? = null,
    @SerialName("updated_at")
    val updatedAt: String? = null,
    @SerialName("guest_details")
    val guestDetails: String?,
    @SerialName("status_updated_at")
    val statusUpdatedAt: String?,
    @SerialName("travel_agent_data")
    val travelAgent: TravelAgentDto? = null,
    @SerialName("request_received_date")
    val requestReceivedDate: String?,
    @SerialName("agent_comm")
    val commission: Double?,
) {
    fun toModel() =
        ProposalModel(
            id = id,
            title = title,
            headline = headline,
            areas = areas,
            fromDate = fromDate,
            toDate = toDate,
            requestId = requestId,
            itineraryId = itineraryId,
            proposalId = proposalId,
            travelAgent = travelAgent?.toModel(),
            status =
            when (status) {
                "TO_DO" -> ProposalModel.Status.Todo
                "IN_PROGRESS" -> ProposalModel.Status.InProgress
                "SENT" -> ProposalModel.Status.Sent
                "CONFIRMED" -> ProposalModel.Status.Confirmed
                "ITINERARY_READY" -> ProposalModel.Status.ItineraryReady
                "ON_HOLD" -> ProposalModel.Status.OnHold
                "FOLLOWED_UP" -> ProposalModel.Status.FollowedUp
                "NOT_BOOKED" -> ProposalModel.Status.NotBooked
                "ACCEPTED" -> ProposalModel.Status.Accepted
                else -> null
            },
            version = version,
            coverImage = coverImage?.let { FileModel.empty.copy(id = it) },
            createdAt = createdAt,
            clients = clients,
            guestCount = guestCount ?: 0,
            primaryClient = primaryClient,
            ownerId = ownerId,
            shareCode = shareCode,
            updatedAt = updatedAt,
            guestDetails = guestDetails,
            statusUpdatedAt = statusUpdatedAt,
            requestReceivedDate = requestReceivedDate,
            commission = commission,
        )

    companion object {
        const val COLUMNS_VALUE = "*, travel_agent_data:travel_agent(*)"
        fun getColumns() =
            Columns.raw(COLUMNS_VALUE)
    }
}

internal fun ProposalModel.Status.toDto() =
    when (this) {
        ProposalModel.Status.Todo -> "TO_DO"
        ProposalModel.Status.InProgress -> "IN_PROGRESS"
        ProposalModel.Status.Sent -> "SENT"
        ProposalModel.Status.Confirmed -> "CONFIRMED"
        ProposalModel.Status.ItineraryReady -> "ITINERARY_READY"
        ProposalModel.Status.OnHold -> "ON_HOLD"
        ProposalModel.Status.FollowedUp -> "FOLLOWED_UP"
        ProposalModel.Status.NotBooked -> "NOT_BOOKED"
        ProposalModel.Status.Accepted -> "ACCEPTED"
    }
