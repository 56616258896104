package com.essntl.core.utils.utils

fun formatIntMoney(money: Int): String {
    val dollar = (money / 100).formatWithCommas()
    val cent = money % 100
    return if (cent == 0) {
        dollar
    } else {
        "$dollar.${cent.toString().padStart(2, '0')}"
    }
}

fun formatMoney(text: String): String {
    val number = text.replace(Regex("[^0-9.]"), "")
    val parts = number.split('.')
    val integerPart = parts[0].toIntOrNull() ?: 0
    return integerPart.formatWithCommas() + (if (parts.size > 1) ".${parts[1]}" else "")
}

private fun Int.formatWithCommas(): String {
    val numberStr = this.toString()
    val parts = numberStr.split('.')
    val integerPart = parts[0].toIntOrNull() ?: 0
    val formattedInteger =
        buildString {
            append(integerPart.withCommas())
        }
    return if (parts.size > 1) "$formattedInteger.${parts[1]}" else formattedInteger
}

private fun Int.withCommas(): String {
    val str = this.toString()
    val len = str.length
    if (len <= 3) return str

    val result = StringBuilder()
    var count = 0

    for (i in len - 1 downTo 0) {
        result.append(str[i])
        count++
        if (count == 3 && i > 0) {
            result.append(',')
            count = 0
        }
    }

    return result.reverse().toString()
}

fun Int.pluralize(singular: String, plural: String): String =
    "$this ${if (this == 1) singular else plural}"

fun Int.pluralize(name: String): String =
    "$this ${if (this == 1) name else "${name}s"}"

fun Double.roundTo(decimals: Int): Double {
    var multiplier = 1.0
    repeat(decimals) { multiplier *= 10 }
    return kotlin.math.round(this * multiplier) / multiplier
}
