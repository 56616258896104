package com.essntl.core.supabase

import com.essntl.config.EssntlConfig
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.createSupabaseClient
import io.github.jan.supabase.functions.Functions
import io.github.jan.supabase.gotrue.Auth
import io.github.jan.supabase.postgrest.Postgrest
import io.github.jan.supabase.realtime.Realtime
import io.github.jan.supabase.serializer.KotlinXSerializer
import io.github.jan.supabase.storage.Storage
import kotlinx.serialization.json.Json
import kotlin.time.Duration.Companion.minutes

internal fun createEssntlSupabaseClient(): SupabaseClient =
    createSupabaseClient(
        supabaseUrl = EssntlConfig.supabaseUrl,
        supabaseKey = EssntlConfig.supabaseKey,
    ) {
        httpEngine = httpClientEngine()

        install(Postgrest)
        install(Storage)
        install(Auth)
        install(Realtime)
        install(Functions)

        requestTimeout = 2.minutes

        defaultSerializer =
            KotlinXSerializer(
                Json {
                    ignoreUnknownKeys = true
                },
            )
    }
