package client.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import client.pages.components.ProposalScreen
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext

@Page(routeOverride = "trip/{shareCode}")
@Composable
fun ProposalPage() {
    val ctx = rememberPageContext()
    val viewModel = rememberProposalViewModel()
    val state by viewModel.state.collectAsState()

    LaunchedEffect(Unit) {
        val shareCode = ctx.route.params.getValue("shareCode")

        viewModel.onEvent(
            ProposalItineraryEvent.GetProposal(shareCode = shareCode),
        )
    }

    ProposalScreen(
        state = state
    )
}