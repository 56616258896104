package client.pages.components

import androidx.compose.runtime.Composable
import client.toSitePalette
import client.ui.S2SemiBold16
import client.ui.spacing
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flex
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.VerticalDivider
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.px

@Composable
fun ItineraryDayInfosServiceGroup(
    number: Int,
    id: String,
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit,
) {
    val sitePalette = ColorMode.current.toSitePalette()
    val breakpoint = rememberBreakpoint()

    val newModifier = modifier
        .id(id)
        .padding(
            topBottom = if (breakpoint < Breakpoint.MD) 14.px else 16.px,
            leftRight = if (breakpoint < Breakpoint.MD) 14.px else 20.px,
        )
//        .margin(bottom = if (breakpoint < Breakpoint.MD) 24.px else 42.px)
        .fillMaxWidth()

    if (breakpoint < Breakpoint.MD) {
        Column(
            modifier = newModifier,
        ) {
//            Row(
//                verticalAlignment = Alignment.CenterVertically,
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .margin(bottom = 1.5f.spacing),
//            ) {
//                Box(
//                    contentAlignment = Alignment.Center,
//                    modifier = Modifier
//                        .size(28.px)
//                        .borderRadius(8.px)
//                        .backgroundColor(Colors.White),
//                ) {
//                    SpanText(
//                        text = "$number",
//                        modifier = S2SemiBold16.toModifier()
//                            .color(sitePalette.text.primary),
//                    )
//                }
//
//                HorizontalDivider(
//                    modifier = Modifier
//                        .flex(1)
//                        .height(1.px)
//                        .border {
//                            color(Colors.White)
//                        }
//                        .margin(0.px),
//                )
//            }

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(Colors.White)
                    .borderRadius(16.px)
            ) {
                content()
            }
        }
    } else {
        Row(
            modifier = newModifier
                .alignItems(AlignItems.Stretch),
        ) {
//            Column(
//                horizontalAlignment = Alignment.CenterHorizontally,
//                modifier = Modifier
//                    .margin(right = 1.5f.spacing),
//            ) {
//                Box(
//                    contentAlignment = Alignment.Center,
//                    modifier = Modifier
//                        .size(28.px)
//                        .borderRadius(8.px)
//                        .backgroundColor(Colors.White),
//                ) {
//                    SpanText(
//                        text = "$number",
//                        modifier = S2SemiBold16.toModifier()
//                            .color(sitePalette.text.primary),
//                    )
//                }
//
//                VerticalDivider(
//                    modifier = Modifier
//                        .flex(1)
//                        .width(1.px)
//                        .border {
//                            color(Colors.White)
//                        }
//                        .margin(0.px),
//                )
//            }

            Column(
//            Row(
                modifier = Modifier
                    .fillMaxWidth()
//                    .alignItems(AlignItems.Stretch)
                    .backgroundColor(Colors.White)
                    .borderRadius(16.px)
            ) {
                content()
            }
        }
    }
}