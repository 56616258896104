package com.essntl.core.network

enum class Platform {
    Android,
    Ios,
    Web,
    Desktop;

    val isAndroid: Boolean
        get() = this == Android

    val isIos: Boolean
        get() = this == Ios

    val isWeb: Boolean
        get() = this == Web

    val isDesktop: Boolean
        get() = this == Desktop
}

expect val currentPlatform: Platform