package com.essntl.features.notification.data.supabase

import NotificationDto
import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
class NotificationSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
): SupabaseDataSource<NotificationDto> {
    override val tableName: String = "notification"
}