package client.components.widgets

import androidx.compose.runtime.Composable
import client.pages.components.ProposalCoverValues
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backdropFilter
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun EsCard(
    title: String,
    description: String,
    modifier: Modifier = Modifier
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .borderRadius(16.px)
            .backgroundColor(Color.rgb(0xF2EFEA))
            .backdropFilter(blur(6.px))
            .padding(
                topBottom = ProposalCoverValues.Padding.esCardTopBottom,
                leftRight = ProposalCoverValues.Padding.esCardLeftRight,
            )
    ) {
        Column(
            horizontalAlignment = Alignment.Start,
            modifier = Modifier
        ) {
            SpanText(
                text = title,
                modifier = Modifier
                    .fillMaxWidth()
                    .fontSize(14.px)
                    .fontWeight(600)
                    .lineHeight(20.px)
                    .color(Color.rgb(0x0E073D))
                    .margin(
                        bottom = 2.px
                    )
            )

            SpanText(
                text = description,
                modifier = Modifier
                    .fontSize(14.px)
                    .fontWeight(400)
                    .lineHeight(20.px)
                    .color(Colors.Black)
            )
        }
    }
}