package com.essntl.features.proposal.data.supabase.proposalservice

import com.essntl.features.proposal.data.repository.ProposalRepositoryImpl
import com.essntl.features.proposal.domain.model.ProposalServiceModel
import com.essntl.features.supplier.data.supabase.SupplierDto
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * If you edited these fields make sure [ProposalRepositoryImpl.addAllProposalService]
 */
@Serializable
data class ProposalServiceDto(
    val id: String,
    @SerialName("proposal_id")
    val proposalId: String?,
    @SerialName("service_id")
    val serviceId: String,
    val grouped: Boolean,
    @SerialName("additional_info")
    val additionalInfo: String?,
    @SerialName("edited_description")
    val editedDescription: String?,
    @SerialName("start_date")
    val startDate: String,
    @SerialName("end_date")
    val endDate: String?,
    @SerialName("start_time")
    val startTime: String?,
    @SerialName("end_time")
    val endTime: String?,
    val index: Int,
    val status: String?,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("updated_at")
    val updatedAt: String?,
    val price: Int?,
    @SerialName("internal_notes")
    val internalNotes: String?,
    @SerialName("supplier_id_data")
    val supplier: SupplierDto?,
    @SerialName("guest_count")
    val guestCount: Int,
    val address: String?,
    @SerialName("updated_by")
    val updatedBy: String?,
    @SerialName("contact_name")
    val contactName: String?,
    @SerialName("contact_phone_number")
    val contactPhoneNumber: String?,
    val clients: List<String>?,
    @SerialName("itinerary_id")
    val itineraryId: String?,
    @SerialName("dynamic_fields")
    val dynamicFields: Map<String, String>?,
    @SerialName("special_notes")
    val specialNotes: String?,
    val paid: Boolean?,
    @SerialName("embedded_map")
    val embeddedMap: String?,
    @SerialName("embedded_content")
    val embeddedContent: String?,
    val files: List<String>?,
    val commissionable: Boolean?,
) {
    fun toModel() =
        ProposalServiceModel(
            id = id,
            proposalId = proposalId,
            serviceId = serviceId,
            grouped = grouped,
            additionalInfo = additionalInfo,
            editedDescription = editedDescription,
            startDate = startDate,
            endDate = endDate,
            startTime = startTime,
            endTime = endTime,
            index = index,
            createdAt = createdAt,
            updatedAt = updatedAt,
            price = price,
            supplier = supplier?.toModel(),
            internalNotes = internalNotes,
            guestCount = guestCount,
            address = address,
            contactName = contactName,
            contactPhoneNumber = contactPhoneNumber,
            updatedBy = updatedBy,
            clients = clients,
            itineraryId = itineraryId,
            dynamicFields = dynamicFields,
            specialNotes = specialNotes,
            paid = paid,
            embeddedMap = embeddedMap,
            embeddedContent = embeddedContent,
            files = files?.toSet(),
            commissionable = commissionable,
            status =
            when (status) {
                "READY" -> ProposalServiceModel.Status.Ready
                "PO_SENT" -> ProposalServiceModel.Status.POSent
                "CONFIRMED" -> ProposalServiceModel.Status.Confirmed
                "TO_CHANGE" -> ProposalServiceModel.Status.TOChange
                "CANCELLED" -> ProposalServiceModel.Status.Cancelled
                "COMPLETED" -> ProposalServiceModel.Status.Completed
                else -> null
            },
        )

    companion object {
        fun getColumns() = Columns.raw(COLUMNS_VALUE)

        private const val COLUMNS_VALUE = "*, supplier_id_data:supplier_id(*)"
    }
}

internal fun ProposalServiceModel.Status.toDto() =
    when (this) {
        ProposalServiceModel.Status.Ready -> "READY"
        ProposalServiceModel.Status.POSent -> "PO_SENT"
        ProposalServiceModel.Status.Confirmed -> "CONFIRMED"
        ProposalServiceModel.Status.TOChange -> "TO_CHANGE"
        ProposalServiceModel.Status.Cancelled -> "CANCELLED"
        ProposalServiceModel.Status.Completed -> "COMPLETED"
    }
