package client.ui

import kotlinx.browser.window

object EsIcons {
    const val Logo = "logo"
    const val ArrowLeft = "arrow_left"
    const val ArrowUp = "arrow_up"
    const val ArrowRight = "arrow_right"
    const val ArrowDown = "arrow_down"
    const val MoreVertical = "more_vertical"
    const val InfoCircle = "info_circle"
    const val CheckCircle = "check_circle"
    const val Check = "check"
    const val Edit = "edit"
    const val Search = "search"
    const val Add = "add"
    const val Minus = "minus"
    const val Trash = "trash"
    const val Timer = "timer"
    const val BaseInformation = "base_information"
    const val Calendar = "calendar"
    const val Filter = "filter"
    const val Sort = "sort"
    const val Service = "service"
    const val Itinerary = "itinerary"
    const val Request = "request"
    const val NoteFavorite = "note_favorite"
    const val Proposal = "proposal"
    const val SpinnerLarge = "spinner_large"
    const val SpinnerMedium = "spinner_medium"
    const val SpinnerSmall = "spinner_small"
    const val Close = "close"
    const val CloseCircle = "close_circle"
    const val Tick = "tick"
    const val Link = "link"
    const val ListBulleted = "list_bulleted"
    const val ListNumbered = "list_numbered"
    const val TextBold = "text_bold"
    const val TextItalic = "text_italic"
    const val TextUnderline = "text_underline"
    const val TextStrikethrough = "text_strikethrough"
    const val ColorItem = "color_item"
    const val TextAlignLeft = "text_align_left"
    const val Copy = "copy"
    const val Eye = "eye"
    const val Share = "share"
    const val EmptyList = "empty_list"
    const val EmptySearch = "empty_search"
    const val ContactIcon = "contact_icon"
    const val Map = "map"
    const val Date = "date"
    const val Location = "location"
    const val Persons = "persons"
    const val Board = "board_icon"
    const val RequestList = "list_icon_request"
    const val ArrowBack = "arrow_back"
    const val BeLogoKey = "be_logo_key"
    const val BeLogoFull = "be_logo_full"
    const val BeLogoKeyNude = "be_logo_key_nude"
    const val BeLogoFullNude = "be_logo_full_nude"
    const val WhatsAppChat = "WhatsAppButtonWhiteLarge"
    const val Email = "email"
    const val OfficePhone = "office_phone"
    const val SupportContact = "support_contact"
    const val Maximize = "maximize"
    const val Download = "download"
}

fun iconSrc(icon: String): String =
    "${window.location.origin.removeSuffix("/")}/icons/$icon.svg"

fun imageSrc(image: String): String =
    "${window.location.origin.removeSuffix("/")}/images/$image.svg"