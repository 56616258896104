package client.pages.components.service

import ItineraryTimeAddressCard
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import client.Branding
import client.components.widgets.EsSmallIcon
import client.components.widgets.EsSmallOutlineIconButton
import client.pages.ServiceUiState
import client.pages.components.ItineraryBtnVariant
import client.pages.components.UnselectedItineraryBtnVariant
import client.toSitePalette
import client.ui.*
import client.utils.setInnerHtml
import com.essntl.core.utils.datetime.formatDateToMMDDYYYY
import com.essntl.core.utils.utils.formatIntMoney
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.proposal.domain.model.ProposalServiceModel
import com.essntl.features.service.domain.model.ServiceModel
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.brightness
import com.varabyte.kobweb.compose.css.functions.contrast
import com.varabyte.kobweb.compose.css.functions.hueRotate
import com.varabyte.kobweb.compose.css.functions.invert
import com.varabyte.kobweb.compose.css.functions.saturate
import com.varabyte.kobweb.compose.css.functions.sepia
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.addVariantBase
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun ColumnScope.DefaultServiceDetails(
    isItinerary: Boolean,
    proposalService: ServiceUiState,
    service: ServiceModel,
    category: CategoryModel?,
    files: List<FileModel?>,
    onDownloadFile: (FileModel) -> Unit,
) {
    val sitePalette = ColorMode.current.toSitePalette()
    val breakpoint = rememberBreakpoint()

    Row(
        verticalAlignment = Alignment.Top,
        modifier = Modifier
            .fillMaxWidth()
    ) {
        Column {
            category?.let {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .margin(bottom = 12.px)
                        .padding {
                            top(8.px)
                            bottom(8.px)
                            left(18.px)
                            right(18.px)
                        }
                        .borderRadius(100.px)
                        .backgroundColor(Branding.controlAccent),
                ) {
                    category.iconUrl?.let { url ->
                        Box(
                            modifier = Modifier
                                .size(18.px)
                                .margin(right = 2.px)
                                .backgroundImage(url(url))
                                .backgroundSize(BackgroundSize.Cover)
                                .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
                                .filter(
                                    invert(100.percent),
                                    sepia(100.percent),
                                    saturate(100.percent),
                                    hueRotate(90.deg),
                                    brightness(100.percent),
                                    contrast(100.percent),
                                ),
                        )
                    }

                    SpanText(
                        text = it.name,
                        modifier = Modifier
                            .fontSize(14.px)
                            .color(sitePalette.text.primary),
                    )
                }
            }

            // Title
            SpanText(
                text = service.title,
                modifier = Modifier
                    .fontSize(24.px)
                    .lineHeight(28.px)
                    .fontWeight(700)
                    .color(sitePalette.text.primary)
                    .margin(right = 14.px)
                    .letterSpacing((-0.48f).px)
                    .textTransform(TextTransform.Capitalize),
            )

            // Headline
            service.headline?.let { headline ->
                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                ) {
                    SpanText(
                        text = headline.replaceFirstChar { it.uppercaseChar() },
                        modifier = P1Medium16.toModifier()
                            .fontSize(14.px)
                            .lineHeight(20.px)
                            .fontWeight(400)
                            .color(sitePalette.text.secondary)
                            .flex(3),
                    )
                }
            }
        }

        Spacer()

        if (!isItinerary) {
            proposalService.price?.let {
                if (it > 0) {
                    val isPaid = proposalService.paid != null && proposalService.paid == true
                    val priceTextValue = if (isPaid) "Booked" else "$${formatIntMoney(it)}"

                    ItineraryDayInfosPriceCard(
                        price = priceTextValue,
                        modifier = Modifier
                            .width(100.px)
                            .displayIfAtLeast(Breakpoint.MD),
                    )
                } else {
                    // TODO: decode which message or info to show to client
                }
            }
        }
    }

    // Price layout on mobile. TODO: make smaller
    if (!isItinerary) {
        proposalService.price?.let {
            if (it > 0) {

                val isPaid = proposalService.paid != null && proposalService.paid == true
                val priceTextValue = if (isPaid) "Booked" else "$${formatIntMoney(it)}"

                ItineraryDayInfosPriceCard(
                    price = priceTextValue,
                    modifier = Modifier
                        .maxWidth(100.px)
                        .margin(
                            top = .5f.spacing,
                            bottom = 1.5f.spacing,
                        )
                        .fillMaxWidth()
                        .displayUntil(Breakpoint.MD),
                )
            } else {
                // TODO: decode which message or info to show to client
            }
        }
    }

    // Proposal service files
    DownloadDocumentsButton(isItinerary, files, service, breakpoint, onDownloadFile)

    if (proposalService.linkedId == null)
        // Additional Info
        proposalService.additionalInfo?.let { info ->
            SpanText(
                text = info.replaceFirstChar { it.uppercaseChar() },
                modifier = P2Regular16.toModifier()
                    .color(sitePalette.text.primary)
                    .fontStyle(FontStyle.Italic)
                    .margin(bottom = 8.px),
            )
        }

    if (isItinerary && proposalService.linkedId == null) {
        ItineraryTimeAddressCard(
            proposalService = proposalService,
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 10.px),
        )
    }

    var isPassedMaxHeight by remember {
        mutableStateOf(false)
    }

    var isExpanded by remember {
        mutableStateOf(true)
    }

    if (proposalService.linkedId == null) {
        val proposalServiceDescriptionId = remember(proposalService.id) {
            "proposal-service-description-${proposalService.id}"
        }

        SpanText(
            text = "",
            modifier = P2Regular16.toModifier()
                .fillMaxWidth()
                .color(Colors.Black)
                .id(proposalServiceDescriptionId)
                .maxHeight(
                    if (isExpanded)
                        1000.px
                    else
                        200.px,
                )
                .overflow(Overflow.Hidden)
                .textAlign(TextAlign.Justify),
        )

        if (isPassedMaxHeight)
            SpanText(
                text = if (isExpanded) "Show less" else "Show more",
                modifier = P2Regular16.toModifier()
                    .fontWeight(600)
                    .textDecorationLine(TextDecorationLine.Underline)
                    .fillMaxWidth()
                    .color(Colors.Black)
                    .margin(top = 20.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        isExpanded = !isExpanded
                    },
            )

        LaunchedEffect(proposalServiceDescriptionId, proposalService.editedDescription) {
            document.getElementById(proposalServiceDescriptionId)?.let { element ->
                element.setInnerHtml(proposalService.editedDescription)

                delay(1000)
                isPassedMaxHeight = element.clientHeight > 200
            }
        }
    }
}

@Composable
fun DownloadDocumentsButton(
    isItinerary: Boolean,
    files: List<FileModel?>,
    service: ServiceModel,
    breakpoint: Breakpoint,
    onDownloadFile: (FileModel) -> Unit,
) {
    var isDialogOpen by remember { mutableStateOf(false) }

    if (isItinerary && files.isNotEmpty()) {
        Button(
            onClick = {
                isDialogOpen = true
            },
            variant = ItineraryBtnVariant
                .then(
                    DownloadButtonVariant,
                ),
            modifier = Modifier
                .width(252.px)
                .height(50.px)
                .margin(top = 1f.spacing),
        ) {
            SpanText(
                text = "Download your documents",
                modifier = Modifier.color(Color.rgb(0x5935E9))
                    .textAlign(TextAlign.Left),
            )
        }
    }

    EsDialog(
        isOpen = isDialogOpen,
        onClose = { isDialogOpen = false },
        headerContent = {
            SpanText(
                "Documents for ${service.title}",
                modifier = Modifier
                    .fontSize(
                        if (breakpoint < Breakpoint.MD)
                            14.px
                        else
                            24.px,
                    )
                    .lineHeight(
                        if (breakpoint < Breakpoint.MD)
                            18.px
                        else
                            28.px,
                    )
                    .color(Colors.White)
                    .maxWidth(100.percent)
                    .whiteSpace(WhiteSpace.NoWrap)
                    .overflow(Overflow.Hidden)
                    .textOverflow(TextOverflow.Ellipsis)
                    .textAlign(TextAlign.Left),
            )
        },
    ) {
        Div(
            attrs = {
                style {
                    display(DisplayStyle.Flex)
                    flexDirection(FlexDirection.Column)
                    overflowX("auto")
                    width(100.percent)
                    paddingLeft(18.px)
                    paddingRight(18.px)
                }
            },
        ) {
            files.forEachIndexed { index, file ->
                if (file == null)
                    return@forEachIndexed

                Div(
                    attrs = {
                        style {
                            border(1.px, LineStyle.Solid, Color.rgb(0xE7E6EB))
                            borderRadius(16.px)
                            width(100.percent)
                            minWidth(100.px)
                            padding(12.px)
                            marginBottom(
                                if (index == files.lastIndex)
                                    0.px
                                else
                                    20.px,
                            )
                        }
                    },
                ) {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween,
                    ) {
                        Column {
                            SpanText(
                                file.name ?: "",
                                modifier = Modifier
                                    .fontSize(16.px)
                                    .lineHeight(24.px)
                                    .fontWeight(500)
                                    .maxWidth(700.px)
                                    .color(Color.rgb(0x121021))
                                    .whiteSpace(WhiteSpace.NoWrap)
                                    .overflow(Overflow.Hidden)
                                    .textOverflow(TextOverflow.Ellipsis),
                            )

                            SpanText(
                                formatDateToMMDDYYYY(file.createdAt, "."),
                                modifier = Modifier
                                    .fontSize(14.px)
                                    .lineHeight(20.px)
                                    .fontWeight(500)
                                    .color(Color.rgb(0x6E6A8B))
                                    .margin(top = 8.px),
                            )
                        }

                        EsSmallOutlineIconButton(
                            onClick = {
                                onDownloadFile(file)
                            },
                            iconRes = iconSrc(EsIcons.Download),
                        )
                    }
                }
            }
        }
    }
}

val DownloadButtonVariant by ButtonStyle.addVariantBase {
    Modifier
        .backgroundColor(Color.rgb(0xCEC8F9))
        .color(Color.rgb(0x5935E9))
}