package com.essntl.toastmanager

import kotlinx.coroutines.flow.SharedFlow

interface ToastManager {
    val toastFlow: SharedFlow<Toast>
    val clickedToastFlow: SharedFlow<Toast>
    val throwable: SharedFlow<Throwable>

    suspend fun success(
        title: String = "Success",
        description: String,
    )

    suspend fun warning(
        title: String = "Warning",
        description: String,
    )

    suspend fun error(
        title: String = "Something went wrong",
        description: String,
        throwable: Throwable? = null,
    )

    suspend fun info(
        title: String = "Information",
        description: String,
    )

    suspend fun message(
        message: Toast.Message
    )

    suspend fun clickToast(toast: Toast)

    companion object {
        const val TOAST_DURATION_MILLIS = 3000L
        const val TOAST_DELAY_MILLIS = 200L
    }
}
