package client.pages.components

import androidx.compose.runtime.Composable
import client.Branding
import client.components.widgets.HeaderGlassCard
import client.components.widgets.SvgEssntl
import client.ui.EsIcons
import client.ui.imageSrc
import com.essntl.core.utils.datetime.formatDateToMMDDYYYYDot
import com.essntl.features.area.domain.model.AreaModel
import com.essntl.features.client.domain.model.ClientModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.proposal.domain.model.ProposalModel
import com.varabyte.kobweb.compose.css.TextTransform
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backdropFilter
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flex
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textTransform
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import core.config.buildFlavor
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.vh

val ProposalCoverStyle by ComponentStyle.base {
    Modifier
        .width(100.percent)
        .height(100.vh)
}

@Composable
fun ProposalCover(
    proposal: ProposalModel,
    proposalCoverImage: FileModel?,
    proposalPrimaryClient: ClientModel?,
    travelAgentLogo: FileModel?,
    area: AreaModel?,
    modifier: Modifier = Modifier,
) {
    val breakpoint = rememberBreakpoint()

    Box(
        modifier =
        ProposalCoverStyle
            .toModifier()
            .then(modifier)
            .padding(ProposalCoverValues.Padding.boxPadding),
    ) {
        Column(
            modifier =
            proposalCoverModifier(
                proposalCoverImage = proposalCoverImage,
            ),
        ) {
            // Header
            val headerText = if (buildFlavor.isDemo) {
                "Essential by Nature, Exceptional by Design."
            } else {
                "Select the Essential, experience the extraordinary."
            }

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier =
                Modifier
                    .fillMaxWidth()
                    .borderRadius(
                        topLeft = 28.px,
                        topRight = 28.px,
                    )
                    .backgroundColor(Branding.backgroundPrimary)
                    .padding(
                        topBottom = ProposalCoverValues.Padding.headerTopBottom,
                        leftRight = ProposalCoverValues.Padding.headerLeftRight,
                    ),
            ) {
                SpanText(
                    text = headerText,
                    modifier =
                    Modifier
                        .textTransform(TextTransform.Capitalize)
                        .color(Color.rgb(0xFFFFFF))
                        .fontFamily("Manrope", "sans-serif")
                        .fontSize(
                            if (breakpoint < Breakpoint.MD) {
                                13.px
                            } else {
                                24.px
                            },
                        )
                        .fontWeight(600)
                        .lineHeight(
                            if (breakpoint < Breakpoint.MD) {
                                18.px
                            } else {
                                32.px
                            },
                        ),
                )

                Spacer()

                if (!buildFlavor.isDemo) {
                    Image(
                        src = imageSrc(EsIcons.BeLogoFullNude),
                        modifier =
                        Modifier
                            .size(if (breakpoint < Breakpoint.MD) 60.px else 120.px),
                    )
                } else {
                    SvgEssntl(
                        modifier = Modifier
                            .size(60.px),
                        color = "white",
                    )
                }
            }

            // Proposal details
            Column(
                modifier =
                Modifier
                    .fillMaxWidth()
                    .flex(1)
                    .padding(
                        leftRight =
                        if (breakpoint < Breakpoint.MD) {
                            16.px
                        } else {
                            40.px
                        },
                        topBottom =
                        if (breakpoint < Breakpoint.MD) {
                            16.px
                        } else {
                            40.px
                        },
                    ),
            ) {
                SpanText(
                    text = "Welcome to ${area?.country.orEmpty()}".uppercase(),
                    modifier =
                    Modifier
                        .textTransform(TextTransform.Capitalize)
                        .color(Colors.White)
                        .fontFamily("PP Mori", "Manrope", "sans-serif")
                        .fontSize(
                            if (breakpoint < Breakpoint.MD) {
                                32.px
                            } else {
                                60.px
                            },
                        )
                        .fontWeight(600)
                        .lineHeight(
                            if (breakpoint < Breakpoint.MD) {
                                40.px
                            } else {
                                64.px
                            },
                        )
                        .letterSpacing(
                            if (breakpoint < Breakpoint.MD) {
                                (-0.64).px
                            } else {
                                (-1.2).px
                            },
                        ),
                )

                proposal.headline?.let {
                    SpanText(
                        text = it,
                        modifier =
                        Modifier
                            .textTransform(TextTransform.Capitalize)
                            .margin(top = 14.px, bottom = 40.px)
                            .color(Colors.White)
                            .fontFamily("PP Mori", "Manrope", "sans-serif")
                            .fontSize(
                                if (breakpoint < Breakpoint.MD) {
                                    18.px
                                } else {
                                    36.px
                                },
                            )
                            .fontWeight(500)
                            .lineHeight(
                                if (breakpoint < Breakpoint.MD) {
                                    22.px
                                } else {
                                    38.px
                                },
                            )
                            .letterSpacing(
                                if (breakpoint < Breakpoint.MD) {
                                    (-0.64).px
                                } else {
                                    (-1.2).px
                                },
                            ),
                    )
                }

                // Dates
                Row(
                    modifier =
                    Modifier
                        .margin(bottom = 10.px),
                ) {
                    HeaderGlassCard(
                        title = proposal.fromDate?.let { formatDateToMMDDYYYYDot(it) } ?: "",
                        modifier =
                        Modifier
                            .fillMaxWidth()
                            .margin(right = 10.px),
                    )

                    HeaderGlassCard(
                        title = proposal.toDate?.let { formatDateToMMDDYYYYDot(it) } ?: "",
                        modifier =
                        Modifier
                            .fillMaxWidth(),
                    )
                }

                proposalPrimaryClient?.let { client ->
                    val salutation = client.salutation ?: client.prettyName
                    HeaderGlassCard(
                        title = salutation,
                        modifier =
                        Modifier
                            .textTransform(TextTransform.Capitalize)
                            .margin(bottom = 10.px),
                    )
                }

                // Travel Agent + Logo
                val travelAgent = proposal.travelAgent

                val travelAgentCardTitle =
                    "In partnership with ${travelAgent?.firstName ?: ""} ${travelAgent?.lastName ?: ""}"

                @Composable
                fun travelAgentTitle() {
                    HeaderGlassCard(
                        title = travelAgentCardTitle,
                        modifier =
                        Modifier
                            .textTransform(TextTransform.Capitalize),
                    )
                }

                @Composable
                fun travelAgentLogo(modifier: Modifier = Modifier) {
                    travelAgentLogo?.let {
                        Image(
                            src = it.url,
                            height = ProposalCoverValues.Sizes.taLogo,
                            modifier =
                            modifier
                                .backgroundColor(rgba(255, 255, 255, .8f))
                                .backdropFilter(blur(6.px))
                                .borderRadius(16.px),
                        )
                    }
                }

                if (breakpoint < Breakpoint.MD) {
                    travelAgentTitle()
                    travelAgentLogo(
                        modifier =
                        Modifier
                            .margin(top = 10.px),
                    )
                } else {
                    Spacer()
                    Row(
                        verticalAlignment = Alignment.Bottom,
                        modifier =
                        Modifier
                            .fillMaxWidth(),
                    ) {
                        travelAgentTitle()
                        Spacer()
                        travelAgentLogo(Modifier)
                    }
                }
            }
        }
    }
}
