package com.essntl.features.service.data.repository

import com.essntl.core.supabase.delete
import com.essntl.core.supabase.getAll
import com.essntl.core.supabase.getById
import com.essntl.core.supabase.getByIds
import com.essntl.core.supabase.getCount
import com.essntl.core.supabase.insertCustom
import com.essntl.core.supabase.updateCustom
import com.essntl.core.utils.repository.Repository
import com.essntl.features.service.data.supabase.ServiceDto
import com.essntl.features.service.data.supabase.ServiceSimpleDto
import com.essntl.features.service.data.supabase.ServiceSupabaseDataSource
import com.essntl.features.service.data.supabase.toRequestMap
import com.essntl.features.service.domain.model.ServiceFilterOptions
import com.essntl.features.service.domain.model.ServiceModel
import com.essntl.features.service.domain.model.ServiceOrderType
import com.essntl.features.service.domain.repository.ServiceRepository
import io.github.jan.supabase.postgrest.query.Order
import io.github.jan.supabase.postgrest.query.filter.PostgrestFilterBuilder
import org.koin.core.annotation.Single

@Single
internal class ServiceRepositoryImpl internal constructor(
    private val supabase: ServiceSupabaseDataSource,
) : Repository(), ServiceRepository {
    override suspend fun get(id: String): Result<ServiceModel> =
        runCatching {
            val service =
                supabase.getById(
                    id = id,
                    columns = ServiceDto.getColumns(),
                )

            service.toModel()
        }

    override suspend fun getByIds(ids: List<String>): Result<List<ServiceModel>> =
        runCatching {
            val serviceList =
                supabase.getByIds(
                    ids = ids,
                    columns = ServiceDto.getColumns(),
                )

            serviceList
                .map { service ->
                    service.toModel()
                }
        }

    override suspend fun getAll(
        page: Long,
        limit: Int,
        filterOptions: ServiceFilterOptions,
    ): Result<List<ServiceModel>> =
        runCatching {
            val serviceList =
                supabase.getAll(
                    page = page,
                    limit = limit,
                    columns = ServiceDto.getColumns(),
                ) {
                    if (
                        filterOptions.searchText.isNotEmpty() ||
                        filterOptions.selectedCategoryIds.isNotEmpty() ||
                        filterOptions.selectedTagIds.isNotEmpty() ||
                        filterOptions.selectedAreaIds.isNotEmpty() ||
                        filterOptions.supplier != null
                    ) {
                        filter {
                            if (filterOptions.searchText.isNotEmpty()) {
                                or {
                                    ilike("title", "%${filterOptions.searchText}%")
                                    ilike("headline", "%${filterOptions.searchText}%")
                                    ilike("reference_code", "%${filterOptions.searchText}%")
                                }

                                and {
                                    applyCategoryTagsArea(filterOptions)
                                }
                            } else {
                                and {
                                    applyCategoryTagsArea(filterOptions)
                                }
                            }
                        }
                    }

                    when (filterOptions.selectedServiceOrderType) {
                        ServiceOrderType.Alphabetical ->
                            order(
                                column = "title",
                                order = Order.ASCENDING,
                            )

                        ServiceOrderType.LastCreated ->
                            order(
                                column = "created_at",
                                order = Order.DESCENDING,
                            )

                        ServiceOrderType.RecentlyChanged ->
                            order(
                                column = "updated_at",
                                order = Order.DESCENDING,
                            )
                    }
                }

            serviceList
                .map { service ->
                    service.toModel()
                }
        }
            .onFailure {
                it.printStackTrace()
            }

    private fun PostgrestFilterBuilder.applyCategoryTagsArea(filterOptions: ServiceFilterOptions) {
        if (filterOptions.selectedCategoryIds.isNotEmpty()) {
            and {
                or {
                    filterOptions.selectedCategoryIds.forEach { categoryId ->
                        eq("category", categoryId)
                    }
                }
            }
        }

        if (filterOptions.selectedTagIds.isNotEmpty()) {
            contains("tags", filterOptions.selectedTagIds.toList())
        }

        if (filterOptions.selectedAreaIds.isNotEmpty()) {
            and {
                or {
                    filterOptions.selectedAreaIds.forEach { areaId ->
                        eq("area_id", areaId)
                    }
                }
            }
        }

        filterOptions.supplier?.let { supplier ->
            contains("suppliers", listOf(supplier.id))
        }
    }

    override suspend fun getCount(categoryId: String?): Result<Long> =
        runCatching {
            supabase.getCount {
                if (categoryId != null) {
                    filter {
                        eq("category", categoryId)
                    }
                }
            }
        }

    override suspend fun insert(model: ServiceModel): Result<ServiceModel> =
        runCatching {
            val requestMap = model.toRequestMap()
            val dto: ServiceSimpleDto =
                supabase
                    .insertCustom(requestMap)
            val newModel = dto.toModel()

            newModel.copy(
                primaryImage =
                newModel.primaryImage?.copy(
                    url = model.primaryImage?.url ?: "",
                ),
            )
        }

    override suspend fun update(model: ServiceModel): Result<ServiceModel> =
        runCatching {
            val requestMap = model.toRequestMap()
            val dto: ServiceSimpleDto =
                supabase
                    .updateCustom(id = model.id, requestMap)
            val newModel = dto.toModel()

            newModel.copy(
                primaryImage =
                newModel.primaryImage?.copy(
                    url = model.primaryImage?.url ?: "",
                ),
            )
        }

    override suspend fun delete(model: ServiceModel): Result<Unit> =
        runCatching {
            supabase.delete(id = model.id)
        }
}
