package com.essntl.features.tag.domain.model

data class TagModel(
    val id: String,
    val name: String,
) {
    companion object {
        val empty =
            TagModel(
                id = "",
                name = "",
            )
    }
}
