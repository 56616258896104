package com.essntl.features.proposal.data.supabase.proposal

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class ProposalDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<ProposalDto> {
    override val tableName: String = "proposal"
}
