package com.essntl.features.tag.data.supabase

import com.essntl.features.tag.domain.model.TagModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class TagDto(
    val id: String,
    val name: String,
    @SerialName("created_at")
    val createdAt: String,
) {
    fun toModel() =
        TagModel(
            id = id,
            name = name,
        )
}
