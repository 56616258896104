package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_file_data_di_FileModule : Module = module {
	single() { com.essntl.features.file.data.repository.FileRepositoryImpl(supabase=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.file.domain.repository.FileRepository::class))
	single() { com.essntl.features.file.data.supabase.FileSupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.file.data.di.FileModule.module : org.koin.core.module.Module get() = com_essntl_features_file_data_di_FileModule