package com.essntl.features.file.data.supabase

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class FileSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<FileDto> {
    override val tableName: String = "file"
}
