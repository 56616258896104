package com.essntl.features.file.data.supabase

import com.essntl.config.EssntlConfig
import com.essntl.features.file.domain.model.FileModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FileDto(
    val id: String,
    @SerialName("created_at")
    val createdAt: String,
    val url: String,
    val name: String?,
    @SerialName("uploaded_by")
    val uploadedBy: String?,
    @SerialName("entity_type")
    val entityType: String?,
) {
    fun toModel() =
        FileModel(
            id = id,
            createdAt = createdAt,
            url = if (url.startsWith("http")) url else
                "${EssntlConfig.supabaseUrl}/${EssntlConfig.supabaseStorageUrl}/$url",
            name = name,
            uploadedBy = uploadedBy,
            entityType = entityType,
        )
}
