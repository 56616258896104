package com.essntl.features.service.domain.model

import com.essntl.features.file.domain.model.FileModel

data class ServiceModel(
    val id: String,
    val title: String,
    val category: String?,
    val description: String?,
    val location: String?,
    val areaId: String?,
    val headline: String?,
    val tags: Set<String>?,
    val images: Set<String>?,
    val createdAt: String,
    val updatedAt: String?,
    val primaryImage: FileModel?,
    val referenceCode: String?,
    val internalNotes: String?,
    val suppliers: List<String>?,
    val qbItemId: String?
) {
    /**
     * Returns a new instance of the ServiceModel with only the fields that have changed from the given serviceModel.
     *
     * @param serviceModel The ServiceModel to compare with.
     * @return A new instance of ServiceModel with only the changed fields.
     */
    fun getChangedFields(serviceModel: ServiceModel) =
        ServiceModel(
            id = id,
            title = if (title != serviceModel.title) serviceModel.title else "",
            category = if (category != serviceModel.category) serviceModel.category else null,
            description = if (description.orEmpty() != serviceModel.description.orEmpty()) serviceModel.description else null,
            location = if (location != serviceModel.location) serviceModel.location else null,
            areaId = if (areaId != serviceModel.areaId) serviceModel.areaId else null,
            headline = if (headline != serviceModel.headline) serviceModel.headline else null,
            tags =
            if (tags != serviceModel.tags) {
                if (tags == null && serviceModel.tags.isNullOrEmpty()) null else serviceModel.tags
            } else {
                null
            },
            images = if (images != serviceModel.images) serviceModel.images else null,
            primaryImage =
            if (primaryImage?.id != serviceModel.primaryImage?.id) {
                serviceModel.primaryImage
            } else {
                null
            },
            createdAt = createdAt,
            updatedAt = null,
            referenceCode = if (referenceCode != serviceModel.referenceCode) serviceModel.referenceCode else null,
            internalNotes = if (internalNotes != serviceModel.internalNotes) serviceModel.internalNotes else null,
            suppliers = if (suppliers != serviceModel.suppliers) serviceModel.suppliers else null,
            qbItemId = if (qbItemId != serviceModel.qbItemId) serviceModel.qbItemId else null,
        )

    val isEmpty get() = this.copy(id = "", createdAt = "") == empty

    companion object {
        val empty =
            ServiceModel(
                id = "",
                title = "",
                category = null,
                description = null,
                location = null,
                areaId = null,
                headline = null,
                tags = null,
                images = null,
                createdAt = "",
                updatedAt = null,
                primaryImage = null,
                referenceCode = null,
                internalNotes = null,
                suppliers = null,
                qbItemId = null
            )
    }
}
