package com.essntl.features.area.domain.model

data class AreaModel(
    val id: String,
    val name: String?,
    val country: String?,
    val createdAt: String,
) {
    val isEmpty
        get() = this == empty

    companion object {
        val empty =
            AreaModel(
                id = "",
                name = "",
                country = "",
                createdAt = "",
            )
    }
}
