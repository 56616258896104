package com.essntl.core.network

import io.ktor.client.HttpClient
import io.ktor.client.plugins.cache.HttpCache
import io.ktor.client.request.get
import io.ktor.client.statement.readBytes

class ImageClient {
    private val httpClient: HttpClient by lazy {
        createPlatformHttpClient().config {
            install(HttpCache)
        }
    }

    suspend fun get(url: String): ByteArray = httpClient.get(url).readBytes()
}
