package client.components.widgets

import androidx.compose.runtime.Composable
import client.pages.components.ProposalCoverValues
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backdropFilter
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba

@Composable
fun HeaderGlassCard(
    title: String,
    modifier: Modifier = Modifier
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .borderRadius(16.px)
            .backgroundColor(rgba(255, 255, 255, .2f))
            .backdropFilter(blur(6.px))
            .padding(
                topBottom = ProposalCoverValues.Padding.glassCardTopBottom,
                leftRight = ProposalCoverValues.Padding.glassCardLeftRight,
            )
    ) {
        SpanText(
            text = title,
            modifier = Modifier
                .color(Colors.White)
                .fontSize(ProposalCoverValues.Font.glassCardSize)
                .fontWeight(600)
                .lineHeight(20.px)
                .letterSpacing((-0.2).px)
                .styleModifier {
                    property("text-wrap", "nowrap")
                }
        )
    }
}