package client.pages

import com.essntl.core.utils.viewmodel.State
import com.essntl.features.area.domain.model.AreaModel
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.client.domain.model.ClientModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.itinerary.domain.model.ItineraryModel
import com.essntl.features.itinerary.domain.model.ItineraryServiceModel
import com.essntl.features.paymentlink.domain.model.PaymentLinkModel
import com.essntl.features.proposal.domain.model.ProposalModel
import com.essntl.features.proposal.domain.model.ProposalServiceModel
import com.essntl.features.service.domain.model.ServiceModel
import com.essntl.features.tag.domain.model.TagModel

data class ProposalState(
    val getProposal: State<ProposalModel> = State(),
    val getItinerary: State<ItineraryModel> = State(),
    val getProposalCoverImage: State<FileModel> = State(),
    val getPrimaryClient: State<ClientModel> = State(),
    val getTravelAgentLogo: State<FileModel> = State(),
    val getArea: State<AreaModel> = State(),
    val getAllProposalService: State<List<ProposalServiceModel>> = State(),
    val getAllItineraryService: State<List<ItineraryServiceModel>> = State(),
    val getAllService: State<List<ServiceModel>> = State(),
    val getAllCategory: State<List<CategoryModel>> = State(),
    val getAllTag: State<List<TagModel>> = State(),
    val getAllImage: State<List<FileModel>> = State(),
    val getPaymentLinks: State<List<PaymentLinkModel>> = State(),
    val getAllFile: State<List<FileModel>> = State(),
) {
    fun itineraryServicesToUiStateList(proposal: ProposalModel): List<ServiceUiState> {
        return getAllItineraryService.data
            .orEmpty()
            .map {
                ServiceUiState(
                    id = it.id,
                    serviceId = it.serviceId,
                    grouped = it.grouped,
                    additionalInfo = it.additionalInfo,
                    editedDescription = it.editedDescription,
                    startDate = it.startDate,
                    endDate = it.endDate,
                    startTime = it.startTime,
                    endTime = it.endTime,
                    index = 0,
                    status = when (it.status) {
                        ItineraryServiceModel.Status.Ready -> ServiceUiState.Status.Ready
                        ItineraryServiceModel.Status.POSent -> ServiceUiState.Status.POSent
                        ItineraryServiceModel.Status.Confirmed -> ServiceUiState.Status.Confirmed
                        ItineraryServiceModel.Status.TOChange -> ServiceUiState.Status.TOChange
                        ItineraryServiceModel.Status.Cancelled -> ServiceUiState.Status.Cancelled
                        ItineraryServiceModel.Status.Completed -> ServiceUiState.Status.Completed
                        null -> null
                    },
                    createdAt = it.createdAt,
                    updatedAt = it.updatedAt,
                    price = it.price,
                    internalNotes = it.internalNotes,
                    supplier = it.supplier,
                    guestCount = it.guestCount,
                    address = it.address,
                    updatedBy = it.updatedBy,
                    contactName = it.contactName,
                    contactPhoneNumber = it.contactPhoneNumber,
                    itineraryId = it.itineraryId,
                    dynamicFields = it.dynamicFields,
                    specialNotes = it.specialNotes,
                    paid = it.paid,
                    embeddedMap = it.embeddedMap,
                    embeddedContent = it.embeddedContent,
                    files = it.files,
                    linkedId = it.linkedId,
                    hidePrices = proposal.hidePrices,
                )
            }
    }

    fun proposalServicesToUiStateList(proposal: ProposalModel): List<ServiceUiState> {
        return getAllProposalService.data
            .orEmpty()
            .map {
                ServiceUiState(
                    id = it.id,
                    serviceId = it.serviceId,
                    grouped = it.grouped,
                    additionalInfo = it.additionalInfo,
                    editedDescription = it.editedDescription,
                    startDate = it.startDate,
                    endDate = it.endDate,
                    startTime = it.startTime,
                    endTime = it.endTime,
                    index = it.index,
                    status = when (it.status) {
                        ProposalServiceModel.Status.Ready -> ServiceUiState.Status.Ready
                        ProposalServiceModel.Status.POSent -> ServiceUiState.Status.POSent
                        ProposalServiceModel.Status.Confirmed -> ServiceUiState.Status.Confirmed
                        ProposalServiceModel.Status.TOChange -> ServiceUiState.Status.TOChange
                        ProposalServiceModel.Status.Cancelled -> ServiceUiState.Status.Cancelled
                        ProposalServiceModel.Status.Completed -> ServiceUiState.Status.Completed
                        null -> null
                    },
                    createdAt = it.createdAt,
                    updatedAt = it.updatedAt,
                    price = it.price,
                    internalNotes = it.internalNotes,
                    supplier = it.supplier,
                    guestCount = it.guestCount,
                    address = it.address,
                    updatedBy = it.updatedBy,
                    contactName = it.contactName,
                    contactPhoneNumber = it.contactPhoneNumber,
                    itineraryId = it.itineraryId,
                    specialNotes = it.specialNotes,
                    paid = it.paid,
                    embeddedMap = it.embeddedMap,
                    embeddedContent = it.embeddedContent,
                    files = it.files,
                    hidePrices = proposal.hidePrices,
                    dynamicFields = null,
                    linkedId = null,
                )
            }
    }
}