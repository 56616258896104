package client.pages

import androidx.compose.runtime.Composable
import client.pages.components.ItineraryScreenContent
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext

@Page(routeOverride = "itinerary/preview/{id}")
@Composable
fun ItineraryPreviewPage() {
    val ctx = rememberPageContext()
    val itineraryId = ctx.route.params.getValue("id")

    ItineraryScreenContent(itineraryId = itineraryId)
}