package core.datastore.di

import com.russhwolf.settings.Settings
import core.datastore.data.DatastoreManager
import org.koin.dsl.module

val datastoreModule
    get() =
        module {
            single { Settings() }
            single { DatastoreManager(settings = get()) }
        }