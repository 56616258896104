package com.essntl.features.client.domain.model

import com.essntl.features.travelagent.domain.model.TravelAgentModel
import com.essntl.uikit.components.chat.contact.ContactUiState

data class ClientModel(
    val id: String,
    val createdAt: String,
    val firstName: String?,
    val lastName: String,
    val dateOfBirth: String?,
    val notes: String?,
    val telephone: String?,
    val email: String?,
    val salutation: String?,
    val travelAgent: TravelAgentModel?,
    val qbCustomerId: String?,
) {
    val toContactUiState
        get() =
            ContactUiState(
                name = if (id.isNotEmpty()) prettyName else null,
                phoneNumber = telephone,
                isOnline = false,
                type = if (id.isNotEmpty()) ContactUiState.ContactType.Client else null,
            )

    val prettyName
        get() = firstName?.let { "$it $lastName" } ?: lastName

    fun getChangedFields(clientModel: ClientModel) =
        ClientModel(
            id = id,
            createdAt = createdAt,
            firstName = if (firstName.orEmpty() != clientModel.firstName.orEmpty()) clientModel.firstName else null,
            lastName = if (lastName != clientModel.lastName) clientModel.lastName else "",
            dateOfBirth = if (dateOfBirth.orEmpty() != clientModel.dateOfBirth.orEmpty()) clientModel.dateOfBirth else null,
            telephone = if (telephone.orEmpty() != clientModel.telephone.orEmpty()) clientModel.telephone else null,
            notes = if (notes.orEmpty() != clientModel.notes.orEmpty()) clientModel.notes else null,
            email = if (email.orEmpty() != clientModel.email.orEmpty()) clientModel.email else null,
            salutation = if (salutation.orEmpty() != clientModel.salutation.orEmpty()) clientModel.salutation else null,
            travelAgent = if (travelAgent != clientModel.travelAgent) clientModel.travelAgent else null,
            qbCustomerId = if (qbCustomerId != clientModel.qbCustomerId) clientModel.qbCustomerId else null,
        )

    val isEmpty
        get() = this.copy(id = "", createdAt = "") == empty

    companion object {
        val empty =
            ClientModel(
                id = "",
                createdAt = "",
                firstName = null,
                lastName = "",
                dateOfBirth = null,
                notes = null,
                telephone = null,
                email = null,
                salutation = null,
                travelAgent = null,
                qbCustomerId = null,
            )
    }
}
