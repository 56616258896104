import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { client.pages.HomePage() }
        ctx.router.register("/itinerary/preview/{id}") { client.pages.ItineraryPreviewPage() }
        ctx.router.register("/itinerary/{share_code}") { client.pages.ItineraryPage() }
        ctx.router.register("/proposal/{id}") { client.pages.ProposalPreviewPage() }
        ctx.router.register("/termsandconditions") { client.pages.TermsAndConditionsPage() }
        ctx.router.register("/trip/{shareCode}") { client.pages.ProposalPage() }
        ctx.router.register("/trip/{shareCode}/v/{version}") { client.pages.ProposalHistoryPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerVariant("-circle", client.CircleButtonVariant
        )
        ctx.theme.registerVariant("-rounded", client.RoundedButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", client.UncoloredButtonVariant
        )
        ctx.theme.registerVariant("-primary", client.PrimaryButtonVariant
        )
        ctx.theme.registerVariant("-es-small-outline-icon",
                client.components.widgets.EsSmallOutlineIconButtonVariant
        )
        ctx.theme.registerVariant("-selected-itinerary-btn",
                client.pages.components.SelectedItineraryBtnVariant
        )
        ctx.theme.registerVariant("-unselected-itinerary-btn",
                client.pages.components.UnselectedItineraryBtnVariant
        )
        ctx.theme.registerVariant("-itinerary-btn", client.pages.components.ItineraryBtnVariant
        )
        ctx.theme.registerVariant("-download", client.pages.components.service.DownloadButtonVariant
        )
        ctx.theme.registerStyle(client.HeadlineTextStyle)
        ctx.theme.registerStyle(client.SubheadlineTextStyle)
        ctx.theme.registerStyle(client.pages.components.ProposalCoverStyle)
        ctx.theme.registerStyle(client.ui.H1ExtraBold36)
        ctx.theme.registerStyle(client.ui.H2Bold32)
        ctx.theme.registerStyle(client.ui.H3Bold24)
        ctx.theme.registerStyle(client.ui.H3Regular24)
        ctx.theme.registerStyle(client.ui.H4SemiBold20)
        ctx.theme.registerStyle(client.ui.S1Bold16)
        ctx.theme.registerStyle(client.ui.S2SemiBold16)
        ctx.theme.registerStyle(client.ui.P1Medium16)
        ctx.theme.registerStyle(client.ui.P1Regular16)
        ctx.theme.registerStyle(client.ui.P2Medium14)
        ctx.theme.registerStyle(client.ui.P2Regular14)
        ctx.theme.registerStyle(client.ui.P2Regular16)
        ctx.theme.registerStyle(client.ui.C1Medium13)
        ctx.theme.registerStyle(client.ui.C1Medium11)
        client.initColorMode(ctx)
        client.initKoin(ctx)
        client.initSiteStyles(ctx)
        client.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Essntlly client"))
    renderComposable(rootElementId = "root") {
        client.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
