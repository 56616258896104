package com.essntl.features.area.data.repository

import com.essntl.core.supabase.delete
import com.essntl.core.supabase.getAll
import com.essntl.core.supabase.getById
import com.essntl.core.supabase.insert
import com.essntl.core.supabase.update
import com.essntl.core.utils.repository.Repository
import com.essntl.features.area.data.supabase.AreaSupabaseDataSource
import com.essntl.features.area.data.supabase.toRequestMap
import com.essntl.features.area.domain.model.AreaModel
import com.essntl.features.area.domain.repository.AreaRepository
import org.koin.core.annotation.Single

@Single
class AreaRepositoryImpl internal constructor(
    private val supabase: AreaSupabaseDataSource,
) : Repository(), AreaRepository {
    override suspend fun get(areaId: String): Result<AreaModel> =
        runCatching {
            supabase
                .getById(areaId)
                .toModel()
        }

    override suspend fun getAll(): Result<List<AreaModel>> =
        runCatching {
            supabase
                .getAll()
                .map {
                    it.toModel()
                }
        }

    override suspend fun insert(area: AreaModel): Result<AreaModel> =
        runCatching {
            supabase
                .insert(area.toRequestMap())
                .toModel()
        }

    override suspend fun update(area: AreaModel): Result<AreaModel> =
        runCatching {
            supabase
                .update(id = area.id, area.toRequestMap())
                .toModel()
        }

    override suspend fun delete(area: AreaModel): Result<Unit> =
        runCatching {
            supabase.delete(id = area.id)
        }
}
