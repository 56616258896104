package client.pages.components.service

import androidx.compose.runtime.Composable
import client.components.widgets.EsSmallOutlineIconButton
import client.ui.EsIcons
import client.ui.iconSrc
import com.essntl.features.file.domain.model.FileModel
import com.varabyte.kobweb.compose.css.BackgroundPosition
import com.varabyte.kobweb.compose.css.BackgroundRepeat
import com.varabyte.kobweb.compose.css.BackgroundSize
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.backgroundImage
import com.varabyte.kobweb.compose.ui.modifiers.backgroundPosition
import com.varabyte.kobweb.compose.ui.modifiers.backgroundRepeat
import com.varabyte.kobweb.compose.ui.modifiers.backgroundSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.jetbrains.compose.web.css.px

@Composable
fun FullScreenServiceImage(
    serviceImage: FileModel?,
    canGoBackward: Boolean,
    canGoForward: Boolean,
    onGoBackward: () -> Unit,
    onGoForward: () -> Unit,
    modifier: Modifier = Modifier,
) {
    val imageUrl = serviceImage?.url

    Box(
        modifier = modifier
            .fillMaxSize()
            .then(
                if (imageUrl == null)
                    Modifier
                        .backgroundColor(Colors.LightGrey)
                else
                    Modifier
                        .backgroundImage(url(imageUrl))
                        .backgroundSize(BackgroundSize.Contain)
                        .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
                        .backgroundRepeat(BackgroundRepeat.NoRepeat)
            )
    ) {
        if (canGoBackward)
            EsSmallOutlineIconButton(
                onClick = { onGoBackward() },
                iconRes = iconSrc(EsIcons.ArrowLeft),
                modifier = Modifier
                    .align(Alignment.CenterStart)
                    .margin(16.px)
            )

        if (canGoForward)
            EsSmallOutlineIconButton(
                onClick = { onGoForward() },
                iconRes = iconSrc(EsIcons.ArrowRight),
                modifier = Modifier
                    .align(Alignment.CenterEnd)
                    .margin(16.px)
            )
    }
}