package client.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import client.pages.ProposalState
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import core.config.BuildFlavor
import core.config.buildFlavor
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.Text

@Composable
fun ProposalScreen(
    state: ProposalState,
) {

    LaunchedEffect(state.getArea.data) {
        document.title = if (buildFlavor.isDemo) "Essntl" else "Be Essential"
        state.getArea.data?.country.let { country ->
            document.title = if (buildFlavor.isDemo) "Essntl" else "Be Essential - Welcome to $country"
        }
    }

    val proposal = state.getProposal.data

    Box(
        contentAlignment = Alignment.TopStart,
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(Color.rgb(0xF2EFEA)),
    ) {
        if (proposal == null)
            Text(
                value = "Loading...",
            )
        else
            ProposalScreenContent(
                proposal = proposal,
                getProposalCoverImage = state.getProposalCoverImage,
                getProposalPrimaryClient = state.getPrimaryClient,
                getTravelAgentLogo = state.getTravelAgentLogo,
                getArea = state.getArea,
                allProposalService = state.proposalServicesToUiStateList(proposal),
                getAllService = state.getAllService,
                getAllCategory = state.getAllCategory,
                getAllTag = state.getAllTag,
                getAllImage = state.getAllImage,
                paymentLinks = state.getPaymentLinks.data,
                getAllFile = state.getAllFile,
                onDownloadFile = {},
            )
    }
}