package client.ui

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import org.jetbrains.compose.web.css.px

val H1ExtraBold36 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.ExtraBold)
        .fontSize(36.px)
        .lineHeight(40.px)
        .letterSpacing((-1.08f).px)
}

val H2Bold32 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Bold)
        .fontSize(32.px)
        .lineHeight(36.px)
        .letterSpacing((-0.96f).px)
}

val H3Bold24 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Bold)
        .fontSize(24.px)
        .lineHeight(32.px)
        .letterSpacing((-0.48f).px)
}

val H3Regular24 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Normal)
        .fontSize(24.px)
        .lineHeight(32.px)
        .letterSpacing((-0.48f).px)
}

val H4SemiBold20 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.SemiBold)
        .fontSize(20.px)
        .lineHeight(24.px)
        .letterSpacing((-0.2f).px)
}

val S1Bold16 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Bold)
        .fontSize(16.px)
        .lineHeight(24.px)
}

val S2SemiBold16 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.SemiBold)
        .fontSize(16.px)
        .lineHeight(24.px)
}

val P1Medium16 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Medium)
        .fontSize(16.px)
        .lineHeight(24.px)
}

val P1Regular16 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Normal)
        .fontSize(16.px)
        .lineHeight(24.px)
}

val P2Medium14 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Medium)
        .fontSize(14.px)
        .lineHeight(20.px)
}

val P2Regular14 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Normal)
        .fontSize(14.px)
        .lineHeight(20.px)
}

val P2Regular16 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Normal)
        .fontSize(16.px)
        .lineHeight(20.px)
}

val C1Medium13 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Medium)
        .fontSize(13.px)
        .lineHeight(16.px)
}

val C1Medium11 by ComponentStyle.base {
    Modifier
        .fontFamily("Manrope", "sans-serif")
        .fontWeight(FontWeight.Medium)
        .fontSize(11.px)
        .lineHeight(16.px)
}
