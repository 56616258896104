package com.essntl.features.paymentlink.data.supabase

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class PaymentLinkSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<PaymentLinkDto> {
    override val tableName: String = "payment_link"
}
