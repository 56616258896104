package com.essntl.features.itinerary.data.supabase.itinerary

import com.essntl.features.itinerary.domain.model.ItineraryModel
import com.essntl.features.proposal.data.supabase.proposal.ProposalDto
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ItineraryDto(
    val id: String,
    @SerialName("proposal_id_data")
    val proposalId: ProposalDto? = null,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("share_code")
    val shareCode: String? = null,
) {
    fun toModel() =
        ItineraryModel(
            id = id,
            proposal = proposalId?.toModel(),
            createdAt = createdAt,
            shareCode = shareCode,
        )

    companion object {
        fun getColumns() =
            Columns.raw(COLUMNS_VALUE)

        const val COLUMNS_VALUE = "*, proposal_id_data: proposal_id(${ProposalDto.COLUMNS_VALUE})"
    }
}
