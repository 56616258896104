package com.essntl.features.travelagent.data.supabase

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class TravelAgentSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<TravelAgentDto> {
    override val tableName: String = "travel_agent"
}
