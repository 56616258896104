package client.pages.components
import androidx.compose.runtime.Composable
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px

object ProposalCoverValues {
    object Font {
        val titleSize: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 10.px else 20.px
            }

        val glassCardSize: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 14.px else 28.px
            }

    }

    object Padding {
        val boxPadding: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 10.px else 20.px
            }

        val headerTopBottom: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 10.px else 20.px
            }

        val headerLeftRight: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 14.px else 40.px
            }

        val glassCardTopBottom: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 10.px else 20.px
            }

        val glassCardLeftRight: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 12.px else 24.px
            }

        val esCardTopBottom: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 8.px else 12.px
            }

        val esCardLeftRight: CSSSizeValue<CSSUnit.px>
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 12.px else 20.px
            }
    }

    object Sizes {
        val taLogo: Int
            @Composable
            get() {
                val breakpoint = rememberBreakpoint()
                return if (breakpoint < Breakpoint.MD) 60 else 100
            }
    }
}