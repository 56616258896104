package client.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.components.graphics.Image

@Composable
fun EsSmallIcon(
    iconRes: String,
    modifier: Modifier = Modifier,
) {
    Image(
        src = iconRes,
        width = 20,
        height = 20,
        modifier = modifier,
    )
}