package com.essntl.features.proposal.domain.model

import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.travelagent.domain.model.TravelAgentModel

data class ProposalModel(
    val id: String,
    val title: String?,
    val headline: String?,
    val areas: List<String>?,
    val fromDate: String?,
    val toDate: String?,
    val requestId: String?,
    val itineraryId: String?,
    val proposalId: String?,
    val status: Status?,
    val version: Int,
    val coverImage: FileModel?,
    val primaryClient: String?,
    val ownerId: String?,
    val createdAt: String,
    val updatedAt: String?,
    val clients: List<String>?,
    val guestCount: Int,
    val shareCode: String?,
    val guestDetails: String?,
    val statusUpdatedAt: String?,
    val requestReceivedDate: String?,
    val travelAgent: TravelAgentModel?,
    val commission: Double?,
    val hidePrices: Boolean,
) {
    fun getChangedFields(proposalModel: ProposalModel) =
        ProposalModel(
            id = id,
            createdAt = createdAt,
            proposalId = proposalId,
            title = if (title != proposalModel.title) proposalModel.title else null,
            fromDate = if (fromDate != proposalModel.fromDate) proposalModel.fromDate else null,
            toDate = if (toDate != proposalModel.toDate) proposalModel.toDate else null,
            areas = if (areas != proposalModel.areas) proposalModel.areas else null,
            headline =
            if (headline != proposalModel.headline) {
                if (headline == null && proposalModel.headline.isNullOrEmpty()) null else proposalModel.headline
            } else {
                null
            },
            requestId = if (requestId != proposalModel.requestId) proposalModel.requestId else null,
            itineraryId = if (itineraryId != proposalModel.itineraryId) proposalModel.itineraryId else null,
            coverImage =
            if (coverImage?.id != proposalModel.coverImage?.id ||
                coverImage?.url != proposalModel.coverImage?.url
            ) if (coverImage == null && proposalModel.coverImage == FileModel.empty) null
            else if (coverImage?.id == proposalModel.coverImage?.id) null
            else proposalModel.coverImage
            else null,
            version = 1,
            status = if (status != proposalModel.status) proposalModel.status else null,
            clients = if (clients != proposalModel.clients) proposalModel.clients else null,
            guestCount = if (guestCount != proposalModel.guestCount) proposalModel.guestCount else -1,
            primaryClient = if (primaryClient != proposalModel.primaryClient) proposalModel.primaryClient else null,
            ownerId = if (ownerId != proposalModel.ownerId) proposalModel.ownerId else null,
            shareCode = if (shareCode != proposalModel.shareCode) proposalModel.shareCode else null,
            guestDetails = if (guestDetails != proposalModel.guestDetails) proposalModel.guestDetails else null,
            updatedAt = null,
            statusUpdatedAt = null,
            travelAgent = if (travelAgent != proposalModel.travelAgent) proposalModel.travelAgent else null,
            requestReceivedDate = if (requestReceivedDate != proposalModel.requestReceivedDate) proposalModel.requestReceivedDate else null,
            commission = if (commission != proposalModel.commission) proposalModel.commission else null,
            hidePrices = if (hidePrices != proposalModel.hidePrices) proposalModel.hidePrices else false,
        )

    val isEmpty get() = this.copy(id = "", createdAt = "", proposalId = null) == empty

    enum class Status {
        Todo,
        InProgress,
        Sent,
        Confirmed,
        ItineraryReady,
        OnHold,
        FollowedUp,
        NotBooked,
        Accepted;

        override fun toString(): String {
            return when (this) {
                Todo -> "To do"
                InProgress -> "In progress"
                Sent -> "Sent"
                Confirmed -> "Confirmed"
                ItineraryReady -> "Itinerary"
                OnHold -> "On hold"
                FollowedUp -> "Followed up"
                NotBooked -> "Not Booked"
                Accepted -> "Accepted"
            }
        }
    }

    companion object {
        val empty =
            ProposalModel(
                id = "",
                createdAt = "",
                title = null,
                fromDate = null,
                toDate = null,
                headline = null,
                areas = null,
                requestId = null,
                itineraryId = null,
                proposalId = null,
                status = null,
                version = 1,
                coverImage = null,
                clients = null,
                primaryClient = null,
                ownerId = null,
                guestCount = -1,
                shareCode = null,
                updatedAt = null,
                guestDetails = null,
                statusUpdatedAt = null,
                travelAgent = null,
                requestReceivedDate = null,
                commission = null,
                hidePrices = false,
            )
    }
}
