package com.essntl.features.client.data.supabase

import com.essntl.features.client.domain.model.ClientModel
import com.essntl.features.travelagent.data.supabase.TravelAgentDto
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ClientDto(
    val id: String,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("first_name")
    val firstName: String?,
    @SerialName("last_name")
    val lastName: String,
    @SerialName("date_of_birth")
    val dateOfBirth: String?,
    val notes: String?,
    val telephone: String?,
    val email: String?,
    val salutation: String?,
    @SerialName("travel_agent_data")
    val travelAgent: TravelAgentDto? = null,
    @SerialName("qb_customer_id")
    val qbCustomerId: String? = null,
) {
    fun toModel() =
        ClientModel(
            id = id,
            createdAt = createdAt,
            firstName = firstName,
            lastName = lastName,
            dateOfBirth = dateOfBirth,
            notes = notes,
            telephone = telephone,
            email = email,
            salutation = salutation,
            travelAgent = travelAgent?.toModel(),
            qbCustomerId = qbCustomerId,
        )

    companion object {
        fun getColumns() =
            Columns.raw(
                "*, travel_agent_data:travel_agent(*)",
            )
    }
}
