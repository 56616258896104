package client

import com.varabyte.kobweb.compose.ui.graphics.Color
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgba

fun rgba(color: Color, a: Number): CSSColorValue = rgba(color.toRgb(), a)
fun rgba(rgb: Color.Rgb, a: Number): CSSColorValue = rgba(rgb.red, rgb.green, rgb.blue, a)

internal val Black = Color.argb(0xFF121212)

internal val Grayscale100 = Color.argb(0xFFFFFFFF)
internal val Grayscale200 = Color.argb(0xFFF7F7F8) // 0xFFF3F3F3
internal val Grayscale300 = Color.argb(0xFFE7E6EB)
internal val Grayscale400 = Color.argb(0xFFC6C5D0)
internal val Grayscale500 = Color.argb(0xFF8985A2)
internal val Grayscale600 = Color.argb(0xFF6E6A8B)
internal val Grayscale700 = Color.argb(0xFF58556A)
internal val Grayscale800 = Color.argb(0xFF121021)
internal val Grayscale900 = Color.argb(0xFF090811)

internal val Primary100 = Color.argb(0xFFF5F5FC)
internal val Primary200 = Color.argb(0xFFEFEDFD)
internal val Primary300 = Color.argb(0xFFCEC8F9)
internal val Primary400 = Color.argb(0xFF8D7EF1)
internal val Primary500 = Color.argb(0xFF5935E9)
internal val Primary600 = Color.argb(0xFF3412A6)
internal val Primary700 = Color.argb(0xFF190C6E)
internal val Primary800 = Color.argb(0xFF0E073D)
internal val Primary900 = Color.argb(0xFF06041F)

internal val Accent100 = Color.argb(0xFFFDFBEA)
val Accent200 = Color.argb(0xFFFDF9D0)
internal val Accent300 = Color.argb(0xFFFCF0B5)
internal val Accent400 = Color.argb(0xFFFBEB98)
val Accent500 = Color.argb(0xFFFAE36F)
internal val Accent600 = Color.argb(0xFFF8DB47)

internal val Orange200 = Color.argb(0xFFFEF3DC)
internal val Orange300 = Color.argb(0xFFFDE9C2)
internal val Orange400 = Color.argb(0xFFFBDA97)
internal val Orange500 = Color.argb(0xFFFAC867)
internal val Orange500base = Color.argb(0xFFF9C152)
internal val Orange800 = Color.argb(0xFFF7AA12)

internal val Green100 = Color.argb(0xFFF5FEFA)
internal val Green200 = Color.argb(0xFFD9FDEB)
val Green300 = Color.argb(0xFF9FF9CC)
internal val Green400 = Color.argb(0xFF47EBA9)
internal val Green500 = Color.argb(0xFF0CC078)
internal val Green600 = Color.argb(0xFF09865C)

internal val Red100 = Color.argb(0xFFFEF4F3)
internal val Red200 = Color.argb(0xFFF9CAC3)
internal val Red300 = Color.argb(0xFFF39587)
internal val Red400 = Color.argb(0xFFEE6B57)
internal val Red500 = Color.argb(0xFFE62B0F)
internal val Red600 = Color.argb(0xFF991D0A)