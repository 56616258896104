package client.ui

import androidx.compose.runtime.Stable
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times

object EsConstants {
    val SpacingUnit = 8.px

    val LargeButtonHeight = 48.px
    val MediumButtonHeight = 40.px
    val SmallButtonHeight = 32.px

    val LargeIconSize = 24.px
    val MediumIconSize = 20.px
    val SmallIconSize = 16.px

    val ButtonBorderRadius = 80.px
    val SectionBorderRadius = 24.px

    val SectionVerticalPadding = 3.spacing
    val SectionHorizontalPadding = 4.spacing

    val ButtonBorderWidth = 4.px
}

@Stable
inline val Int.spacing: CSSSizeValue<CSSUnit.px> get() = this.toFloat() * EsConstants.SpacingUnit

/**
 * Create a [CSSSizeValue<CSSUnit.px>] using a [Double]:
 *     val left = 10.0
 *     val x = left.px
 *     // -- or --
 *     val y = 10.0.px
 */
@Stable
inline val Double.spacing: CSSSizeValue<CSSUnit.px> get() = this.toFloat() * EsConstants.SpacingUnit

/**
 * Create a [CSSSizeValue<CSSUnit.px>] using a [Float]:
 *     val left = 10f
 *     val x = left.px
 *     // -- or --
 *     val y = 10f.px
 */
@Stable
inline val Float.spacing: CSSSizeValue<CSSUnit.px> get() = this * EsConstants.SpacingUnit
