package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_travelagent_data_di_TravelAgentModule : Module = module {
	single() { com.essntl.features.travelagent.data.repository.TravelAgentRepositoryImpl(supabase=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.travelagent.domain.repository.TravelAgentRepository::class))
	single() { com.essntl.features.travelagent.data.supabase.TravelAgentSupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.travelagent.data.di.TravelAgentModule.module : org.koin.core.module.Module get() = com_essntl_features_travelagent_data_di_TravelAgentModule