package client.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.px

@Composable
fun ItineraryDayInfosService(
    id: String,
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit,
) {
    val breakpoint = rememberBreakpoint()

    val newModifier = modifier
        .id(id)
        .padding(
            topBottom = if (breakpoint < Breakpoint.MD) 14.px else 16.px,
            leftRight = if (breakpoint < Breakpoint.MD) 14.px else 20.px,
        )
        .fillMaxWidth()

    if (breakpoint < Breakpoint.MD) {
        Column(
            modifier = newModifier
                .fillMaxWidth()
                .backgroundColor(Colors.White)
                .borderRadius(16.px)
        ) {
            content()
        }
    } else {
        Row(
            modifier = newModifier
                .alignItems(AlignItems.Stretch)
                .backgroundColor(Colors.White)
                .borderRadius(16.px)
        ) {
            content()
        }
    }
}