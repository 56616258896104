package client.pages.components.service

import androidx.compose.runtime.Composable
import client.components.widgets.EsCard
import client.pages.ServiceUiState
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.jetbrains.compose.web.css.px

@Composable
fun ServiceContactDetailsCard(
    proposalService: ServiceUiState,
) {
    proposalService.contactName?.let { contact ->
        val contactPhoneNumber = proposalService.contactPhoneNumber ?: ""

        EsCard(
            title = "Contact: $contact",
            description = "+$contactPhoneNumber",
            modifier = Modifier
                .margin(right = 10.px),
        )
    }
}