package client.pages.components

import androidx.compose.runtime.Composable
import client.Branding
import com.essntl.core.utils.datetime.getFutureDate
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.css.overflow
import com.varabyte.kobweb.compose.css.whiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import core.config.buildFlavor
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

@Composable
fun ItineraryNavRow(
    totalDays: Int,
    fromDate: String?,
    selectedDay: Int,
    onDaySelected: (Int) -> Unit,
) {
    val breakpoint = rememberBreakpoint()

    Box(
        modifier =  Modifier
            .width(100.percent)
            .padding(
                leftRight =
                    if (breakpoint < Breakpoint.MD)
                        10.px
                    else
                        20.px
            )
            .then(
                if (breakpoint < Breakpoint.MD)
                    Modifier
                else
                    Modifier
                        .position(Position.Sticky)
                        .top(0.px)
                        .zIndex(1)
            )
    ) {
        Column(
            modifier =  Modifier
                .fillMaxWidth()
                .padding(
                    topBottom = 28.px,
                    leftRight = 40.px,
                )
                .backgroundColor(if (buildFlavor.isDemo) Branding.backgroundSecondary else Colors.White)
                .borderRadius(28.px)

        ) {
            Div(
                attrs = {
                    style {
                        width(100.percent)
                        if (breakpoint >= Breakpoint.MD) {
                            overflow(Overflow.Auto)
                            whiteSpace(WhiteSpace.NoWrap)
                        }
                    }
                },
            ) {
                repeat(totalDays) {
                    val day = it + 1
                    console.log("fromDate $fromDate")
                    val date = getFutureDate(fromDate, it)

                    Button(
                        onClick = { onDaySelected(day) },
                        variant = ItineraryBtnVariant
                            .then(
                                if (day == selectedDay) SelectedItineraryBtnVariant else UnselectedItineraryBtnVariant
                            ),
                        modifier = Modifier
                            .then(
                                if (breakpoint < Breakpoint.MD)
                                    Modifier
                                else
                                    Modifier
                                        .width(114.px)
                            )
                            .height(52.px)
                            .margin(
                                right = 20.px,
                                bottom = if (breakpoint < Breakpoint.MD) 20.px else 0.px
                            )
                    ) {
                        Column(
                            horizontalAlignment = Alignment.CenterHorizontally,
                            modifier = Modifier
                        ) {
                            SpanText(
                                text = "Day $day",
                                modifier = Modifier
                                    .fontSize(14.px)
                                    .fontWeight(700)
                                    .lineHeight(20.px)
                            )

                            SpanText(
                                text = "$date",
                                modifier = Modifier
                                    .fontSize(14.px)
                                    .fontWeight(700)
                                    .lineHeight(20.px)
                            )
                        }
                    }
                }
            }
        }
    }
}

val SelectedItineraryBtnVariant =
    ButtonStyle.addVariantBase {
        Modifier
            .backgroundColor(Branding.backgroundPrimary)
            .color(Color.rgb(0xFFFFFF))
    }

val UnselectedItineraryBtnVariant = ButtonStyle.addVariantBase {
    Modifier
        .backgroundColor(Branding.controlTinted)
        .color(if (buildFlavor.isDemo) Branding.backgroundPrimary else Color.rgb(0x6E6A8B))
}

val ItineraryBtnVariant = ButtonStyle.addVariantBase {
    Modifier
        .padding(
            topBottom = 6.px,
            leftRight = 20.px,
        )
        .borderRadius(14.px)
        .display(DisplayStyle.InlineBlock)
}