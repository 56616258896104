package com.essntl.core.utils.repository

import com.essntl.toastmanager.IO
import com.essntl.toastmanager.ToastManager
import io.github.jan.supabase.postgrest.query.filter.FilterOperation
import io.github.jan.supabase.realtime.PostgresAction
import io.github.jan.supabase.realtime.RealtimeChannel
import io.github.jan.supabase.realtime.postgresChangeFlow
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.jsonPrimitive
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

abstract class Repository : KoinComponent {
    protected val toastManager by inject<ToastManager>()

    protected suspend inline fun <T> runCatching(
        sendToastOnFailure: Boolean = true,
        crossinline block: suspend () -> T,
    ): Result<T> =
        try {
            withContext(Dispatchers.IO) {
                Result.success(block())
            }
        } catch (e: CancellationException) {
            Result.failure(e)
        } catch (e: Throwable) {
            if (sendToastOnFailure) {
                toastManager.error(
                    description = e.message ?: "Something went wrong, please try again later",
                    throwable = e,
                )
            }
            e.cause?.printStackTrace()
            e.printStackTrace()

            Result.failure(e)
        }

    protected suspend inline fun <T, reified R> realTimeActions(
        channel: RealtimeChannel,
        tableName: String,
        crossinline mapper: (R) -> T,
        vararg filterOperation: FilterOperation,
    ): Flow<RealtimeAction<T>> {
        val changeFlow = channel.postgresChangeFlow<PostgresAction>(schema = "public") {
            table = tableName
            filterOperation.forEach { filter(it.column, it.operator, it.value) }
        }

        channel.subscribe(blockUntilSubscribed = true)

        return changeFlow
            .map { action ->
                when (action) {
                    is PostgresAction.Insert -> {
                        val dto = Json.decodeFromJsonElement<R>(action.record)
                        RealtimeAction.Insert(mapper(dto))
                    }

                    is PostgresAction.Update -> {
                        val dto = Json.decodeFromJsonElement<R>(action.record)
                        RealtimeAction.Update(mapper(dto))
                    }

                    is PostgresAction.Delete -> RealtimeAction.Delete(action.oldRecord["id"]?.jsonPrimitive?.content)

                    is PostgresAction.Select -> {
                        throw NotImplementedError("Select action is not implemented")
                    }
                }
            }
    }
}
