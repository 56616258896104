package com.essntl.toastmanager

import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.asSharedFlow

internal class ToastManagerImpl : ToastManager {
    private val _toastFlow = MutableSharedFlow<Toast>()
    override val toastFlow: SharedFlow<Toast> = _toastFlow.asSharedFlow()

    private val _clickedToastFlow = MutableSharedFlow<Toast>()
    override val clickedToastFlow: SharedFlow<Toast> = _clickedToastFlow.asSharedFlow()

    private val _throwable = MutableSharedFlow<Throwable>()
    override val throwable = _throwable.asSharedFlow()

    private suspend fun emitEvent(event: Toast) {
        _toastFlow.emit(event)
    }

    private suspend fun emitThrowable(e: Throwable) {
        _throwable.emit(e)
    }

    override suspend fun success(
        title: String,
        description: String,
    ) {
        emitEvent(
            Toast.Default(
                title = title,
                description = description,
                state = Toast.Default.State.Success,
            ),
        )
    }

    override suspend fun warning(
        title: String,
        description: String,
    ) {
        emitEvent(
            Toast.Default(
                title = title,
                description = description,
                state = Toast.Default.State.Warning,
            ),
        )
    }

    override suspend fun error(
        title: String,
        description: String,
        throwable: Throwable?,
    ) {
        emitEvent(
            Toast.Default(
                title = title,
                description = description,
                state = Toast.Default.State.Error,
            ),
        )

        if (throwable != null) emitThrowable(throwable)
    }

    override suspend fun info(
        title: String,
        description: String,
    ) {
        emitEvent(
            Toast.Default(
                title = title,
                description = description,
                state = Toast.Default.State.Information,
            ),
        )
    }

    override suspend fun message(message: Toast.Message) {
        emitEvent(message)
    }

    override suspend fun clickToast(toast: Toast) {
        _clickedToastFlow.emit(toast)
    }
}
