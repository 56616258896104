package com.essntl.features.paymentlink.domain.model

data class PaymentLinkModel(
    val id: String,
    val createdAt: String,
    val linkUrl: String?,
    val proposalId: String?,
    val linkId: String?,
    val productName: String?,
    val amount: Int?,
    val hasMerchantFee: Boolean?,
    val active: Boolean?,
) {
    companion object {
        val empty =
            PaymentLinkModel(
                id = "",
                createdAt = "",
                linkUrl = null,
                proposalId = null,
                linkId = null,
                productName = null,
                amount = null,
                hasMerchantFee = null,
                active = null,
            )
    }

    val isEmpty
        get() = this.copy(
            id = "",
            createdAt = "",
            linkUrl = null,
            proposalId = null,
            linkId = null,
            productName = null,
            amount = null,
            hasMerchantFee = null,
        ) == empty

    fun getChangedFields(paymentLinkModel: PaymentLinkModel): PaymentLinkModel =
        PaymentLinkModel(
            id = id,
            createdAt = createdAt,
            linkUrl = if (linkUrl != paymentLinkModel.linkUrl) paymentLinkModel.linkUrl else null,
            proposalId = if (proposalId != paymentLinkModel.proposalId) paymentLinkModel.proposalId else null,
            linkId = if (linkId != paymentLinkModel.linkId) paymentLinkModel.linkId else null,
            productName = if (productName != paymentLinkModel.productName) paymentLinkModel.productName else null,
            amount = if (amount != paymentLinkModel.amount) paymentLinkModel.amount else null,
            hasMerchantFee = if (hasMerchantFee != paymentLinkModel.hasMerchantFee) paymentLinkModel.hasMerchantFee else null,
            active = if (active != paymentLinkModel.active) paymentLinkModel.active else null,
        )
}
