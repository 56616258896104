package com.essntl.features.supplier.domain.model

import com.essntl.features.area.domain.model.AreaModel
import com.essntl.features.category.domain.model.CategoryModel

data class SupplierModel(
    val id: String,
    val createdAt: String,
    val name: String?,
    val currency: String?,
    val website: String?,
    val description: String?,
    val telephone: String?,
    val address: String?,
    val contactName: String?,
    val notes: String?,
    val email: String?,
    val area: AreaModel?,
    val category: CategoryModel?,
) {
    /**
     * Returns a new instance of the SupplierModel with only the fields that have changed from the given supplierModel.
     *
     * @param supplierModel The SupplierModel to compare with.
     * @return A new instance of SupplierModel with only the changed fields.
     */
    fun getChangedFields(supplierModel: SupplierModel) =
        SupplierModel(
            id = id,
            createdAt = createdAt,
            name = if (name.orEmpty() != supplierModel.name.orEmpty()) supplierModel.name else null,
            currency = if (currency.orEmpty() != supplierModel.currency.orEmpty()) supplierModel.currency else null,
            website = if (website.orEmpty() != supplierModel.website.orEmpty()) supplierModel.website else null,
            description = if (description.orEmpty() != supplierModel.description.orEmpty()) supplierModel.description else null,
            telephone = if (telephone.orEmpty() != supplierModel.telephone.orEmpty()) supplierModel.telephone else null,
            address = if (address.orEmpty() != supplierModel.address.orEmpty()) supplierModel.address else null,
            contactName = if (contactName.orEmpty() != supplierModel.contactName.orEmpty()) supplierModel.contactName else null,
            notes = if (notes.orEmpty() != supplierModel.notes.orEmpty()) supplierModel.notes else null,
            email = if (email.orEmpty() != supplierModel.email.orEmpty()) supplierModel.email else null,
            area =
                if (area?.id != supplierModel.area?.id) {
                    supplierModel.area
                } else {
                    null
                },
            category =
                if (category?.id != supplierModel.category?.id) {
                    supplierModel.category
                } else {
                    null
                },
        )

    val isEmpty get() = this.copy(id = "", createdAt = "") == empty

    companion object {
        val empty =
            SupplierModel(
                id = "",
                createdAt = "",
                name = null,
                currency = null,
                website = null,
                description = null,
                telephone = null,
                address = null,
                contactName = null,
                notes = null,
                email = null,
                area = null,
                category = null,
            )
    }
}
