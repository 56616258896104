package com.essntl.core.utils.utils.loadingmanager

import kotlinx.coroutines.flow.*

internal class LoadingManagerImpl: LoadingManager {
    private val _isLoadingFlow = MutableSharedFlow<Boolean>()

    override val isLoadingFlow = _isLoadingFlow.asSharedFlow()

    override suspend fun emitLoading(isLoading: Boolean) {
        _isLoadingFlow.emit(isLoading)
    }
}