package com.essntl.features.paymentlink.data.supabase

import com.essntl.features.paymentlink.domain.model.PaymentLinkModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class PaymentLinkDto(
    val id: String,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("link_url")
    val linkUrl: String?,
    @SerialName("proposal_id")
    val proposalId: String?,
    @SerialName("link_id")
    val linkId: String?,
    @SerialName("product_name")
    val productName: String?,
    val amount: Int?,
    @SerialName("has_merchant_fee")
    val hasMerchantFee: Boolean?,
    val active: Boolean?,
) {
    fun toModel() =
        PaymentLinkModel.empty.copy(
            id = id,
            createdAt = createdAt,
            linkUrl = linkUrl,
            proposalId = proposalId,
            linkId = linkId,
            productName = productName,
            amount = amount,
            hasMerchantFee = hasMerchantFee,
            active = active,
        )
}
