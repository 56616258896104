package client.pages

import androidx.compose.runtime.Composable
import client.pages.components.ItineraryScreenContent
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext

@Page(routeOverride = "itinerary/{share_code}")
@Composable
fun ItineraryPage() {
    val ctx = rememberPageContext()
    val shareCode = ctx.route.params.getValue("share_code")

    ItineraryScreenContent(shareCode = shareCode)
}