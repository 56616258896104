package com.essntl.features.supplier.data.supabase

import com.essntl.features.area.data.supabase.AreaDto
import com.essntl.features.category.data.supabase.CategoryDto
import com.essntl.features.supplier.domain.model.SupplierModel
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SupplierDto(
    val id: String,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("updated_at")
    val updatedAt: String?,
    val name: String?,
    val currency: String?,
    val website: String?,
    val description: String?,
    val telephone: String?,
    val address: String?,
    @SerialName("contact_name")
    val contactName: String?,
    val notes: String?,
    val email: String?,
    @SerialName("area_data")
    val area: AreaDto? = null,
    @SerialName("category_data")
    val category: CategoryDto? = null,
) {
    fun toModel() =
        SupplierModel(
            id = id,
            createdAt = createdAt,
            name = name,
            currency = currency,
            website = website,
            description = description,
            telephone = telephone,
            address = address,
            contactName = contactName,
            notes = notes,
            email = email,
            area = area?.toModel(),
            category = category?.toModel(),
        )

    companion object {
        fun getColumns() =
            Columns.raw(
                "*, area_data:area(*), " +
                    "category_data:category(*)",
            )
    }
}
