package com.essntl.features.category.data.supabase

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class CategorySupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<CategoryDto> {
    override val tableName: String = "category"
}
