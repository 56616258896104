package com.essntl.features.proposal.data.repository

import com.essntl.core.supabase.*
import com.essntl.core.utils.repository.Repository
import com.essntl.features.proposal.data.supabase.proposal.*
import com.essntl.features.proposal.data.supabase.proposal.ProposalDataSource
import com.essntl.features.proposal.data.supabase.proposal.toDto
import com.essntl.features.proposal.data.supabase.proposal.toRequestMap
import com.essntl.features.proposal.data.supabase.proposalhistory.ProposalHistoryDataSource
import com.essntl.features.proposal.data.supabase.proposalhistory.toHistoryRequestMap
import com.essntl.features.proposal.data.supabase.proposalservice.ProposalServiceDataSource
import com.essntl.features.proposal.data.supabase.proposalservice.ProposalServiceDto
import com.essntl.features.proposal.data.supabase.proposalservice.ProposalServiceSimpleDto
import com.essntl.features.proposal.data.supabase.proposalservice.toRequestMap
import com.essntl.features.proposal.domain.model.ProposalFilterOptions
import com.essntl.features.proposal.domain.model.ProposalModel
import com.essntl.features.proposal.domain.model.ProposalOrderType
import com.essntl.features.proposal.domain.model.ProposalServiceModel
import com.essntl.features.proposal.domain.repository.ProposalRepository
import core.datastore.data.DatastoreManager
import core.datastore.data.DatastoreManager.Companion.PROPOSALS
import io.github.jan.supabase.postgrest.query.Order
import io.github.jan.supabase.postgrest.query.PostgrestRequestBuilder
import io.github.jan.supabase.postgrest.query.filter.PostgrestFilterBuilder
import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.TimeZone
import kotlinx.datetime.plus
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.koin.core.annotation.Single

@Single
internal class ProposalRepositoryImpl(
    private val proposalDataSource: ProposalDataSource,
    private val proposalHistoryDataSource: ProposalHistoryDataSource,
    private val proposalServiceDataSource: ProposalServiceDataSource,
    private val datastoreManager: DatastoreManager,
) : Repository(), ProposalRepository {

    override suspend fun getAll(
        page: Long,
        limit: Int,
        filterOptions: ProposalFilterOptions,
    ): Result<List<ProposalModel>> =
        runCatching(sendToastOnFailure = false) {
            proposalDataSource
                .getAll(
                    page = page,
                    limit = limit,
                    columns = ProposalDto.getColumns(),
                ) {
                    if (
                        filterOptions.searchText.isNotEmpty() ||
                        filterOptions.selectedProfileIds.isNotEmpty() ||
                        filterOptions.selectedProposalOrderType.isNotEmpty() ||
                        filterOptions.selectedProposalStatus != null
                    ) {
                        filter {
                            applyProposalFilter(filterOptions)
                        }

                        if (filterOptions.selectedProposalOrderType.isNotEmpty()) {
                            applyProposalOrder(filterOptions)
                        }
                    }
                }.map { it.toModel() }
        }

    private fun PostgrestFilterBuilder.applyProposalFilter(filterOptions: ProposalFilterOptions) {
        if (filterOptions.searchText.isNotEmpty()) {
            ilike("title", "%${filterOptions.searchText}%")
        }

        if (filterOptions.selectedProfileIds.isNotEmpty()) {
            and {
                or {
                    filterOptions.selectedProfileIds.forEach { profileId ->
                        eq("owner_id", profileId)
                    }
                }
            }
        }

        if (filterOptions.selectedProposalStatus != null) {
            eq("status", filterOptions.selectedProposalStatus!!.toDto())
        }
    }

    private fun PostgrestRequestBuilder.applyProposalOrder(filterOptions: ProposalFilterOptions) {
        if (filterOptions.selectedProposalOrderType.isNotEmpty()) {
            if (filterOptions.selectedProposalOrderType
                    .contains(ProposalOrderType.Alphabetical)
            ) {
                order(
                    column = "title",
                    order = Order.ASCENDING,
                )
            }

            if (filterOptions.selectedProposalOrderType
                    .contains(ProposalOrderType.LastCreated)
            ) {
                order(
                    column = "created_at",
                    order = Order.DESCENDING,
                )
            }

            if (filterOptions.selectedProposalOrderType
                    .contains(ProposalOrderType.RecentlyChanged)
            ) {
                order(
                    column = "updated_at",
                    order = Order.DESCENDING,
                )
            }

            if (filterOptions.selectedProposalOrderType
                    .contains(ProposalOrderType.StartDate)
            ) {
                order(
                    column = "from_date",
                    order = Order.DESCENDING,
                )
            }
        }
    }

    override suspend fun getProposalsByClientId(clientId: String): Result<List<ProposalModel>> =
        runCatching {
            proposalDataSource
                .getAll {
                    filter {
                        cs("clients", listOf(clientId))
                    }
                }
                .map {
                    it.toModel()
                }
        }

    override suspend fun getAllProposalByIds(ids: List<String>): Result<List<ProposalModel>> =
        runCatching {
            proposalDataSource
                .getByIds(ids)
                .map { it.toModel() }
        }

    override suspend fun getAllProposalServiceByStartDate(
        startDate: String,
        weeklyProposalServices: Boolean,
        proposalId: String,
    ): Result<List<ProposalServiceModel>> {
        return runCatching {
            val datesRange: ArrayList<String> = ArrayList()
            val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
            if (weeklyProposalServices) {
                // TODO improve range

                for (i in 0 until 7) {
                    val nextDay = today.plus(DatePeriod(days = i))
                    datesRange.add(nextDay.toString())
                }
            }
            proposalServiceDataSource.getAll(columns = ProposalServiceDto.getColumns()) {
                filter {
                    if (datesRange.isNotEmpty()) {
                        isIn(
                            "start_date",
                            datesRange,
                        )
                    } else {
                        eq("start_date", startDate)
                    }
                    eq("proposal_id", proposalId)
                }
                order("created_at", Order.DESCENDING)
            }.map { it.toModel() }
        }
    }

    override suspend fun getAllProposalServiceByStartDate(startDate: String): Result<List<ProposalServiceModel>> {
        return runCatching {
            proposalServiceDataSource.getAll(columns = ProposalServiceDto.getColumns()) {
                filter {
                    eq("start_date", startDate)
                    exact("proposal_id", null)
                }
                order("created_at", Order.DESCENDING)
            }.map { it.toModel() }
        }
    }

    override suspend fun getAllProposalService(
        page: Long,
        limit: Int,
    ): Result<List<ProposalServiceModel>> =
        runCatching {
            proposalServiceDataSource
                .getAll(page = page, limit = limit, columns = ProposalServiceDto.getColumns())
                .map { it.toModel() }
        }

    override suspend fun getProposal(id: String): Result<ProposalModel> =
        runCatching {
            proposalDataSource
                .getById(
                    id = id,
                    columns = ProposalDto.getColumns(),
                )
                .toModel()
        }

    override suspend fun getProposalByShareCode(shareCode: String): Result<ProposalModel> =
        runCatching {
            proposalDataSource
                .getOne {
                    filter {
                        eq("share_code", shareCode)
                    }
                }
                .toModel()
        }

    override suspend fun addProposal(proposal: ProposalModel): Result<ProposalModel> =
        runCatching {
            proposalDataSource
                .insertCustom<ProposalDto, ProposalSimpleDto>(proposal.toRequestMap())
                .toModel()
                .copy(travelAgent = proposal.travelAgent)
        }

    override suspend fun updateProposal(proposal: ProposalModel): Result<ProposalModel> =
        runCatching {
            proposalDataSource
                .updateCustom<ProposalDto, ProposalSimpleDto>(id = proposal.id, proposal.toRequestMap())
                .toModel()
                .copy(travelAgent = proposal.travelAgent)
        }

    // ProposalHistory

    override suspend fun getProposalHistoryList(proposalId: String): Result<List<ProposalModel>> =
        runCatching {
            proposalHistoryDataSource
                .getAll {
                    filter {
                        eq("proposal_id", proposalId)
                    }
                    order("created_at", Order.DESCENDING)
                }
                .map {
                    it.toModel()
                }
        }

    override suspend fun getProposalHistoryById(id: String): Result<ProposalModel> =
        runCatching {
            proposalHistoryDataSource
                .getById(id)
                .toModel()
        }

    override suspend fun getLatestProposalHistory(shareCode: String): Result<ProposalModel> =
        runCatching {
            proposalHistoryDataSource
                .getOne(
                    columns = ProposalDto.getColumns(),
                ) {
                    order("created_at", Order.DESCENDING)

                    filter {
                        eq("share_code", shareCode)
                    }
                }
                .toModel()
        }

    override suspend fun getProposalHistory(shareCode: String, version: Int): Result<ProposalModel> =
        runCatching {
            proposalHistoryDataSource
                .getOne(
                    columns = ProposalDto.getColumns(),
                ) {
                    order("created_at", Order.DESCENDING)

                    filter {
                        eq("share_code", shareCode)
                        eq("version", version)
                    }
                }
                .toModel()
        }

    override suspend fun addProposalHistory(proposal: ProposalModel): Result<ProposalModel> =
        runCatching {
            proposalHistoryDataSource
                .insert(proposal.toHistoryRequestMap())
                .toModel()
        }

    // ProposalService

    override suspend fun addAllProposalService(proposalServiceList: List<ProposalServiceModel>): Result<List<ProposalServiceModel>> =
        runCatching {
            proposalServiceDataSource
                .insertAllCustom<ProposalServiceDto, ProposalServiceSimpleDto>(
                    proposalServiceList.map { it.toRequestMap(ignoreNullFields = false) },
                )
                .map { it.toModel() }
        }

    override suspend fun addAllItineraryService(itineraryServiceList: List<ProposalServiceModel>): Result<List<ProposalServiceModel>> =
        runCatching {
            proposalServiceDataSource
                .insertAllCustom<ProposalServiceDto, ProposalServiceSimpleDto>(
                    itineraryServiceList.map { it.toRequestMap(ignoreNullFields = false) },
                )
                .map { it.toModel() }
        }

    override suspend fun getAllProposalServiceBySupplierId(supplierId: String): Result<List<ProposalServiceModel>> =
        runCatching {
            proposalServiceDataSource.getAll(
                columns = ProposalServiceDto.getColumns(),
            ) {
                filter {
                    eq("supplier_id", supplierId)
                }
                order("created_at", Order.DESCENDING)
            }.map { it.toModel() }
        }

    override suspend fun getItineraryService(id: String): Result<ProposalServiceModel> =
        runCatching {
            proposalServiceDataSource
                .getById(id = id, columns = ProposalServiceDto.getColumns())
                .toModel()
        }

    override suspend fun getAllProposalService(proposalId: String): Result<List<ProposalServiceModel>> =
        runCatching {
            proposalServiceDataSource.getAll(
                columns = ProposalServiceDto.getColumns(),
            ) {
                filter {
                    eq("proposal_id", proposalId)
                }
                order("created_at", Order.DESCENDING)
            }
                .map { it.toModel() }
        }


    override suspend fun getAllProposalServiceByRange(
        proposalId: String,
        fromDate: String,
        toDate: String,
    ): Result<List<ProposalServiceModel>> =
        runCatching {
            proposalServiceDataSource.getAll(
                columns = ProposalServiceDto.getColumns(),
            ) {
                filter {
                    eq("proposal_id", proposalId)
                }

                order("created_at", Order.DESCENDING)
            }
                .map { it.toModel() }
                .filter {
                    it.startDate in fromDate..toDate
                }
        }

    override suspend fun deleteAllProposalService(proposalId: String): Result<Unit> =
        runCatching {
            proposalServiceDataSource
                .delete {
                    filter {
                        eq("proposal_id", proposalId)
                    }
                }
        }

    override suspend fun deleteAllProposalService(ids: List<String>): Result<Unit> =
        runCatching {
            proposalServiceDataSource.deleteAll(ids = ids)
        }

    override suspend fun deleteProposalService(proposalServiceId: String): Result<Unit> =
        runCatching {
            proposalServiceDataSource.delete(id = proposalServiceId)
        }

    override suspend fun updateProposalService(proposalService: ProposalServiceModel): Result<ProposalServiceModel> =
        runCatching {
            val requestMap = proposalService.toRequestMap()
            val dto: ProposalServiceSimpleDto =
                proposalServiceDataSource
                    .updateCustom(id = proposalService.id, requestMap)
            val newModel = dto.toModel()
            newModel.copy(supplier = proposalService.supplier)
        }

    override suspend fun updateProposalToItinerary(proposal: ProposalModel): Result<Unit> =
        runCatching {
            proposalDataSource
                .invokeFunction(
                    edgeFunctionName = "confirm-proposal",
                    body = buildJsonObject {
                        put("proposal_id", proposal.id)
                    },
                )
        }

    override fun saveOpenedProposal(proposalId: String) {
        val lastOpenedProposalsString = datastoreManager.getData(PROPOSALS)

        if (lastOpenedProposalsString.isNullOrEmpty()) {
            val lastOpenedProposals = mutableListOf(proposalId)
            datastoreManager.saveData(PROPOSALS, Json.encodeToString(lastOpenedProposals))
        } else {
            val lastOpenedProposals = Json
                .decodeFromString<List<String>>(lastOpenedProposalsString)
                .toMutableList()

            if (lastOpenedProposals.contains(proposalId)) return

            if (lastOpenedProposals.size >= 10)
                lastOpenedProposals.removeLast()

            lastOpenedProposals.add(0, proposalId)

            datastoreManager.saveData(PROPOSALS, Json.encodeToString(lastOpenedProposals))
        }
    }

    override suspend fun getLastOpenedProposals(): Result<List<ProposalModel>> =
        runCatching {
            val lastOpenedProposalsString = datastoreManager.getData(PROPOSALS)
            if (lastOpenedProposalsString.isNullOrEmpty())
                emptyList()
            else {
                val lastOpenedProposals = Json.decodeFromString<List<String>>(lastOpenedProposalsString)
                proposalDataSource
                    .getByIds(
                        ids = lastOpenedProposals,
                        columns = ProposalDto.getColumns(),
                    )
                    .map {
                        it.toModel()
                    }.sortedBy { proposal ->
                        lastOpenedProposals.indexOf(proposal.id)
                    }
            }
        }
}
