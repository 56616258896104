package com.essntl.features.file.domain.model

data class FileModel(
    val id: String,
    val createdAt: String,
    val url: String,
    val name: String?,
    val uploadedBy: String?,
    val entityType: String?,
) {
    val isEmpty
        get() = this == empty

    companion object {
        val empty =
            FileModel(
                id = "",
                createdAt = "",
                url = "",
                name = null,
                uploadedBy = null,
                entityType = null,
            )
    }
}
