package client.utils

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.essntl.core.utils.datetime.dateDiffInDays

@Composable
fun rememberNumberOfDays(
    fromDate: String?,
    toDate: String?,
): Int? {
    return remember(fromDate, toDate) {
        dateDiffInDays(
            fromDate = fromDate,
            toDate = toDate,
        )?.plus(1)
    }
}