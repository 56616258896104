package client.pages.components.hotel

import androidx.compose.runtime.Composable
import client.pages.ServiceUiState
import com.essntl.core.utils.viewmodel.State
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.proposal.domain.model.ProposalServiceModel
import com.essntl.features.service.domain.model.ServiceModel
import com.essntl.features.tag.domain.model.TagModel
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth

@Composable
fun HotelSection(
    allProposalService: List<ServiceUiState>,
    getAllService: State<List<ServiceModel>>,
    getAllTag: State<List<TagModel>>,
    getAllImage: State<List<FileModel>>,
    getAllCategory: State<List<CategoryModel>>,
    onOpenImageFullScreen: (imageList: List<FileModel>, imageIndex: Int) -> Unit,
    modifier: Modifier = Modifier,
) {
    Column(
        modifier = modifier
            .fillMaxWidth()
    ) {
//        HotelHeader()

        HotelList(
            allProposalService = allProposalService,
            getAllService = getAllService,
            getAllTag = getAllTag,
            getAllImage = getAllImage,
            getAllCategory = getAllCategory,
            onOpenImageFullScreen = onOpenImageFullScreen,
        )
    }
}