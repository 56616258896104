package com.essntl.features.client.data.supabase

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class ClientSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<ClientDto> {
    override val tableName: String = "client"
}
