package com.essntl.features.travelagent.data.supabase

import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.travelagent.domain.model.TravelAgentModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TravelAgentDto(
    val id: String,
    val email: String?,
    val website: String?,
    val agency: String?,
    val city: String?,
    val country: String?,
    val address: String? = null,
    @SerialName("zip_code")
    val zipCode: String? = null,
    @SerialName("first_name")
    val firstName: String?,
    @SerialName("last_name")
    val lastName: String?,
    @SerialName("job_title")
    val jobTitle: String?,
    val notes: String?,
    val source: String?,
    val telephone: String?,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("logo_url")
    val logoUrl: String?,
    @SerialName("commission_type")
    val commissionType: String?,
    val commission: Double?,
    @SerialName("logo_file_id")
    val logoFile: String? = null,
    @SerialName("qb_customer_id")
    val qbCustomerId: String? = null,
) {
    fun toModel() =
        TravelAgentModel(
            id = id,
            email = email,
            website = website,
            agency = agency,
            city = city,
            country = country,
            address = address,
            zipCode = zipCode,
            firstName = firstName,
            lastName = lastName,
            jobTitle = jobTitle,
            notes = notes,
            source = source,
            telephone = telephone,
            createdAt = createdAt,
            logoUrl = logoUrl,
            commission = commission,
            logoFile = logoFile?.let { FileModel.empty.copy(id = it) },
            qbCustomerId = qbCustomerId,
            commissionType =
            when (commissionType) {
                "NET" -> TravelAgentModel.CommissionType.Net
                "GROSS" -> TravelAgentModel.CommissionType.Gross
                else -> TravelAgentModel.CommissionType.Gross
            },
        )

    companion object {
        fun getColumnsRaw() = "*"
    }
}

internal fun TravelAgentModel.CommissionType.toDto() =
    when (this) {
        TravelAgentModel.CommissionType.Net -> "NET"
        TravelAgentModel.CommissionType.Gross -> "GROSS"
    }
