package com.essntl.features.tag.data.supabase

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class TagSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<TagDto> {
    override val tableName: String = "service_tag"
}
