package client.termsandconditions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexDirection
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.px

@Composable
fun TermsAndConditionsBody() {
    val breakpoint = rememberBreakpoint()

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(20.px)
            .flexDirection(if (breakpoint < Breakpoint.MD) FlexDirection.Column else FlexDirection.Row)
    ) {
        SpanText(
            text = "",
            modifier = Modifier
                .margin(bottom = 28.px)
                .id("1234")
        )

        LaunchedEffect("1234") {
            document.getElementById("1234")?.let { element ->
                element.innerHTML = "\n" +
                    "<div style=\"color: black; font-size: 14px;\">\n" +
                    "<table >\n" +
                    "  <tr>\n" +
                    "    <td style=\"width: 50%;\">\n" +
                    "      <!-- TOURS COLUMN -->\n" +
                    "      <h2>TOURS</h2>\n" +
                    "      <h3>RATES</h3>\n" +
                    "      <ul>\n" +
                    "        <li>Rates are in DOLLARS and inclusive of tax at the current government rate.</li>\n" +
                    "        <li>Admissions are always quoted separately and are intended per person.</li>\n" +
                    "        <li>Should our drivers or guides exceed any pre-booked hours, products or items then additional fees may apply.</li>\n" +
                    "        <li>Surcharge for Public Holidays (advised at time of enquiry): There will be a 50% increment for tours taking place on 1st May, 24th, 25th & 31st December and 1st January</li>\n" +
                    "      </ul>\n" +
                    "\n" +
                    "      <h3>PAYMENTS</h3>\n" +
                    "      <ul>\n" +
                    "        <li>The price must be paid as follows:</li>\n" +
                    "        <li>An advance payment of 50% for any 'Private Tour' must be received to confirm the booking and the remaining balance no later than 30 days prior to arrival.</li>\n" +
                    "        <li>The payment should be made by:</li>\n" +
                    "        <li>Credit Card: payment by credit or debit card will incur a surcharge of 3.5% from AMEX, Visa or Master Card. Or by Wire Transfer</li>\n" +
                    "        <li>BANK: ESSENTIAL CLUB TRAVEL CONCIERGE</li>\n" +
                    "        <li>ACC NUMBER: 96281029</li>\n" +
                    "        <li>ROUTING Number: 267084131 (ACH & Direct Deposit)</li>\n" +
                    "        <li>Wire Transfers ABA #021000021</li>\n" +
                    "      </ul>\n" +
                    "\n" +
                    "      <h3>CANCELLATION</h3>\n" +
                    "      <ul>\n" +
                    "        <li>All cancellations must be advised in writing and followed by a call if it is made after 18.00 GMT+1; Cancellations are effective on the day they are received by the Company.\n" +
                    "Once your itinerary is confirmed, we make commitments to third parties and we cannot always refund or cancel any amounts payable by you if you choose to cancel all or any part of the itinerary. In such circumstances:</li>\n" +
                    "        <li>There will be a 20% cancellation charge if you cancel more than 60 days before the beginning of the tour.</li>\n" +
                    "        <li>There will be a 50% cancellation charge if you cancel between 59 to 30 days before the beginning of the tour.</li>\n" +
                    "        <li>If the tour is cancelled less than 30 days before the day of the tour, the charge is 100%</li>\n" +
                    "        <li>**Cancellation charge is expressed as a percentage of the total tour price.</li>\n" +
                    "      </ul>\n" +
                    "\n" +
                    "      <h3>THE SERVICE</h3>\n" +
                    "      <ul>\n" +
                    "        <li>All tours are escorted by a fully qualified guide, in deluxe motor cars when stated.</li>\n" +
                    "        <li>Your private guide and driver will meet you in the lobby of your hotel unless otherwise stated.</li>\n" +
                    "        <li>Gratuities to the guide and driver are not included in the cost.</li>\n" +
                    "      </ul>\n" +
                    "\n" +
                    "      <h3>OUR GUIDES</h3>\n" +
                    "      <ul>\n" +
                    "        <li>All our guides hold the France's highest guiding qualification and belong to our selected group of loyal assistants</li>\n" +
                    "        <li>Charges are always supported by our professional and qualified who are fully safe drive around while the guide will be free to speak.</li>\n" +
                    "      </ul>\n" +
                    "\n" +
                    "      <h3>ADMISSIONS/TICKETS</h3>\n" +
                    "      <ul>\n" +
                    "        <li>Tickets are non-refundable, non-exchangeable, non-cancellable.</li>\n" +
                    "      </ul>\n" +
                    "    </td>\n" +
                    "    \n" +
                    "    <td style=\"width: 50%;\">\n" +
                    "      <!-- CAR SERVICES COLUMN -->\n" +
                    "      <h2>CAR SERVICES</h2>\n" +
                    "<h3>RATES</h3>\n" +
                    "<ul>\n" +
                    "  <li>Rates are in DOLLARS and inclusive of tax at the current government rate.</li>\n" +
                    "  <li>Parking cost included-Should our drivers exceed any pre-booked hours then additional fees may apply.</li>\n" +
                    "  <li>Airport Arrivals: booking quote we include 60 minutes waiting time for Commercial Flight only. After this period waiting time is charged at \$30.00 every 10 minutes. Surcharge for Public Holidays (advised at time of enquiry): There will be a 50% increment for tours taking place on 1st May, 24th, 25th & 31st December and 1st January</li>\n" +
                    "</ul>\n" +
                    "\n" +
                    "<h3>PAYMENTS</h3>\n" +
                    "<ul>\n" +
                    "  <li>The price must be paid as follows:</li>\n" +
                    "  <li>An advance payment of 50% for any \"Private Tour\" must be received to confirm the booking and the remaining balance no later than 30 days prior to arrival.</li>\n" +
                    "  <li>The payment should be made by:</li>\n" +
                    "  <li>Credit Card: payment by credit or debit card will incur a surcharge of 3.5% from AMEX, Visa or Master Card. Or by Wire Transfer</li>\n" +
                    "  <li>BANK: ESSENTIAL CLUB TRAVEL CONCIERGE</li>\n" +
                    "  <li>ACC NUMBER: 96281029</li>\n" +
                    "  <li>ROUTING Number: 267084131 (ACH & Direct Deposit)</li>\n" +
                    "  <li>Wire Transfers ABA #021000021</li>\n" +
                    "</ul>\n" +
                    "\n" +
                    "<h3>CANCELLATION</h3>\n" +
                    "<ul>\n" +
                    "  <li>All cancellations must be advised in writing and followed by a call if it is made after 18.00 GMT+1; Cancellations are effective on the day they are received by the Company.\n" +
                    "Once your itinerary is confirmed, we make commitments to third parties and we cannot always refund or cancel any amounts payable by you if you choose to cancel all or any part of the itinerary. In such circumstances:</li>\n" +
                    "  <li>There will be a 20% cancellation charge if you cancel more than 60 days before the beginning of the tour.</li>\n" +
                    "  <li>There will be a 50% cancellation charge if you cancel between 59 to 30 days before the beginning of the tour.</li>\n" +
                    "  <li>If the tour is cancelled less than 30 days before the day of the tour, the charge is 100%</li>\n" +
                    "  <li>**Cancellation charge is expressed as a percentage of the total tour price.</li>\n" +
                    "</ul>\n" +
                    "\n" +
                    "<h3>THE SERVICE</h3>\n" +
                    "<ul>\n" +
                    "  <li>Your private chauffeur will be waiting for you at the Arrivals hall with a Name Board.</li>\n" +
                    "  <li>We use your flight number to track your flight and ensure your driver is waiting for you when you land.</li>\n" +
                    "  <li>If you are unable to locate your chauffeur, please do not leave the site and contact our Help Line immediately, by calling us you will be able to locate your chauffeur.</li>\n" +
                    "</ul>\n" +
                    "\n" +
                    "<h3>OUR VEHICLES</h3>\n" +
                    "<ul>\n" +
                    "  <li>Our vehicles are driven by qualified chauffeurs. We provide the latest luxury sedans and vans with the highest level of comfort. For tours/Transfers for one to two people we will provide a Mercedes E/S Class, and from 3 to 6 people a Mercedes V Class.</li>\n" +
                    "</ul>\n" +
                    "\n" +
                    "    </td>\n" +
                    "  </tr>\n" +
                    "</table>\n" +
                    "</div>"

            }
        }

//        TermsAndConditionsGroup(
//            title = "Tours",
//            modifier = Modifier
//        ) {
//            TermsAndConditionsItem(
//                title = "Rates",
//                body = "",
//                modifier = Modifier
//                    .margin(bottom = 28.px)
//                    .id("1234")
//            )
//
//            TermsAndConditionsItem(
//                title = "Rates",
//                body = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. " +
//                    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, " +
//                    "when an unknown printer took a galley of type and scrambled it to make " +
//                    "a type specimen book. It has survived not only five centuries, " +
//                    "but also the leap into electronic typesetting, remaining essentially unchanged. " +
//                    "It was popularised in the 1960s with the release of Letraset sheets containing " +
//                    "Lorem Ipsum passages, and more recently with desktop publishing software " +
//                    "like Aldus PageMaker including versions of Lorem Ipsum.",
//            )
//        }
//
//        TermsAndConditionsGroup(
//            title = "CAR SERVICES",
//            modifier = Modifier
//        ) {
//            TermsAndConditionsItem(
//                title = "Rates",
//                body = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. " +
//                    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, " +
//                    "when an unknown printer took a galley of type and scrambled it to make " +
//                    "a type specimen book. It has survived not only five centuries, " +
//                    "but also the leap into electronic typesetting, remaining essentially unchanged. " +
//                    "It was popularised in the 1960s with the release of Letraset sheets containing " +
//                    "Lorem Ipsum passages, and more recently with desktop publishing software " +
//                    "like Aldus PageMaker including versions of Lorem Ipsum.",
//                modifier = Modifier
//                    .margin(bottom = 28.px)
//            )
//
//            TermsAndConditionsItem(
//                title = "Rates",
//                body = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. " +
//                    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, " +
//                    "when an unknown printer took a galley of type and scrambled it to make " +
//                    "a type specimen book. It has survived not only five centuries, " +
//                    "but also the leap into electronic typesetting, remaining essentially unchanged. " +
//                    "It was popularised in the 1960s with the release of Letraset sheets containing " +
//                    "Lorem Ipsum passages, and more recently with desktop publishing software " +
//                    "like Aldus PageMaker including versions of Lorem Ipsum.",
//            )
//        }
    }
}