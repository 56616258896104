package com.essntl.features.proposal.data.supabase.proposalservice

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class ProposalServiceDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<ProposalServiceDto> {
    override val tableName: String = "proposal_service"
}
