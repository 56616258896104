package client.pages.components.hotel

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import client.Branding
import client.pages.ServiceUiState
import client.toSitePalette
import client.ui.P2Regular16
import client.utils.setInnerHtml
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.proposal.domain.model.ProposalServiceModel
import com.essntl.features.service.domain.model.ServiceModel
import com.essntl.features.tag.domain.model.TagModel
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.aspectRatio
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontStyle
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.textOverflow
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.jetbrains.compose.web.css.px

@Composable
fun HotelItem(
    proposalService: ServiceUiState,
    service: ServiceModel,
    category: CategoryModel?,
    allTag: List<TagModel>,
    allImage: List<FileModel?>,
    onOpenImageFullScreen: (imageList: List<FileModel>, imageIndex: Int) -> Unit,
    modifier: Modifier = Modifier,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    Column(
        modifier = modifier
            .borderRadius(
                topLeft = 18.px,
                topRight = 18.px,
                bottomLeft = 32.px,
                bottomRight = 32.px,
            )
            .backgroundColor(Colors.White)
            .overflow(Overflow.Hidden)
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .fillMaxWidth()
                .borderRadius(18.px)
                .overflow(Overflow.Hidden)
                .backgroundColor(Branding.controlTinted)
                .padding(
                    topBottom = 8.px,
                )
        ) {
            SpanText(
                text = service.title,
                modifier = Modifier
                    .fontSize(18.px)
                    .lineHeight(26.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(Colors.Black)
                    .overflow(Overflow.Hidden)
                    .textOverflow(TextOverflow.Ellipsis)
                    .textAlign(TextAlign.Center)
                    .margin(bottom = 4.px)
            )

            service.headline?.let {
                SpanText(
                    text = it,
                    modifier = Modifier
                        .fontSize(16.px)
                        .lineHeight(24.px)
                        .fontWeight(FontWeight.Medium)
                        .color(Colors.Black)
                        .overflow(Overflow.Hidden)
                        .textOverflow(TextOverflow.Ellipsis)
                        .textAlign(TextAlign.Center)
                )
            }
        }

        Column(
            modifier = Modifier
                .padding(
                    top = 14.px,
                    bottom = 20.px,
                    leftRight = 16.px,
                )
        ) {
            HotelPager(
                pageList = allImage,
                category = category,
                onOpenImageFullScreen = onOpenImageFullScreen,
                modifier = Modifier
                    .fillMaxWidth()
                    .aspectRatio(2f)
                    .borderRadius(18.px)
                    .overflow(Overflow.Hidden)
            )

            val hotelItemDescriptionId = remember(proposalService.id) {
                "hotel-item-description-${proposalService.id}"
            }

            SpanText(
                text = "",
                modifier = Modifier
                    .fontSize(16.px)
                    .lineHeight(24.px)
                    .color(sitePalette.text.primary)
//                    .textOverflow(TextOverflow.Ellipsis)
//                    .width(353.px)
                    .margin(top = 14.px)
                    .id(hotelItemDescriptionId)
                    .textAlign(TextAlign.Justify)
            )

            LaunchedEffect(hotelItemDescriptionId, proposalService.editedDescription) {
                document
                    .getElementById(hotelItemDescriptionId)
                    ?.setInnerHtml(proposalService.editedDescription)
            }

            proposalService.additionalInfo?.let { info ->
                SpanText(
                    text = info.replaceFirstChar { it.uppercaseChar() },
                    modifier = P2Regular16.toModifier()
                        .color(sitePalette.text.primary)
                        .fontStyle(FontStyle.Italic)
                )
            }
        }
    }
}