package core.config

import com.essntl.config.EssntlConfig

enum class BuildFlavor {
    Dev,
    Staging,
    Demo,
    Release,
    ;

    val isDev: Boolean
        get() = this == Dev

    val isStaging: Boolean
        get() = this == Staging

    val isRelease: Boolean
        get() = this == Release

    val isDemo: Boolean
        get() = this == Demo
}

val buildFlavor: BuildFlavor by lazy {
    val flavor = EssntlConfig.buildFlavor
    when (flavor.lowercase()) {
        "dev" ->
            BuildFlavor.Dev

        "staging" ->
            BuildFlavor.Staging

        "demo" ->
            BuildFlavor.Demo

        "release" ->
            BuildFlavor.Release

        else ->
            BuildFlavor.Dev
    }
}
