package com.essntl.features.paymentlink.data.repository

import com.essntl.core.supabase.*
import com.essntl.core.utils.repository.Repository
import com.essntl.features.paymentlink.data.supabase.PaymentLinkSupabaseDataSource
import com.essntl.features.paymentlink.data.supabase.toRequestMap
import com.essntl.features.paymentlink.domain.model.PaymentLinkModel
import com.essntl.features.paymentlink.domain.repository.PaymentLinkRepository
import org.koin.core.annotation.Single

@Single
internal class PaymentLinkRepositoryImpl internal constructor(
    private val supabase: PaymentLinkSupabaseDataSource,
) : Repository(), PaymentLinkRepository {
    override suspend fun getById(id: String): Result<PaymentLinkModel> =
        runCatching {
            supabase
                .getById(id)
                .toModel()
        }

    override suspend fun getAllByProposalId(proposalId: String): Result<List<PaymentLinkModel>> =
        runCatching {
            supabase
                .getAll {
                    filter {
                        eq("proposal_id", proposalId)
                    }
                }.map { it.toModel() }
        }

    override suspend fun getAllByIds(ids: List<String>): Result<List<PaymentLinkModel>> =
        runCatching {
            val paymentLinkList = supabase.getByIds(ids = ids)

            paymentLinkList
                .map { paymentLink ->
                    paymentLink.toModel()
                }
        }

    override suspend fun insert(paymentLink: PaymentLinkModel): Result<PaymentLinkModel> =
        runCatching {
            supabase
                .insert(paymentLink.toRequestMap())
                .toModel()
        }

    override suspend fun update(paymentLink: PaymentLinkModel): Result<PaymentLinkModel> =
        runCatching {
            supabase
                .update(
                    id = paymentLink.id,
                    requestMap = paymentLink.toRequestMap(),
                ).toModel()
        }

    override suspend fun delete(id: String): Result<Unit> =
        runCatching {
            supabase
                .delete(id = id)
        }

    override suspend fun getProposalActivePaymentLink(proposalId: String): Result<List<PaymentLinkModel>> =
        runCatching {
            supabase.getAll {
                filter {
                    eq("proposal_id", proposalId)
                    eq("active", true)
                }
            }.map { it.toModel() }
        }

}
