package client.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import client.pages.components.ProposalScreen
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext

@Page(routeOverride = "proposal/{id}")
@Composable
fun ProposalPreviewPage() {
    val ctx = rememberPageContext()
    val viewModel = rememberProposalViewModel()
    val state by viewModel.state.collectAsState()

    LaunchedEffect(Unit) {
        val proposalId = ctx.route.params.getValue("id")

        viewModel.onEvent(
            ProposalItineraryEvent.GetProposalPreview(id = proposalId)
        )
    }

    ProposalScreen(state = state)
}